import { useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { storeAdminCreateQuestionClusterAction } from "../../../redux/actions/questionnaire.action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../../redux/actions/auth.action";

interface IParams {
    id: string;
}

const CreateQuestionCluster = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    let { id } = useParams<IParams>();
    const name = useRef<HTMLInputElement>(null);
    const description = useRef<HTMLTextAreaElement>(null);

    const auth = useSelector((state: RootStateOrAny) => state.auth);

    useEffect(() => {
        !auth.token && history.push("/");
        auth.token && !auth.user && dispatch(getUserAction(auth.token));

        if (auth.successLoginParticipant) {
            history.push("/register");
        }
    }, [history, auth.token, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = () => {
        if(!name.current?.value){
            return name.current?.focus();
        }
        if(!description.current?.value){
            return description.current?.focus();
        }
        const data = {
            "name": name.current?.value,
	        "description": description.current?.value,
            "questionerId" : id
        }

        async function storeQuestionCluster() {
            const res: any = await dispatch(
                storeAdminCreateQuestionClusterAction(auth.token, data)
            );
            if(res.code === 200) {
                history.push("/admin/questionnaire/" + id)
            } else {
                console.error(res.code)
            }
        }
        storeQuestionCluster();
    };

    return (
        <div className="container mt-3">
            <h5>Create Question Cluster</h5>
            <div className="card">
                <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Question Cluster Name"
                            ref={name}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                            className="form-control"
                            id="description"
                            placeholder="Question Cluster Description"
                            rows={3}
                            ref={description}
                        />
                    </div>
                    <div className="text-right">
                        <button
                            className="btn btn-success"
                            onClick={onSubmit}
                        >
                            Create
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateQuestionCluster;
