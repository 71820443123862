import { API } from "./api.middleware"

export const getParticipantListAPI = async (token: string,status:string,page:number,perPage:number) => {
    return await API({
        url : `/admin/participant-answer?status=${status}&page=${page}&limit=${perPage}&delay=1` ,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const getParticipantCountAPI = async (token: string) => {
    return await API({
        url : `/admin/participant-answer?status=counter` ,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}


export const getRiskLevelByDemographyAPI = async (token: string, demographic: string, part: string) => {
    return await API({
        url : `/admin/risk-demographic/detail-level?demographic=${demographic}&part=${part}` ,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const getDetailAdminParticipanAnswerAPI = async(token:string,participantId:string,questionnaireId:string)=>{
    return await API({
        url:`/admin/participant-answer/${participantId}/${questionnaireId}`,
        method:'GET',
        headers:{Authorization:"Bearer " + token}
    }).then(res=>res.data)
}

export const getReportParticipantAPI = async (token : string)=>{
    return await API({
        url:'/admin/participant-report',
        method: 'GET',
        headers: { Authorization:"Bearer " + token}
    }).then(res=>res.data)
}

export const sendNotificationParticipantAPI = async (token:string,participantId:string,questionnaireId:string, data: object)=>{
    return await API({
        url:`/admin/participant-answer/${participantId}/${questionnaireId}`,
        method:'PUT',
        headers:{Authorization:"Bearer " + token},
        data
    }).then(res=>res.data)
}