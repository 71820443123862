import { useHistory } from "react-router-dom"

const GroupForm=()=>{
    const history =useHistory()
    return(
        <>
        <form className="p-4 p-lg-5 ">
            <h4>Questionnaire group</h4>
            <div className="form-group mt-3">
                <label htmlFor="group">Group Name</label>
                <select className="form-control" id="group">
                    <option>Control group</option>
                    <option>Experimental group</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea className="form-control" id="description" rows={3}></textarea>
            </div>
            
            <div className="text-right">
            <div className="custom-control custom-radio custom-control-inline">
            <label htmlFor="switchActive">active</label>
                <label className="switch mt-2 ml-2">
                {/* < */}
                    <input type="checkbox" id="switchActive" name="switchActive" className="custom-control-input"/>
                <span className="slider round"></span>
                </label>
            </div>
            </div>
            <div className='row d-flex align-items-center justify-content-center flex-column my-6'  >
                <button type="button" className='btn btn-primary' style={{backgroundColor: 'rgba(41, 22, 111, 1)'
,color:"white"}} onClick={()=>history.push('/admin/grouplist')}>
                    Save
                </button>
             </div>
        </form>
        </>
    )
}

export default GroupForm
