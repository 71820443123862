import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getMedicalAction } from '../../redux/actions/medicalCenter.action'

const useStyles = createUseStyles({
    container: {
        height: '100vh'
    },
    logo: {
        top: '40px',
        left: '50px'
    }
})

const Homepage = () => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const [ isLoading, setLoading ] = useState(false)
    const [form, setForm] = useState({
        medicalCenter: "",
    })
    const { medical } = useSelector((state: RootStateOrAny) => state.medical)

    useEffect(() => {
        const init = async () => {
            try{
                setLoading(true)
                await dispatch(getMedicalAction())
                setLoading(false)
            }catch(err: any){
                setLoading(false)
                alert(err.message)
            }
        }

        init()
        
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onClickRow = (id: string, shortName: string) => () => {
        history.push(`/${shortName}`, { medicalCentreId: id } )
    }
    
    return (
        <>
            <div className={`container-fluid ${classes.container} bg-light`}  >
                <div className='row h-100 d-flex align-items-center justify-content-center position-relative'>
                    <div className='col-sm-6 col-md-5'>
                        <form className="p-4 p-lg-5 border bg-white" >
                            <div className='text-center'>
                                <h4>Hello There!</h4>
                                <h6>  We are conducting a medical research based on a survey.</h6>

                            </div>
                            <p className=' text-center mt-5'>if you are intended to join our survey please select our medical center to get to the survey we really appreciate your input!</p>
                            <div className="form-group">
                                <select
                                    className="form-control"
                                    id="medical"
                                    name="medical"
                                    required
                                    value={form.medicalCenter}
                                    onChange={(e) => setForm({ ...form, medicalCenter: e.target.value })}
                                >
                                    {isLoading ?<option>Loading ..</option> : <>
                                    <option>Choose Medical Center</option>
                                    {medical.map((mc: any) => 
                                        <option value={mc.id} key={mc.id}>{mc.name}</option>
                                    )} </> }
                                </select>
                            </div>
                            <div className="form-group mt-3">
                                {medical.filter((mc: any) => mc.id === form.medicalCenter).map((filteredMedical: any, key: any) => (
                                    <div key={key}>
                                        <button type="submit" className="btn btn-primary btn-block" style={{ backgroundColor: "rgba(41, 22, 111, 1)", color: "white" }} onClick={onClickRow(filteredMedical.id, filteredMedical.shortName)}>
                                            Next
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <p className='text-center  mt-3'>*this application not for commercial use*</p>

                        </form>
                    </div>
                </div>
            </div>
            <div className="container">

            </div>

        </>
    )
}

export default Homepage