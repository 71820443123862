import React, { useEffect, useState } from "react";
import { useHistory} from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { registerUserAction } from "../../redux/actions/auth.action";
import { getRegInfoAction } from "../../redux/actions/regInfo.action";

const genders  = ["male", "female"]

const RegisterForm = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const stateHistory: any = history.location.state;
    const { medical } = useSelector((state: RootStateOrAny) => state.medical)
    const [myInfo,setMyInfo]=useState({
        occupation:[],
        fracture_site:[], 
        aetiology:[],
        age_classification:[],
        presentationEd:[]
    })
    const [form, setForm] = useState({
        email: stateHistory.email || "",
        phoneNumber:"",
        occupation:'',
        gender:""||undefined,
        presentationEd:'',
        postcode: "",
        medicalCentreId: stateHistory.medicalCentreId,
        ageRange:myInfo.age_classification[0]||'',
        fractureSite:'',
        aetiology:'',
        isEmployed: false
    });

    useEffect(()=>{
        if(!form.occupation || form.occupation==="Unemployed" || form.occupation==="Retire"){
            form.isEmployed=false
        }
        else{
            form.isEmployed=true
        }
    },[])// eslint-disable-line  react-hooks/exhaustive-deps
    
    useEffect(() => {
        async function getInfoDetail() {
            const res : any = await dispatch(getRegInfoAction())
            const items =  res.data
            setMyInfo(items)
            setForm({
                ...form,
                ...items,
                ageRange:items.age_classification[0]||'',
            })
        }
        getInfoDetail()
    },[]) // eslint-disable-line  react-hooks/exhaustive-deps

    
    const onChangeForm = (
        e: React.FormEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.currentTarget;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if(form.gender===""){
                dispatch(form.gender=undefined)
            }
            setLoading(true);
            await dispatch(registerUserAction(form));
            setLoading(false);
            history.push("/agreement");
        } catch (err: any) {
            console.log("[registerUserAction]", err);            
            alert(err?.response?.data?.message);
            setLoading(false);
        }
    };

    return (
        <>
            <form className="p-3 border rounded" style={{ background: '#ffffff9e' }} onSubmit={onSubmit}>
                <h4 className="text-center mb-4">Register Participant</h4>
                <div className="container mt-4">
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="email">
                                Email<span className="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                value={form.email}
                                name="email"
                                onChange={onChangeForm}
                                className="form-control"
                                id="email"
                                aria-describedby="email"
                                placeholder="email"
                                required
                                disabled
                            />
                        </div>
                        <div className="form-group col-md-6 ">
                            <label htmlFor="residental">
                                Residental<span className="text-danger">*</span>
                            </label>
                            <input
                                type="number"
                                value={form.postcode}
                                name="postcode"
                                onChange={onChangeForm}
                                className="form-control"
                                id="residental"
                                placeholder="Postal code"
                                required
                            />
                        </div>
                        <div className="form-group col-md-6 ">
                            <label htmlFor="phoneNumber">
                                Phone Number<span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                value={form.phoneNumber}
                                name="phoneNumber"
                                onChange={onChangeForm}
                                className="form-control"
                                id="phoneNumber"
                                placeholder="Phone Number"
                                required
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="gender">Gender</label>
                            <select
                                name="gender"
                                onChange={onChangeForm}
                                className="form-control"
                                id="gender"
                            >
                                 {isLoading ?<option>Loading ..</option> : <>
                                    <option value="">prefer not to say</option>
                                     {genders.map((gnd: any) => 
                                        <option value={gnd}>{gnd}</option>
                                    )} </> }
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="medicalCenter">
                                Medical Center
                                <span className="text-danger">*</span>
                            </label>
                            <select
                                value={form.medicalCentreId}
                                name="medicalCentreId"
                                onChange={onChangeForm}
                                className="form-control"
                                id="medicalCenter"
                                required
                                disabled
                            >
                                {medical.map((md: any, index: number) => {
                                    return (
                                        <option key={index} value={md.id} selected >
                                            {md.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="ageRange">Age<span className="text-danger">*</span></label>
                            <select
                                value={form.ageRange}
                                name="ageRange"
                                onChange={onChangeForm}
                                className="form-control"
                                id="ageRange"
                                required
                            >
                                 {myInfo.age_classification.map((age, index)=>{
                                    return(
                                        <option value={age} key={index}>
                                        {age}
                                    </option>
                                    )
                                   
                                })}
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="occupation">Occupation<span className="text-danger">*</span></label>
                            <select
                                name="occupation"
                                onChange={onChangeForm}
                                className="form-control"
                                id="occupation"
                                required
                            >
                                 {isLoading ?<option>Loading ..</option> : <>
                                    <option value="">Choose</option>
                                    {myInfo.occupation.map((jobs: any, idx) => 
                                        <option value={jobs} key={idx}>{jobs}</option>
                                    )}</>}
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="fractureSite">Fracture Site (confirm with Hospital staff)<span className="text-danger">*</span></label>
                            <select
                                name="fractureSite"
                                onChange={onChangeForm}
                                className="form-control"
                                id="fractureSite"
                                required
                            >
                                 {isLoading ?<option>Loading ..</option> : <>
                                    <option value="">Choose</option>
                                     {myInfo.fracture_site.map((fs: any) => 
                                        <option value={fs}>{fs}</option>
                                    )} </> }
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="aetiology">Aetiology<span className="text-danger">*</span></label>
                            <select
                                name="aetiology"
                                onChange={onChangeForm}
                                className="form-control"
                                id="aetiology"
                                required
                            >
                                 {isLoading ?<option>Loading ..</option> : <>
                                    <option value="">Choose</option>
                                     {myInfo.aetiology.map((aetiology: any) => 
                                        <option value={aetiology}>{aetiology}</option>
                                    )} </> }
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="presentationEd">Number of presentations to ED (with alcohol related trauma)<span className="text-danger">*</span></label>
                            <select
                                name="presentationEd"
                                onChange={onChangeForm}
                                className="form-control"
                                id="presentationEd"
                                required
                            >
                                 {isLoading ?<option>Loading ..</option> : <>
                                    <option value="">Choose</option>
                                    {myInfo.presentationEd.map((ed)=>
                                        <option value={ed}>{ed}</option>
                                    )}
                                    </> }
                            </select>
                        </div>
                    </div>
                    
                    <div className="form-group mt-4">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="btn btn-primary btn-block"
                            style={{
                                backgroundColor: " rgba(41, 22, 111, 1)",
                                color: "white",
                            }}
                        >
                            {isLoading ? "Registering .." : "Register"}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default RegisterForm;
