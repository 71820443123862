import { persistReducer } from 'redux-persist'
import * as types from '../types/auth.type'
import storage from 'redux-persist/lib/storage'
import IAction from '../../interfaces/IAction'

const initialState = {
    user: '',
    token: null,
    successLoginParticipant:false,
    verifyParticipant:false,
    refreshToken:null
}

const persistConfig = {
    key: 'auth',
    storage,
    whitelist: ['token', 'user' ,'refreshToken']
}

export default persistReducer(persistConfig, (state = initialState, action: IAction = { type: '', payload: {} }) => {
    switch (action.type) {
        case types.LOGIN_SUCCESS:return{
            ...state as {},
            token:action.payload.token,
            refreshToken:action.payload.refreshToken,
            user:action.payload.user
        }
        case types.REFRESH_TOKEN:return{
            ...state as {},
            token:action.payload.token,
            user:action.payload.user
        }
        case types.LOGOUT: return {
            ...state as {},
            user : null,
            token : null,
            refreshToken:null
        }
        case types.LOGIN_USER:return{
            ...state as {},
            isLoading: false,
            verifyParticipant: true,
            successLoginParticipant : true ,
            errMessage : null,
        }
        case types.SET_USER:return{
            ...state as {},
            user:action.payload.user
        }
        default:
             return state
    }
})
