import { FormEvent, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { loginUserAction } from '../../redux/actions/auth.action'
import { getDetailMedicalAction } from '../../redux/actions/medicalCenter.action'

const useStyles = createUseStyles({
    container: {
        height: '100vh'
    },
    logo: {
        top: '40px',
        left: '50px'
    }
})

const LoginUser = () => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const [ isLoading, setLoading ] = useState(true)
    const medical = useSelector((state: RootStateOrAny) => state.medical)
    const detailMedical = medical.detailMedical || {}
    const { id : shortName }: { id: string } = useParams()
    const [form, setForm] = useState({
        email: ''
    })
   
    useEffect(() => {
        const init = async () => {
            try {
                await dispatch(getDetailMedicalAction(shortName));
                setLoading(false)
            } catch (error: any) {
                if(error.response?.data?.message === "DATA_NOT_FOUND") {
                    alert('The hospital is not found');
                    history.push('/')
                } else {
                    history.push('/')
                }
            }
        }
        init()
    }, [dispatch, shortName, history])

  
    const onLoginUser = async (e: FormEvent) => {
        e.preventDefault();
        try {
            setLoading(true)
            await dispatch(loginUserAction(form))
            setLoading(false)
            history.push("/agreement")
        } catch(err : any) {
            setLoading(false)
            const message = err?.response?.data?.message
            // console.log(message)}
            if(message === 'Account Not Found'){
                history.push('/register', { email: form.email, medicalCentreId: medical.detailMedical.id })
            }
        }
    }
    const onChangeForm = (
        e: FormEvent<HTMLInputElement>
    ) => {
        const { name, value } = e.currentTarget;
        setForm({
            ...form,
            [name]: value,
        });
    };

    return (
        <>
            <div className={`container-fluid ${classes.container} bg-light`}  >
                <div className='row h-100 d-flex align-items-center justify-content-center position-relative'>
                    <div className='col-sm-6 col-md-5'>
                        <form className="p-4 p-lg-5 border bg-white" onSubmit={onLoginUser} >
                            <img src={detailMedical.logo} alt='logo' style={{ height: "auto", width: "100%", maxWidth: '150px', display: 'block', margin: '0 auto' }} />
                            <hr />
                            <div className='text-center'>
                                <h4>Hello There!</h4>
                                <h6>  We are conducting a medical research based on a survey.</h6>

                            </div>
                            <p className=' text-center mt-5'>if you are intended to join our survey please register your email to get to the survey we really appreciate your input!</p>
                            <div className="form-group mt-5">
                                <input type="email" value={form.email} onChange={onChangeForm} className="form-control" id="email" aria-describedby="email" name='email' placeholder="john.doe@gmail.com" required />
                            </div>
                            <div className="form-group mt-5">
                                <button type="submit" className="btn btn-primary btn-block" style={{ backgroundColor: "rgba(41, 22, 111, 1)", color: "white" }} disabled={isLoading} >
                                    {isLoading ? 'Loading ..' : 'next'}
                                </button>
                            </div>
                            <p className='text-center  mt-3'>*this application not for commercial use*</p>
                            <p className=' text-center'>If you are a survey application admin you can login <Link className='btn-link' to={`/${shortName}/login`} >here</Link></p>
                        </form>

                    </div>
                </div>
            </div>
            <div className="container">

            </div>

        </>
    )
}

export default LoginUser

