import axios from "axios";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getRiskDemographyDetailAction } from "../../../redux/actions/report.action";
import ButtonDownload, { SwitchButton } from "../../Button/Button";
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


const RiskDemographyList = () => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [download, setDownload] = useState(false)
    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const  demograpic= searchParams.get("demographic")
    const demographicValue=searchParams.get("value")
    // const [form, setForm] = useState({
    //     demographic: '',
    //     demographicValue : ""
    // });
    const [hideEmail,setHideEmail]=useState<boolean>(false)
   
    async function getReportDemography() {
        const res: any = await dispatch(
            getRiskDemographyDetailAction(auth.token,demograpic,demographicValue)
        );
        setData(res.data);
    }
    async function downloadApi() {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/risk-demographic?demographic=${demograpic}&value=${demographicValue}&download=1&hideid=${hideEmail}`,
                {
                    responseType: "blob",
                    headers: { Authorization: "Bearer " + auth.token },
                }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "risk-demography-detail.csv");
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0);
            setDownload(false);
        } catch (e) {
            alert("Download failed")
        }
    }

    useEffect(() => {
       
        !auth.token && history.push("/");
        if (auth.successLoginParticipant) {
            history.push("/register");
        }
        getReportDemography();

        if (download) {
            downloadApi();
        }
    }, [history, auth.token, dispatch, download]); // eslint-disable-line react-hooks/exhaustive-deps

    // const onChangeForm = (
    //     e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
    // ) => {
    //     e.preventDefault()
    //     const { name, value } = e.currentTarget;
    //     setForm({
    //         ...form,
    //         [name]: value,
    //     });
    //     getReportDemography()
    //     // dispatch(getParticipanAction(auth.token, value, 1, perPage))
    // };

    // const renderSearch = () => {
    //     return (
    //         <form className="input-group col-md-4 col-sm-6 mt-3"

    //         >
    //             <span className="mt-1 mr-3" style={{ fontSize: '1rem' }}>Filter</span>
    //             <select
    //                 name="demographic"
    //                 onChange={onChangeForm}
    //                 className="form-control"
    //                 id="status"
    //             >
    //                 {/* <option value="">All Demographic</option> */}
    //                 <option value="gender">Gender</option>
    //                 <option value="age-range">Age Range</option>
    //             </select>
    //         </form>
    //     )
    // }

    return (
        <div className="container-fluid">
        <div className="row p-5">
            <div className="col pr-5 pt-2 bg-white">
                <div className="row px-3 pb-2 d-flex" style={{ justifyContent: 'space-between' }}>
                    <div className="mt-3">
                    <img src="/assets/img/population.png" alt="icon-population" className="mr-2" />
                    <span className="page-title">Patient's Detail filtered by Demographic</span>
                    </div>

                    {/* {renderSearch()} */}

                </div>
                <div className="row pb-2 d-flex">
                    <div className="col">
                        <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr> 
                                    <th scope="col" className="rowTableCenter">created At</th>
                                    <th scope="col" className="rowTableCenter">Occupation</th>
                                    <th scope="col" className="rowTableCenter">Phone Number</th>
                                    <th scope="col" className="rowTableCenter">Post Code</th>
                                    <th scope="col" className="rowTableCenter">Employed</th>
                                    <th scope="col" className="rowTableCenter">Gender </th>
                                    <th scope="col" className="rowTableCenter">Age Range</th>
                                    <th scope="col" className="rowTableCenter">Fracture Site</th>
                                    <th scope="col" className="rowTableCenter">Aetiology</th>
                                    <th scope="col" className="rowTableCenter">Presentation ED</th>
                                    <th scope="col" className="rowTableCenter">Hospital Name</th>
                                    <th scope="col" className="rowTableCenter">Group Name</th>
                                    <th scope="col" className="rowTableCenter">Score Q1</th>
                                    <th scope="col" className="rowTableCenter">Score Q2</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {filterData().map((riskData:any)=>(
                                    <tr>
                                        <td>{dayjs.tz(riskData.createdAt, "Australia/Sydney").format('DD MMMM YYYY, hh:mm A') || "loading"}</td>
                                        <td>{riskData.occupation}</td>
                                        <td>{riskData.phoneNumber}</td>
                                        <td>{riskData.postcode}</td>
                                        <td>{riskData.isEmployed? "Employed" : "No"}</td>
                                        <td>{riskData.gender}</td>
                                        <td>{riskData.ageRange}</td>
                                        <td>{riskData.fractureSite}</td>
                                        <td>{riskData.aetiology}</td>
                                        <td>{riskData.presentationEd}</td>
                                        <td>{riskData.medicalcentername}</td>
                                        <td>{riskData.groupname}</td>
                                        <td>{riskData.q1}</td>
                                        <td>{riskData.q2}</td>
                                    </tr>
                                ))} */}
                            {data.map((riskData:any)=>(
                                    <tr>
                                        <td>{dayjs.tz(riskData.createdAt, "Australia/Sydney").format('DD MMMM YYYY, hh:mm A') || "loading"}</td>
                                        <td>{riskData.occupation}</td>
                                        <td>{riskData.phoneNumber}</td>
                                        <td>{riskData.postcode}</td>
                                        <td>{riskData.isEmployed? "Employed" : "No"}</td>
                                        <td>{riskData.gender}</td>
                                        <td>{riskData.ageRange}</td>
                                        <td>{riskData.fractureSite}</td>
                                        <td>{riskData.aetiology}</td>
                                        <td>{riskData.presentationEd}</td>
                                        <td>{riskData.medicalcentername}</td>
                                        <td>{riskData.groupname}</td>
                                        <td>{riskData.q1}</td>
                                        <td>{riskData.q2}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                <div className="row px-3 pb-2 d-flex">
                    <SwitchButton hideEmail={hideEmail} onSwitch={()=>setHideEmail(!hideEmail)} wordingSwitch="Hide patient details when download" />
                    <ButtonDownload onClickButton={downloadApi} wordingButton="Download" />
                </div>
                <div className="row pb-2 d-flex">
                    <div className="col">
                    </div>
                </div>
            </div>

        </div>
    </div>
    );
};

export default RiskDemographyList;
