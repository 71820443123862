import { useRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getAdminQuestionClusterDetailAction, updateAdminEditQuestionClusterAction } from "../../../redux/actions/questionnaire.action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../../redux/actions/auth.action";

interface IParams {
    idQuestionnaire: string;
    idCluster: string;
}

interface IData {
    name: string;
    description: string;  
    questionerId : string;
}

const UpdateQuestionCluster = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    let { idQuestionnaire, idCluster } = useParams<IParams>();
    const name = useRef<HTMLInputElement>(null);
    const description = useRef<HTMLTextAreaElement>(null);
    const [cluster, setCluster] = useState<Partial<IData>>({});
    const [loading, setLoading] = useState(true);

    const auth = useSelector((state: RootStateOrAny) => state.auth);

    useEffect(() => {
        !auth.token && history.push("/");
        auth.token && !auth.user && dispatch(getUserAction(auth.token));

        if (auth.successLoginParticipant) {
            history.push("/register");
        }
    }, [history, auth.token, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getQuestionClusterDetail() {
            const res: any = await dispatch(
                getAdminQuestionClusterDetailAction(auth.token, idCluster)
            );
            delete res.data.createdAt
            delete res.data.updatedAt
            delete res.data.id
            setCluster(res.data);
            setLoading(false);
        }
        getQuestionClusterDetail();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = () => {
        if(!name.current?.value){
            return name.current?.focus();
        }
        if(!description.current?.value){
            return description.current?.focus();
        }
        const data = {
            "name": name.current?.value,
	        "description": description.current?.value,
            "questionerId" : idQuestionnaire
        }

        async function putQuestionCluster() {
            const res: any = await dispatch(
                updateAdminEditQuestionClusterAction(auth.token, idCluster, data)
            );
            if(res.code === 200) {
                history.push("/admin/questionnaire/" + idQuestionnaire)
            } else {
                console.error(res.code)
            }
        }
        putQuestionCluster();
    };

    if (loading) {
        return (
            <div className="container mt-3">
                <p>Loading ...</p>
            </div>
        );
    }
    return (
        <div className="container mt-3">
            <h5>Edit Question Cluster</h5>
            <div className="card">
                <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Question Cluster Name"
                            ref={name}
                            defaultValue={cluster.name}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                            className="form-control"
                            id="description"
                            placeholder="Question Cluster Description"
                            rows={3}
                            ref={description}
                            defaultValue={cluster.description}
                        />
                    </div>
                    <div className="text-right">
                        <button
                            className="btn btn-success"
                            onClick={onSubmit}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateQuestionCluster;
