import axios from "axios";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRiskDemographyAction } from "../../../redux/actions/report.action";
import ButtonDownload, { ButtonDetail, SwitchButton } from "../../Button/Button";


const RiskDemographyList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [download, setDownload] = useState(false)
    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const [form, setForm] = useState({
        demographic: "all"
    });
    const [hideEmail, setHideEmail] = useState<boolean>(false)

    async function getReportDemography() {
        const res: any = await dispatch(
            getRiskDemographyAction(auth.token, form.demographic)
        );
        setData(res.data);
    }

    async function downloadApi() {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/risk-demographic?download=true`,
                {
                    responseType: "blob",
                    headers: { Authorization: "Bearer " + auth.token },
                }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "risk-demography-list.csv");
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0);
            setDownload(false);
        } catch (e) {
            alert("Download failed")
        }
    }

    useEffect(() => {
        !auth.token && history.push("/");

        if (auth.successLoginParticipant) {
            history.push("/register");
        }
        getReportDemography();

        if (download) {
            downloadApi();
        }
    }, [history, auth.token, dispatch, download]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeForm = async (
        e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        e.preventDefault()
        const { name, value } = e.currentTarget;
        setForm({
            ...form,
            [name]: value,
        });
        const res: any = await dispatch(
            getRiskDemographyAction(auth.token, value)
        );
        setData(res.data);
    };
    const onSearch = (e: React.FormEvent<HTMLFormElement>, page: number) => {
        e.preventDefault()
        dispatch(getRiskDemographyAction(auth.token, form.demographic))
    }

    const renderSearch = () => {
        return (
            <form className="input-group col-md-4 col-sm-6 mt-3"
                onSubmit={() => onSearch}

            >
                <span className="mt-1 mr-3" style={{ fontSize: '1rem' }}>Filter</span>
                <select
                    name="status"
                    onChange={onChangeForm}
                    className="form-control"
                    id="status"
                >
                    <option value="all">All Demographic</option>
                    <option value="gender">Gender</option>
                    <option value="ageRange">Age Range</option>
                    <option value="occupation">Occupation</option>
                    <option value="aetiology">Aetiology</option>
                    <option value="fractureSite">Fracture</option>
                    <option value="presentationEd">Number Presentation</option>
                </select>
            </form>
        )
    }

    const onClickDetail = (demographic: string, valueDemographic: string) => {
        if (demographic === "age-range") {
            history.push({
                pathname: '/admin/risk-demography-detail/',
                search: `?demographic=ageRange&value=${valueDemographic}`
            })
        }
        else {
            history.push({
                pathname: '/admin/risk-demography-detail/',
                search: `?demographic=${demographic}&value=${valueDemographic}`
            })
        }
    }

    console.log(data)
    return (
        <div className="container-fluid">
            <div className="row p-5">
                <div className="col pr-5 pt-2 bg-white">
                    <div className="row px-3 pb-2 d-flex" style={{ justifyContent: 'space-between' }}>
                        <div className="mt-3">
                            <img src='/assets/img/population.png' alt="img-population" className="mr-2" />

                            <span className="page-title">Risk by Demography</span>
                        </div>

                        {renderSearch()}

                    </div>
                    <div className="row pb-2 d-flex">
                        <div className="col">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" rowSpan={2} className="rowTableCenter">Demography</th>
                                            <th scope="col" rowSpan={2} className="rowTableCenter textRowCentered">Demographic Value</th>
                                            <th scope="col" colSpan={3} className="textRowCentered">FollowUp Average Score</th>
                                            <th scope="col" colSpan={3} className="textRowCentered">Initial Average Score</th>
                                            <th scope="col" rowSpan={2} className="rowTableCenter textRowCentered">Action</th>
                                        </tr>
                                        <tr>
                                            <th scope="col">FollowUp Average Score</th>
                                            <th scope="col">FollowUp Total Patient</th>
                                            <th scope="col">FollowUp Total Score</th>
                                            <th scope="col">Initial Average Score</th>
                                            <th scope="col">Initial Total Patient</th>
                                            <th scope="col">Initial Total Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((riskData: any) => (
                                            <tr>
                                                <td>{riskData.demographic}</td>
                                                <td>{riskData.demographicValue}</td>
                                                <td>{Number(riskData.followUpAverageScore).toFixed(2)}</td>
                                                <td>{riskData.followUpTotalPatient}</td>
                                                <td>{riskData.followUpTotalScore}</td>
                                                <td>{Number(riskData.initialAverageScore).toFixed(2)}</td>
                                                <td>{riskData.initialTotalPatient}</td>
                                                <td>{riskData.initialTotalScore}</td>
                                                <td><ButtonDetail onClickButton={() => onClickDetail(riskData.demographic, riskData.dv)} wordingButton="Detail" /></td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3 pb-2 d-flex">
                        <SwitchButton hideEmail={hideEmail} onSwitch={() => setHideEmail(!hideEmail)} wordingSwitch="Hide patient details when download" />
                        <ButtonDownload onClickButton={downloadApi} wordingButton="Download" />
                    </div>
                    <div className="row pb-2 d-flex">
                        <div className="col">
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default RiskDemographyList;
