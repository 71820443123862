import axios from "axios"
import { useEffect, useState } from "react"
import { MdDownload } from "react-icons/md"
import { RootStateOrAny, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import ListAnswer, { detailParams } from "./ListAnswer"
import ParticipantInformation from "./ParticipantInformation"

const ParticipantDetailPage = () => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const token = auth.token || ""
  const { participantId, questionnaireId } = useParams<detailParams>()
  const [download, setDownload] = useState(false)

  useEffect(() => {
    async function downloadApi() {
      try {
        const response = await axios.get(
          // `${process.env.REACT_APP_API_URL}/admin/participant-answer/${participantId}/${questionnaireId}?download=true`,
          `${process.env.REACT_APP_API_URL}/admin/participant-answer/download/${participantId}/${questionnaireId}`,
          {
            responseType: "blob",
            headers: { Authorization: "Bearer " + token },
          }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "participation-detail-answer.csv");
        link.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 0);
        setDownload(false);
      } catch (e) {
        alert("Download failed")
      }
    }

    if (download) {
      downloadApi();
    }
  }, [download]); // eslint-disable-line  react-hooks/exhaustive-deps

  return (
    <div className="container-fluid">
      <div className="row p-5">
        <div className="col pt-2 bg-white">
          <div className='row px-3 pb-2' style={{ justifyContent: 'start' }}>
            <span className="material-icons-outlined mr-2">person</span>
            <span className="page-title">Patient detail</span>
          </div>
          <ParticipantInformation />
        </div>
      </div >

      <div className="row pb-5 px-5">
        <div className="col pt-2 bg-white">
          <div className='row px-3 pb-2' style={{ justifyContent: 'start' }}>
            <span className="material-icons-outlined mr-2">person</span>
            <span className="page-title">PATIENT RESPONSE</span>
          </div>

          <ListAnswer />

          <div className="d-flex flex-row-reverse bd-highlight">
            <div className="pb-5 bd-highlight">
              <button className="btn btn-outline-primary" type="button" onClick={() => setDownload(true)}>
                <MdDownload /> Download
              </button>
            </div>
          </div>
        </div>
      </div >
    </div >

  )
}
export default ParticipantDetailPage