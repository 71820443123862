import RegisterForm from './RegisterForm'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
    container: {
        background: 'linear-gradient(45deg, #f3f3f3, #f3f3f3, #b7b6e1, transparent)'    
    },
    logo : {
        top : '40px',
        left : '50px'
    }
})

const RegisterPage = () => {
    const classes = useStyles();
    
    return(
        <div className={`container-fluid ${classes.container} bg-light`}  >
        <div className='row h-100 py-3 my-md-0 d-flex align-items-center justify-content-center position-relative'>
            <div className='col-md-6'>
                <RegisterForm />
            </div>
        </div>
    </div>
    )
}
export default RegisterPage