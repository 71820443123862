import { API } from "./api.middleware"

export const questionnaireAPI = async (token: string) => {
    return await API({
        url : '/user/questioner' ,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const postUserStatusQuestionnaireAPI = async (token: string) => {
    return await API({
        url : '/user/questioner' ,
        method : 'POST',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const getAdminQuestionnaireListAPI = async (token: string) => {
    return await API({
        url : '/admin/questioner' ,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const getAdminQuestionnaireListDetailAPI = async (token: string, id: string) => {
    return await API({
        url : '/admin/questioner/' + id ,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const storeAdminCreateQuestionnaireAPI = async (token: string, data: object) => {
    return await API({
        url : '/admin/questioner/',
        method : 'POST',
        headers: { Authorization: "Bearer " + token },
        data,
    }).then( res => res.data )
}

export const putAdminUpdateQuestionnaireAPI = async (token: string, id: string, data: object) => {
    return await API({
        url : '/admin/questioner/'+ id,
        method : 'PUT',
        headers: { Authorization: "Bearer " + token },
        data,
    }).then( res => res.data )
}

export const destroyAdminDeleteQuestionnaireAPI = async (token: string, id: string) => {
    return await API({
        url : '/admin/questioner/'+ id,
        method : 'DELETE',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const storeAdminCreateQuestionAPI = async (token: string, data: object) => {
    return await API({
        url : '/admin/question/',
        method : 'POST',
        headers: { Authorization: "Bearer " + token },
        data,
    }).then( res => res.data )
}

export const destroyAdminDeleteQuestionAPI = async (token: string, id: string) => {
    return await API({
        url : '/admin/question/'+ id,
        method : 'DELETE',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const getAdminQuestionDetailAPI = async (token: string, id: string) => {
    return await API({
        url : '/admin/question/' + id,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const putAdminUpdateQuestionAPI = async (token: string, id: string, data: object) => {
    return await API({
        url : '/admin/question/'+ id,
        method : 'PUT',
        headers: { Authorization: "Bearer " + token },
        data,
    }).then( res => res.data )
}

export const storeAdminCreateQuestionClusterAPI = async (token: string, data: object) => {
    return await API({
        url : '/admin/questioner-cluster',
        method : 'POST',
        headers: { Authorization: "Bearer " + token },
        data,
    }).then( res => res.data )
}

export const getAdminQuestionClusterDetailAPI = async (token: string, id: string) => {
    return await API({
        url : '/admin/questioner-cluster/' + id,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const putAdminUpdateQuestionClusterAPI = async (token: string, id: string, data: object) => {
    return await API({
        url : '/admin/questioner-cluster/'+ id,
        method : 'PUT',
        headers: { Authorization: "Bearer " + token },
        data,
    }).then( res => res.data )
}

export const destroyAdminDeleteQuestionClusterAPI = async (token: string, id: string) => {
    return await API({
        url : '/admin/questioner-cluster/'+ id,
        method : 'DELETE',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}