// import MenuNav from '../Navbar/HeaderNav'
import SlideShowUpload from './SlideshowUpload'

const SlideshowUploadPage = () => {
    return(
        <>
        {/* <MenuNav/> */}
        <div className='container'  >
            <SlideShowUpload/>
        </div>
        </>
    )
}
export default SlideshowUploadPage