import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useState } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import { RootStateOrAny, useSelector } from 'react-redux';
import useGetParticipantReport from "../../../util/CustomHooks/UseGetParticipantReport"

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  plugins: {
    title: {
      display: true,
      text: 'Participants {n} Chart',
      font: {
        size: 16
      }
    },
  },
  responsive: true,
};

export const PieChartAge = () => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.report || {}
  const myAge = myReport.age || "N/A"
  const over_60 = myAge.over_60 || "N/A"
  const year_18_29 = myAge.year_18_29 || "N/A"
  const year_30_46 = myAge.year_30_46 || "N/A"
  const year_47_60 = myAge.year_47_60 || "N/A"
  const [isLoading, setLoading] = useState(false)
  const age = ["over 60", "47-60", "30-46", "18-29"]
  const ages = [over_60.percent, year_47_60.percent, year_30_46.percent, year_18_29.percent]

  useGetParticipantReport(auth, setLoading);

  const data = {
    labels: age.map(a => a),
    datasets: [
      {
        data: ages.map(age => Math.floor(age)),
        backgroundColor: [
          'rgba(255, 92, 92, 1)',
          'rgb(54, 162, 235)',
          'rgba(253, 172, 66, 1)',
          'rgba(57, 217, 138, 1)',
          'rgba(230, 229, 230, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <>
            <Pie data={data} options={options} />
          </>
      }

    </>
  )
}

export const PieChartGender = () => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.report || "N/A"
  const myGender = myReport.gender || "N/A"
  const male = myGender.male || "N/A"
  const female = myGender.female || "N/A"
  const na = myGender.na || "N/A"
  const [isLoading, setLoading] = useState(false)
  const gender = [male.percent, female.percent, na.percent]
  const genders = ["male", "female", "Prefer not to say"]

  useGetParticipantReport(auth, setLoading);

  const data = {
    labels: genders.map(gndr => gndr),
    datasets: [
      {
        data: gender.map(gender => Math.floor(gender)),
        backgroundColor: [
          'rgba(255, 92, 92, 1)',
          'rgb(54, 162, 235)',
          'rgba(253, 172, 66, 1)',
          'rgba(57, 217, 138, 1)',
          'rgba(230, 229, 230, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <>
            <Pie data={data} options={options} />
          </>
      }

    </>
  )
}


export const PieChartOccupation = () => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.report || {}
  const myOccupation = myReport.occupation || "N/A"
  const agriculture_farming = myOccupation.agriculture_farming || "N/A"
  const building = myOccupation.building || "N/A"
  const business = myOccupation.business || "N/A"
  const education = myOccupation.education || "N/A"
  const factory_workers = myOccupation.factory_workers || "N/A"
  const health = myOccupation.health || "N/A"
  const hospitality = myOccupation.hospitality || "N/A"
  const other_professional = myOccupation.other_professional || "N/A"
  const retire = myOccupation.retire || "N/A"
  const student = myOccupation.student || "N/A"
  const unemployed = myOccupation.unemployed || "N/A"
  const occupationValue = [agriculture_farming.val, building.val, business.val, education.val, factory_workers.val, health.val, hospitality.val, other_professional.val, retire.val, student.val, unemployed.val]

  const [isLoading, setLoading] = useState(false)

  useGetParticipantReport(auth, setLoading);
  
  const data = {
    labels: Object.keys(myOccupation),
    datasets: [
      {
        data: occupationValue.map(op => Math.floor(op)),
        backgroundColor: [
          'rgba(255, 92, 92, 1)',
          'rgb(54, 162, 235)',
          'rgba(253, 172, 66, 1)',
          'rgba(57, 217, 138, 1)',
          'rgba(230, 229, 230, 1)',
          'rgb(	252	199	7)',
          'rgb(254,145,26)',
          'rgb(202,139,60)',
          'rgb(234, 110,20)',
          'rgb(144,111,8)'
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <>
            <Doughnut data={data} options={options} />
          </>
      }

    </>
  )
}

export const PieChartAetiology = () => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.report || "N/A"
  const myAetiology = myReport.aetiology || "N/A"
  const ipv = myAetiology.ipv || "N/A"
  const falls = myAetiology.falls || "N/A"
  const accident = myAetiology.accident || "N/A"

  const [isLoading, setLoading] = useState(false)

  useGetParticipantReport(auth, setLoading);

  const aetiology = [ipv.percent, falls.percent, accident.percent]
  const aetiologies = ["Ipv", "falls", "Accident"]
  const data = {
    labels: aetiologies.map(atls => atls),
    datasets: [
      {
        data: aetiology.map(myaetiology => Math.floor(myaetiology)),
        backgroundColor: [
          'rgba(255, 92, 92, 1)',
          'rgb(54, 162, 235)',
          'rgba(253, 172, 66, 1)',
          'rgba(57, 217, 138, 1)',
          'rgba(230, 229, 230, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <>
            <Pie data={data} options={options} />
          </>
      }

    </>
  )
}

export const PieChartFracture = () => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.report || {}
  const myFractureSite = myReport.fracture_site || "N/A"
  const mandible = myFractureSite.mandible || "N/A"
  const nasal = myFractureSite.nasal || "N/A"
  const zygomatic = myFractureSite.zygomatic || "N/A"
  const maxilla = myFractureSite.maxilla || "N/A"
  const orbit = myFractureSite.orbit || "N/A"
  const multiple_sites = myFractureSite.multiple_sites || "N/A"
  const fractureValue = [mandible.val, nasal.val, zygomatic.val, maxilla.val, orbit.val, multiple_sites.val]
  const [isLoading, setLoading] = useState(false)

  useGetParticipantReport(auth, setLoading);

  const data = {
    labels: Object.keys(myFractureSite),
    datasets: [
      {
        data: fractureValue.map(op => Math.floor(op)),
        backgroundColor: [
          'rgba(255, 92, 92, 1)',
          'rgb(54, 162, 235)',
          'rgba(253, 172, 66, 1)',
          'rgba(57, 217, 138, 1)',
          'rgba(230, 229, 230, 1)',
          'rgb(	252	199	7)',
          'rgb(254,145,26)',
          'rgb(202,139,60)',
          'rgb(234, 110,20)',
          'rgb(144,111,8)'
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <>
            <Doughnut data={data} options={options} />
          </>
      }

    </>
  )
}

export const PieChartNumberPresentation = () => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.report || {}
  const myPresentation = myReport.number_presentation || "N/A"
  const first = myPresentation.first || "N/A"
  const second = myPresentation.second || "N/A"
  const over_3 = myPresentation.over_3 || "N/A"
  const NumberPresentationValue = [first.val, second.val, over_3.val]

  const [isLoading, setLoading] = useState(false)

  useGetParticipantReport(auth, setLoading);

  const data = {
    labels: Object.keys(myPresentation),
    datasets: [
      {
        data: NumberPresentationValue.map(op => Math.floor(op)),
        backgroundColor: [
          'rgba(255, 92, 92, 1)',
          'rgb(54, 162, 235)',
          'rgba(253, 172, 66, 1)',
          'rgba(57, 217, 138, 1)',
          'rgba(230, 229, 230, 1)',
          'rgb(	252	199	7)',
          'rgb(254,145,26)',
          'rgb(202,139,60)',
          'rgb(234, 110,20)',
          'rgb(144,111,8)'
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <>
            <Doughnut data={data} options={options} />
          </>
      }

    </>
  )
}