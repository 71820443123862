import { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getDetailParticipanAnswerAction, sendNotificationParticipantAction } from "../../../redux/actions/partisipan.action"
import { detailParams } from "./ListAnswer"
import getOrdinal from "../../../util/getOrdinal/getOrdinal"
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Switch } from "@mui/material"
import { AiFillCloseCircle } from "react-icons/ai"

const ParticipantInformation = () => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const dispatch = useDispatch()
  const { participantId, questionnaireId } = useParams<detailParams>()
  const [isLoading, setLoading] = useState(false)
  const [myParticipantList, setMyParticipantList] = useState<any>([])
  const [myParticipantDetail, setMyParticipantDetail] = useState<any>([])
  const myGroup = myParticipantList.group || "N/A"
  const myMedicalCenter = myParticipantList.medicalCentre || "N/A"
  const participantSchedule = myParticipantList.participantSchedule || []
  const [mySchedule, setMySchedule] = useState<any>([])

  async function getParticipantDetail() {
    setLoading(true)
    const res: any = await dispatch(getDetailParticipanAnswerAction(auth.token, participantId, questionnaireId))
    const data = res.data
    setMyParticipantList(data.participant)
    setMyParticipantDetail(data.items)
    setLoading(false)
  }


  useEffect(() => {

    getParticipantDetail()
    setMySchedule([...(participantSchedule || []).map((exm: any) => ({
      questionerCount: exm.questionerCount,
      sendNotification: exm.sendNotification
    }))])
  }, [])  // eslint-disable-line  react-hooks/exhaustive-deps

  console.log(mySchedule)

  const stopSendNotification = (questionaireCount: number) => {
    const data = {
      "sendNotification": false,
      "questionerCount": questionaireCount
    }

    async function stopNotifQuestionaire() {
      const res: any = await dispatch(
        sendNotificationParticipantAction(auth.token, participantId, questionnaireId, data)
      );
      if (res.code === 200) {
        console.log("berhasil stop notif")
      } else {
        console.error(res.code)
      }
    }
    stopNotifQuestionaire();

  }

  const startSendNotification = (questionaireCount: number) => {
    const data = {
      "sendNotification": true,
      "questionerCount": questionaireCount
    }

    async function startNotifQuestionaire() {
      const res: any = await dispatch(
        sendNotificationParticipantAction(auth.token, participantId, questionnaireId, data)
      );
      if (res.code === 200) {
        console.log("berhasil start notif")
        // window.location.reload()
      } else {
        console.error(res.code)
      }
    }
    startNotifQuestionaire();
  }
  const handleChange = (questionaireCount: number, sendNotification: boolean) => {
    if (!sendNotification) {
      startSendNotification(questionaireCount)
    }
    else {
      stopSendNotification(questionaireCount)
    }
  };

  //Modal untuk kesalahan klik
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false)
  const [showModalNotifStart, setShowModalNotifStart] = useState(false);
  const handleCloseModalStart = () => setShowModalNotifStart(false)


  const renderModalstartNotification = () => (
    <Dialog
      open={showModalNotifStart}
      onClose={handleCloseModalStart}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="id">
        <Box display="flex" alignItems="center">
          <Box style={{ justifyContent: "flex-end", display: "flex", width: "100%" }}>
            <IconButton onClick={handleCloseModalStart}>
              <AiFillCloseCircle />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your notification has already started
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )

  const renderModalstopNotification = () => (
    <Dialog
      open={showModal}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="id">
        <Box display="flex" alignItems="center">
          <Box style={{ justifyContent: "flex-end", display: "flex", width: "100%" }}>
            <IconButton onClick={handleCloseModal}>
              <AiFillCloseCircle />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your notification has already stoped
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )

  return (
    <>
      <table className="table table-bordered">

        <thead style={{ backgroundColor: "rgba(215, 215, 250, 1)" }}>
          <tr>
            <th scope="col" colSpan={6}>Patient Demography</th>
          </tr>

        </thead>
        <tbody style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
          <tr>
            <td>Email</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myParticipantList.email}</td>
            <td>Post Code</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myParticipantList.postcode}</td>

          </tr>
          <tr>
            <td>Age</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myParticipantList.ageRange}</td>
            <td>Aetiology</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myParticipantList.aetiology}</td>
          </tr>
          <tr>
            <td>Gender</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myParticipantList.gender || "Prefer not to say"}</td>
            <td>Fracture Site</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myParticipantList.fractureSite}</td>
          </tr>
          <tr>
            <td>Occupation</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myParticipantList.occupation}</td>
            <td>Presentation ED</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myParticipantList.presentationEd}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myParticipantList.phoneNumber || "N/A"}</td>
            <td>Medical Center Name</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myMedicalCenter.name}</td>
          </tr>
        </tbody>
        <thead style={{ backgroundColor: "rgba(215, 215, 250, 1)" }}>
          <tr>
            <th scope="col" colSpan={6}>Questioner Info</th>
          </tr>

        </thead>
        <tbody style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
          <tr>
            <td>Group Name</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myGroup.name}</td>
            <td>Questionnaire Name</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myParticipantDetail?.name}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myGroup.description}</td>
            <td>Description</td>
            <td>:</td>
            <td>{isLoading ? "Loading..." : myParticipantDetail?.description}</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-bordered">
        <thead style={{ backgroundColor: "rgba(215, 215, 250, 1)" }}>
          <tr>
            <th scope="col" colSpan={3}>Questioner Schedule</th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
          {participantSchedule.map((ps: any) => (
            <>
              <tr>
                <td>Date of {`${ps.questionerCount}${getOrdinal(ps.questionerCount)}`} survey</td>
                <td>:</td>
                <td className="d-flex" style={{ justifyContent: 'space-between' }}>{dayjs.tz(ps.nextSchedule, "Australia/Sydney").format('DD MMMM YYYY, hh:mm A') || "loading"}
                  <div>
                    {ps.sendNotification ? <Switch checked={ps.sendNotification} onChange={() => handleChange(ps.questionerCount, ps.sendNotification)} /> : <Switch checked={ps.sendNotification} onChange={() => handleChange(ps.questionerCount, ps.sendNotification)} />}
                    Notify

                  </div>

                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
      {renderModalstartNotification()}
      {renderModalstopNotification()}
    </ >
  )
}
export default ParticipantInformation