import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { rootReducer } from './redux/reducers';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk';
import { Store, AnyAction, createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const store : Store<any, AnyAction> = createStore(rootReducer, applyMiddleware(thunk))
const persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={(<div>Loading..</div>)}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();

reportWebVitals();
