import { useRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getAdminQuestionnaireListDetailAction, updateAdminEditQuestionnaireAction } from "../../../redux/actions/questionnaire.action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../../redux/actions/auth.action";

interface IParams {
    id: string;
}

interface IData {
    createdAt: string;
    description: string;
    id: string;
    isActive: boolean;
    name: string;
    questionCluster: any;
    schedule: any;
    updatedAt: string;
}

const UpdateQuestionnaire = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    let { id } = useParams<IParams>();
    const name = useRef<HTMLInputElement>(null);
    const description = useRef<HTMLTextAreaElement>(null);
    const [questionnaire, setQuestionnaire] = useState<Partial<IData>>({});
    const [loading, setLoading] = useState(true);

    const auth = useSelector((state: RootStateOrAny) => state.auth);

    useEffect(() => {
        !auth.token && history.push("/");
        auth.token && !auth.user && dispatch(getUserAction(auth.token));

        if (auth.successLoginParticipant) {
            history.push("/register");
        }
    }, [history, auth.token, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getQuestionnaireListDetail() {
            const res: any = await dispatch(
                getAdminQuestionnaireListDetailAction(auth.token, id)
            );
            setQuestionnaire(res.data);
            setLoading(false);
        }
        getQuestionnaireListDetail();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = () => {
        const data = {
            "name": name.current?.value,
	        "description": description.current?.value
        }

        async function putQuestionnaire() {
            const res: any = await dispatch(
                updateAdminEditQuestionnaireAction(auth.token, id, data)
            );
            if(res.code === 200) {
                history.push("/admin/questionnaire/" + id);
            } else {
                console.error(res.code)
            }
        }
        putQuestionnaire();
    };

    if (loading) {
        return (
            <div className="container mt-3">
                <p>Loading ...</p>
            </div>
        );
    }
    return (
        <div className="container mt-3">
            <h5>Edit Questionnaire</h5>
            <div className="card">
                <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Name"
                            ref={name}
                            defaultValue={questionnaire.name}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                            className="form-control"
                            id="description"
                            placeholder="Description"
                            rows={3}
                            ref={description}
                            defaultValue={questionnaire.description}
                        />
                    </div>
                    <div className="text-right">
                        <button
                            className="btn btn-success"
                            // type="submit"
                            // onClick={() => history.push("/admin/questionnaire")}
                            onClick={onSubmit}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateQuestionnaire