import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from 'react';

const HeaderNav = (props: any) => {
    const toggleMenu: any = props.toggleMenu
    const isTogle: any = props.toggleMenu
    // const [isTogle,setTogle]=useState(false)

    useEffect(() => {
        // toggleMenu(!isTogle)
        // setTogle(toggleMenu)
    }, [])

    return (
        <nav className="d-flex navbar-light shadow-sm p-0 bg-secondary" style={{  justifyContent: "start" }} >
            <div className='d-flex align-items-center bg-primary' style={{ padding: 0 }} >
                {props.isTogle ?
                    <>
                        <button className="navbar-toggler mx-3 d-inline-block text-white border-primary" onClick={toggleMenu} type="button">
                            <span className="material-icons">menu</span>
                        </button>

                    </>
                    :
                    <>
                        <div style={{ width: "250px" }} className=''>
                            <button className="navbar-toggler mx-3 d-inline-block text-white border-primary " onClick={toggleMenu} type="button">
                                <span className="material-icons">close</span>
                            </button>

                        </div>
                    </>
                }

            </div>
            <div className='d-flex bg-secondary p-2 align-items-center'>
                <img src='/assets/img/logo_fix.png' alt='logo' width={38} height={38} />
                <div className="d-block ml-2 text-white">
                    <span className='d-block' style={{ fontSize: "20px", fontWeight:600 }}>
                        Screening & Brief Intervention App
                    </span>
                    <span>For Alcohol-related Trauma</span>
                </div>
            </div>

        </nav>
    )
}
export default HeaderNav