import IResponse from '../../interfaces/IResponse'
import { questionnaireAPI, postUserStatusQuestionnaireAPI, getAdminQuestionnaireListAPI, getAdminQuestionnaireListDetailAPI, storeAdminCreateQuestionnaireAPI, putAdminUpdateQuestionnaireAPI, destroyAdminDeleteQuestionnaireAPI, storeAdminCreateQuestionAPI, destroyAdminDeleteQuestionAPI, getAdminQuestionDetailAPI, putAdminUpdateQuestionAPI, storeAdminCreateQuestionClusterAPI, getAdminQuestionClusterDetailAPI, putAdminUpdateQuestionClusterAPI, destroyAdminDeleteQuestionClusterAPI } from '../api/api.questionnaire'

export const getQuestionnaireAction = (token: string) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await questionnaireAPI(token)
           return response
        }catch(error :any) {
            console.error('[questionnaire]: ', error)
            throw error
        }
    }
}

export const postUserStatusQuestionnaireAction = (token: string) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await postUserStatusQuestionnaireAPI(token)
           return response
        }catch(error :any) {
            console.error('[questionnaire]: ', error)
            throw error
        }
    }
}

export const getAdminQuestionnaireListAction = (token: string) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await getAdminQuestionnaireListAPI(token)
           return response
        }catch(error :any) {
            console.error('[questionnaireList]: ', error)
            throw error
        }
    }
}

export const getAdminQuestionnaireListDetailAction = (token: string, id:string) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await getAdminQuestionnaireListDetailAPI(token, id)
           return response
        }catch(error :any) {
            console.error('[questionnaireList]: ', error)
            throw error
        }
    }
}

export const storeAdminCreateQuestionnaireAction = (token: string, data: object) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await storeAdminCreateQuestionnaireAPI(token, data)
           return response
        }catch(error :any) {
            console.error('[questionnaireList]: ', error)
            throw error
        }
    }
}

export const updateAdminEditQuestionnaireAction = (token: string, id: string, data: object) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await putAdminUpdateQuestionnaireAPI(token, id, data)
           return response
        }catch(error :any) {
            console.error('[questionnaireList]: ', error)
            throw error
        }
    }
}

export const deleteAdminDeleteQuestionnaireAction = (token: string, id: string) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await destroyAdminDeleteQuestionnaireAPI(token, id)
           return response
        }catch(error :any) {
            console.error('[questionnaireList]: ', error)
            throw error
        }
    }
}

export const storeAdminCreateQuestionAction = (token: string, data: object) : Promise<IResponse> | any => {
        return async () => {
            try {
               const response : Promise<IResponse> = await storeAdminCreateQuestionAPI(token, data)
               return response
            }catch(error :any) {
                console.error('[create question]: ', error)
                throw error
            }
        }    
}

export const deleteAdminDeleteQuestionAction = (token: string, id: string) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await destroyAdminDeleteQuestionAPI(token, id)
           return response
        }catch(error :any) {
            console.error('[delete question]: ', error)
            throw error
        }
    }
}

export const getAdminQuestionDetailAction = (token: string, id:string) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await getAdminQuestionDetailAPI(token, id)
           return response
        }catch(error :any) {
            console.error('[question detail]: ', error)
            throw error
        }
    }
}

export const updateAdminEditQuestionAction = (token: string, id: string, data: object) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await putAdminUpdateQuestionAPI(token, id, data)
           return response
        }catch(error :any) {
            console.error('[question edit]: ', error)
            throw error
        }
    }
}

export const storeAdminCreateQuestionClusterAction = (token: string, data: object) : Promise<IResponse> | any => {
    return async () => {
        try {
           const response : Promise<IResponse> = await storeAdminCreateQuestionClusterAPI(token, data)
           return response
        }catch(error :any) {
            console.error('[create question cluster]: ', error)
            throw error
        }
    }    
}

export const getAdminQuestionClusterDetailAction = (token: string, id:string) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await getAdminQuestionClusterDetailAPI(token, id)
           return response
        }catch(error :any) {
            console.error('[question cluster detail]: ', error)
            throw error
        }
    }
}

export const updateAdminEditQuestionClusterAction = (token: string, id: string, data: object) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await putAdminUpdateQuestionClusterAPI(token, id, data)
           return response
        }catch(error :any) {
            console.error('[question cluster edit]: ', error)
            throw error
        }
    }
}

export const deleteAdminDeleteQuestionClusterAction = (token: string, id: string) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await destroyAdminDeleteQuestionClusterAPI(token, id)
           return response
        }catch(error :any) {
            console.error('[question cluster delete  ]: ', error)
            throw error
        }
    }
}
