import { useState } from "react"
import { RootStateOrAny, useSelector } from "react-redux"
import useGetParticipantReport from "../../../util/CustomHooks/UseGetParticipantReport"



export const OccupationTable = () => {
    const auth = useSelector((state:RootStateOrAny)=>state.auth)
    const report =useSelector((state:RootStateOrAny)=>state.report)
    const myReport=report.report || {}
    const myOccupation= myReport.occupation || "N/A"
    const agriculture_farming=myOccupation.agriculture_farming || "N/A"
    const building = myOccupation.building || "N/A"
    const business=myOccupation.business || "N/A"
    const education=myOccupation.education || "N/A"
    const factory_workers=myOccupation.factory_workers || "N/A"
    const health=myOccupation.health || "N/A"
    const hospitality=myOccupation.hospitality || "N/A"
    const other_professional=myOccupation.other_professional || "N/A"
    const retire=myOccupation.retire || "N/A" 
    const student=myOccupation.student || "N/A"
    const unemployed= myOccupation.unemployed || "N/A"
  
    const  [isLoading,setLoading]=useState(false)

    useGetParticipantReport(auth, setLoading);

    return(
        <>
        {
            isLoading?"Loading": <table className="table table-bordered text-center mt-5">
            <thead style={{backgroundColor:"rgba(215, 215, 250, 1)"}}>
                <tr>
                <th scope="col">Occupation</th>
                <th scope="col">Number of Participants</th>
                <th scope="col">Percentage (%)</th>
                </tr>
            </thead>
            <tbody style={{backgroundColor:"rgba(255, 255, 255, 1)"}}>
                <tr>
                    <td>Agriculture Farming</td>
                    <td>{agriculture_farming.val}</td>
                    <td> {Math.floor(agriculture_farming.percent)} </td>
                </tr>
                <tr>
                    <td>Building</td>
                    <td>{building.val}</td>
                    <td> {Math.floor(building.percent)} </td>
                </tr>
                 <tr>
                    <td>Business</td>
                    <td>{business.val}</td>
                    <td> {Math.floor(business.percent)} </td>
                </tr>
                <tr>
                    <td>Education</td>
                    <td>{education.val}</td>
                    <td> {Math.floor(education.percent)} </td>
                </tr>
                <tr>
                    <td>Factory workers</td>
                    <td>{factory_workers.val}</td>
                    <td> {Math.floor(factory_workers.percent)} </td>
                </tr>
                <tr>
                    <td>Health</td>
                    <td>{health.val}</td>
                    <td> {Math.floor(health.percent)} </td>
                </tr>
                <tr>
                    <td>Hospitality</td>
                    <td>{hospitality.val}</td>
                    <td> {Math.floor(hospitality.percent)} </td>
                </tr>
                <tr>
                    <td>Other profesional</td>
                    <td>{other_professional.val}</td>
                    <td> {Math.floor(other_professional.percent)} </td>
                </tr>
                <tr>
                    <td>Retire</td>
                    <td>{retire.val}</td>
                    <td> {Math.floor(retire.percent)} </td>
                </tr>
                <tr>
                    <td>Student</td>
                    <td>{student.val}</td>
                    <td> {Math.floor(student.percent)} </td>
                </tr>
                 <tr>
                    <td>Unemployed</td>
                    <td>{unemployed.val}</td>
                    <td> {Math.floor(unemployed.percent)} </td>
                </tr>
            </tbody>
            </table>
        }
        
        </>
    )
}
export const AgeTable = () => {  
  const auth = useSelector((state:RootStateOrAny)=>state.auth)
  const report =useSelector((state:RootStateOrAny)=>state.report)
  const myReport=report.report || {}
  const myAge=myReport.age || "N/A"
  const over_60=myAge.over_60 || "N/A"
  const year_18_29=myAge.year_18_29 || "N/A"
  const year_30_46=myAge.year_30_46 || "N/A"
  const year_47_60=myAge.year_47_60 || "N/A"
  const  [isLoading,setLoading]=useState(false)

  useGetParticipantReport(auth, setLoading);

    return(
        <>
       <table className="table table-bordered text-center mt-5">
        <thead style={{backgroundColor:"rgba(215, 215, 250, 1)"}}>
            <tr>
            <th scope="col">Ages</th>
            <th scope="col">Number of Participants</th>
            <th scope="col">Percentage (%)</th>
            </tr>
        </thead>
        {isLoading?"Loading ....":
        <tbody style={{backgroundColor:"rgba(255, 255, 255, 1)"}}>
            <tr>
                <td>18 - 29</td>
                <td> {year_18_29.val} </td>
                <td> {Math.floor(year_18_29.percent)} </td>
            </tr>
            <tr>
                <td> 30 - 46</td>
                <td> {year_30_46.val} </td>
                <td> {Math.floor(year_30_46.percent)} </td>
            </tr>
            <tr>
                <td> 47 - 60 </td>
                <td> {year_47_60.val} </td>
                <td> {Math.floor(year_47_60.percent)} </td>
            </tr>
             <tr>
                <td> Over 60 </td>
                <td> {over_60.val} </td>
                <td> {Math.floor(over_60.percent)} </td>
            </tr>
        </tbody>}
        </table>
        </>
    )
}

export const GenderTable = () => {
    const auth = useSelector((state:RootStateOrAny)=>state.auth)
    const report =useSelector((state:RootStateOrAny)=>state.report)
    const myReport=report.report || "N/A"
    const myGender=myReport.gender || "N/A"
    const male = myGender.male || "N/A"
    const female = myGender.female || "N/A"
    const na = myGender.na || "N/A"
    const  [isLoading,setLoading]=useState(false)
  
    useGetParticipantReport(auth, setLoading);

    return(
        <>
               <table className="table table-bordered text-center mt-5">
        <thead style={{backgroundColor:"rgba(215, 215, 250, 1)"}}>
            <tr>
            <th scope="col">Gender</th>
            <th scope="col">Number of Participants</th>
            <th scope="col">Percentage (%)</th>
            </tr>
        </thead>
        {isLoading?"Loading ... ":
        <tbody style={{backgroundColor:"rgba(255, 255, 255, 1)"}}>
            <tr>
                <td>Male</td>
                <td>{male.val}</td>
                <td>{Math.floor(male.percent)}</td>
            </tr>
            <tr>
                <td>Female</td>
                <td>{female.val}</td>
                <td>{Math.floor(female.percent)}</td>
            </tr>
            <tr>
                <td>Prefer not to say</td>
                <td>{na.val}</td>
                <td>{Math.floor(na.percent)}</td>
            </tr>
        </tbody>}
        </table>
        </>
    )
}

export const AetiologyTable = () => {
    const auth = useSelector((state:RootStateOrAny)=>state.auth)
    const report =useSelector((state:RootStateOrAny)=>state.report)
    const myReport=report.report || "N/A"
    const myAetiology=myReport.aetiology || "N/A"
    const ipv=myAetiology.ipv || "N/A"
    const falls = myAetiology.falls || "N/A"
    const accident = myAetiology.accident ||"N/A"
    const  [isLoading,setLoading]=useState(false)
  
    useGetParticipantReport(auth, setLoading);
    return(
        <>
               <table className="table table-bordered text-center mt-5">
        <thead style={{backgroundColor:"rgba(215, 215, 250, 1)"}}>
            <tr>
            <th scope="col">Aetiology</th>
            <th scope="col">Number of Participants</th>
            <th scope="col">Percentage (%)</th>
            </tr>
        </thead>
        {isLoading?"Loading ...":
        <tbody style={{backgroundColor:"rgba(255, 255, 255, 1)"}}>
            <tr>
                <td>Accident</td>
                <td>{accident.val}</td>
                <td>{Math.floor(accident.percent)}</td>
            </tr>
            <tr>
                <td>Falls</td>
                <td>{falls.val}</td>
                <td>{Math.floor(falls.percent)}</td>
            </tr> 
            <tr>
                <td>Ipv</td>
                <td>{ipv.val}</td>
                <td>{Math.floor(ipv.percent)}</td>
            </tr> 
        </tbody>}
        </table>
        </>
    )
}

export const FractureTable = () => {
    const auth = useSelector((state:RootStateOrAny)=>state.auth)
    const report =useSelector((state:RootStateOrAny)=>state.report)
    const myReport=report.report || "N/A"
    const myFractureSite=myReport.fracture_site || "N/A"
    const mandible=myFractureSite.mandible || "N/A"
    const nasal = myFractureSite.nasal ||"N/A"
    const zygomatic = myFractureSite.zygomatic || "N/A"
    const maxilla = myFractureSite.maxilla || "N/A"
    const orbit = myFractureSite.orbit || "N/A"
    const multiple_sites = myFractureSite.multiple_sites || "N/A"
    const  [isLoading,setLoading]=useState(false)
  
    useGetParticipantReport(auth, setLoading);
    return(
        <>
          <table className="table table-bordered text-center mt-5">
        <thead style={{backgroundColor:"rgba(215, 215, 250, 1)"}}>
            <tr>
            <th scope="col">Fracture Sites</th>
            <th scope="col">Number of Participants</th>
            <th scope="col">Percentage (%)</th>
            </tr>
        </thead>
        {isLoading?"Loading..." :
        <tbody style={{backgroundColor:"rgba(255, 255, 255, 1)"}}>
            <tr>
                <td>Mandible</td>
                <td>{mandible.val}</td>
                <td>{Math.floor(mandible.percent)}</td>
            </tr>
            <tr>
                <td>Maxilla</td>
                <td>{maxilla.val}</td>
                <td>{Math.floor(maxilla.percent)}</td>
            </tr>
            <tr>
                <td>Multiple Sites</td>
                <td>{multiple_sites.val}</td>
                <td>{Math.floor(multiple_sites.percent)}</td>
            </tr>
            <tr>
                <td>Nasal</td>
                <td>{nasal.val}</td>
                <td>{Math.floor(nasal.percent)}</td>
            </tr>
            <tr>
                <td>Orbit</td>
                <td>{orbit.val}</td>
                <td>{Math.floor(orbit.percent)}</td>
            </tr>
            <tr>
                <td>Zygomatic</td>
                <td>{zygomatic.val}</td>
                <td>{Math.floor(zygomatic.percent)}</td>
            </tr>
        </tbody>}
        </table> 
        </>
    )
}

export const LevelPresentationTable = () => {
    const auth = useSelector((state:RootStateOrAny)=>state.auth)
    const report =useSelector((state:RootStateOrAny)=>state.report)
    const myReport=report.report || "N/A"
    const myLevel=myReport.level_presentation || "N/A"
    const low=myLevel.low || "N/A"
    const moderate=myLevel.moderate || "N/A"
    const high=myLevel.high || ""
    const  [isLoading,setLoading]=useState(false)
    useGetParticipantReport(auth, setLoading);

    return(
        <>
       <table className="table table-bordered text-center mt-5">
        <thead style={{backgroundColor:"rgba(215, 215, 250, 1)"}}>
            <tr>
            <th scope="col">Level Presentation</th>
            <th scope="col">Number of Participants</th>
            <th scope="col">Percentage (%)</th>
            </tr>
        </thead>
        {isLoading?"Loading ... " : 
        <tbody style={{backgroundColor:"rgba(255, 255, 255, 1)"}}>
            <tr>
                <td>Low</td>
                <td>{low.val}</td>
                <td>{Math.floor(low.percent)}</td>
            </tr>
            <tr>
                <td>Moderate</td>
                <td>{moderate.val}</td>
                <td>{Math.floor(moderate.percent)}</td>
            </tr>
            <tr>
                <td>High</td>
                <td>{high.val}</td>
                <td>{Math.floor(high.percent)}</td>
            </tr>
        </tbody>}
        </table>
        </>
    )
}

export const NumberPresentationTable = () => {
    const auth = useSelector((state:RootStateOrAny)=>state.auth)
    const report =useSelector((state:RootStateOrAny)=>state.report)
    const myReport=report.report || "N/A"
    const myPresentation=myReport.number_presentation || "N/A"
    const first=myPresentation.first || "N/A"
    const second = myPresentation.second || "N/A"
    const over_3=myPresentation.over_3||"N/A"
    const  [isLoading,setLoading]=useState(false)
  
    useGetParticipantReport(auth, setLoading);
    return(
        <>
       <table className="table table-bordered text-center mt-5">
        <thead style={{backgroundColor:"rgba(215, 215, 250, 1)"}}>
            <tr>
            <th scope="col">Presentation ED</th>
            <th scope="col">Number of Participants</th>
            <th scope="col">Percentage (%)</th>
            </tr>
        </thead>
        {isLoading?"Loading ...":
        <tbody style={{backgroundColor:"rgba(255, 255, 255, 1)"}}>
            <tr>
                <td>First</td>
                <td>{first.val}</td>
                <td>{Math.floor(first.percent)}</td>
            </tr>
            <tr>
                <td>Second</td>
                <td>{second.val}</td>
                <td>{Math.floor(second.percent)}</td>
            </tr>
            <tr>
                <td>Over 3</td>
                <td>{over_3.val}</td>
                <td>{Math.floor(over_3.percent)}</td>
            </tr>
        </tbody>}
        </table>
        </>
    )
}


