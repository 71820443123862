const myGrouplist = ["Control group ", "Experimental group"];

const SlideShowUpload=()=>{
    return(
        <>
        <form className="p-4 p-lg-5 ">
        <h4>Upload slideshow</h4>
            <div className="form-group mt-3">
                <label htmlFor="medicalCenter">Name group</label>
                <select className="form-control" id="medicalCenter">
                    {myGrouplist.map((grp, index) => {
                        return (
                            <option key={index} value={grp}>
                                {grp}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea className="form-control" id="description" rows={3}></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="slideshow">Slideshow Upload</label>
                <div className="input-group">
            <div className="custom-file">
                <label className="custom-file-label" htmlFor="inputGroupFile04">
                    <input type="file" className="custom-file-input bg-light" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04"/>
                </label>
            
            </div>
            </div>
            </div>
            <div className='row d-flex align-items-center justify-content-center flex-column my-6'  >
                <button type="button" className='btn btn-primary' style={{backgroundColor: 'rgba(41, 22, 111, 1)'
,color:"white"}}>
                    Upload 
                </button>
             </div>
        </form>
        </>
    )
}

export default SlideShowUpload
