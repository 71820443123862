import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    header: {
        background: "#29166F",
        height: "103px",
        lineHeight: "103px",
        fontSize: "18px",
        textAlign: "center",
        color: "#FCFAFF",
        fontWeight: "600",
    },
    brand: {
        lineHeight: "1.5",
        display: "inline-block",
        verticalAlign: "middle",
    },
    fullHegiht: {
        height: '100vh'
    },
    fullHeightCol: {
        height: 'calc(100% - 103px)'
    }
  
});



const QuestionnaireResult = () => {
    const classes = useStyles();

    return (
        <>
            <div className="container-fluid bg-light">
                <div className="row justify-content-center">
                    <div className={`col-md-8 p-0 ${classes.fullHegiht}`}>
                        <nav className={classes.header}>
                            <p className={classes.brand}>Question Result</p>
                        </nav>
                        <div className={`px-4 bg-white pb-4 ${classes.fullHeightCol}`} >
                            <h3 className='pt-4'>Hi, There</h3>
                            <b>Thank you for participating in this survey, you'll be contacted in three months for the second part. </b>
                            <h5 className="mt-3">If you seeking further information or support regarding your treatment please talk to a member of your treatment team. </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuestionnaireResult;
