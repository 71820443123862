import IAction from '../../interfaces/IAction'
import * as types from '../types/medical.type'

const initialState={
    medical:[],
    isLoading:false,
    errMessage:null
}

const reducer=(state=initialState,action: IAction = { type: '', payload: {} })=>{
    switch(action.type){
        case types.GET_MEDICAL:return{
            ...state,
            medical:action.payload.medical,
            isLoading:false,
            errMessage:null
        }
        case types.MEDICAL_LOADING:return{
            ...state,
            isLoading:true,
            errMessage:null
        }
        case types.MEDICAL_FAILED:return{
            ...state,
            isLoading:false,
            errMessage:action.payload.errMessage
        }
        case types.GET_DETAIL_MEDICAL:return{
            ...state,
            detailMedical:action.payload.detailMedical,
            isLoading:false,
            errMessage:null
        }
        case types.CLEAR_DETAIL_MEDICAL:return{
            ...state,
            detailMedical:null,
            isLoading:false,
            errMessage:null
        }
        default :return state
    }
}

export default reducer