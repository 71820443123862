import { API } from "./api.middleware";
import axios from "axios";


export const generateS3 = async (filename: string, token: string) => {
    return await API({
        url : '/admin/upload' ,
        method : 'POST',
        data: {
            filename
        },
        headers:{Authorization: "Bearer " + token}
    }).then( res => res.data )
}

export const postS3 = async (data: FormData) => {
    return await axios({
        url: 'https://s3.ap-southeast-2.amazonaws.com/prod-wesgate-api-assets',
        method: 'POST',
        data,
        headers: {"Content-Type": "multipart/form-data"}
    }).then(res => res.status);
}

export const uploadS3 = async (file: File, filename: string, token: string) => {
    try {
        const resp = await generateS3(filename, token);
        const data = new FormData();
        data.append("acl", resp.data.url.fields["acl"]);
        data.append("bucket", resp.data.url.fields["bucket"]);
        data.append("key", resp.data.url.fields["key"]);
        data.append("X-Amz-Algorithm", resp.data.url.fields["X-Amz-Algorithm"]);
        data.append("X-Amz-Credential", resp.data.url.fields["X-Amz-Credential"]);
        data.append("X-Amz-Date", resp.data.url.fields["X-Amz-Date"]);
        data.append("X-Amz-Security-Token", resp.data.url.fields["X-Amz-Security-Token"]);
        data.append("X-Amz-Signature", resp.data.url.fields["X-Amz-Signature"]);
        data.append("Policy", resp.data.url.fields["Policy"]);
        data.append("file", file);
        await postS3(data);
        return resp.data.url.url + '/' + resp.data.url.fields.key;
    } catch (error) {
        return error;
    }
}