import { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ParticipantListRow } from "../../../interfaces/IDataRow";
import { getParticipanAction } from "../../../redux/actions/partisipan.action";
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime'
import { MdDownload } from "react-icons/md";
import axios from "axios";
import { Switch } from "@mui/material";

const customStyles = {
    headCells: {
        style: {
            backgroundColor: "rgba(215, 215, 250, 1)",
            fontSize: "14px",
            '&:nth-child(1)': {
                maxWidth: '50px',
                dispaly: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },

        },
    },
    cells: {
        style: {
            fontSize: '14px',
            color: 'black',
            '&:nth-child(1)': {
                maxWidth: '50px',
                dispaly: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        }
    }
}

const ParticipantList = () => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.extend(relativeTime)
    const history = useHistory()
    const auth = useSelector((state: RootStateOrAny) => state.auth)
    const [hideID, setHideID] = useState(false)
    const dispatch = useDispatch()
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState(false);
    const [download, setDownload] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [paginate, setPaginate] = useState(0);
    const [limit, setLimit] = useState(0);
    const [form, setForm] = useState({
        status: "not-responding"
    });
    // let index= ((paginate-1)*limit);

    const fetchParticipantDetail = async (page: number) => {
        setLoading(true)
        const res: any = await dispatch(getParticipanAction(auth.token, form.status, page, perPage))
        const data = res.data
        setLoading(false)
        setData(data)
        setTotalRows(res.total);
        setLoading(false);
        setPaginate(page);
        setLimit(res.limit);
    };

    const onSearch = (e: React.FormEvent<HTMLFormElement>, page: number) => {
        e.preventDefault()
        dispatch(getParticipanAction(auth.token, form.status, page, perPage))
    }

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        const res: any = await dispatch(getParticipanAction(auth.token, form.status, page, newPerPage))
        const data = res.data
        setLoading(false)
        setData(data)
        setTotalRows(res.total);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const onChangeForm = (
        e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        e.preventDefault()
        const { name, value } = e.currentTarget;
        setForm({
            ...form,
            [name]: value,
        });
        dispatch(getParticipanAction(auth.token, value, 1, perPage))
    };

    async function downloadApi() {
        try {
            const response = await axios.get(
                hideID ? `${process.env.REACT_APP_API_URL}/admin/participant-report/data-answer?hideid=true` : `${process.env.REACT_APP_API_URL}/admin/participant-report/data-answer`,
                {
                    responseType: "blob",
                    headers: { Authorization: "Bearer " + auth.token },
                }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "participation-list-answer.csv");
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0);
            setDownload(false);
        } catch (e) {
            alert("Download failed")
        }
    }

    useEffect(() => {
        if (download) {
            downloadApi();
        }
        setHideID(false)
    }, [download]); // eslint-disable-line  react-hooks/exhaustive-deps

    const renderSearch = () => {
        return (
            <form className="input-group col-md-4 col-sm-6 mt-3"
                onSubmit={() => onSearch}

            >
                <span className="mt-1 mr-3" style={{ fontSize: '1rem' }}>Filter</span>
                <select
                    name="status"
                    onChange={onChangeForm}
                    className="form-control"
                    id="status"
                >
                    <option value="not-responding">Not Responding</option>
                    <option value="ongoing&questioner=1">Answered questionnaire 1 but incomplete</option>
                    <option value="complete&questioner=1">Questioner 1 is Completed</option>
                    <option value="ongoing&questioner=2">Answered questionnaire 2 but incomplete</option>
                    <option value="due&questioner=2">Questionnaire 2 (Due)</option>
                    <option value="complete&questioner=2">Questionnaire 2 is Completed</option>
                </select>
            </form>
        )
    }

    useEffect(() => {
        fetchParticipantDetail(1)
    }, [auth.token, form.status]) // eslint-disable-line  react-hooks/exhaustive-deps

    const onClickRow = (participantId: string, questionnaireId: string) => {
        history.push(`/admin/participant-detail/${participantId}/${questionnaireId}`)
    }

    const customSortFunction = (rowUpdatea: any, rowUpdateb: any) => {
        return rowUpdatea.updatedat.localeCompare(rowUpdateb.updatedat);
    }

    const customSortFunctionCreated = (rowCreatedA: any, rowCreatedB: any) => {
        return rowCreatedA.createdat.localeCompare(rowCreatedB.createdat);
    }

    const columns: TableColumn<ParticipantListRow>[] = [
        {
            name: 'No',
            cell: (row, index) => index += ((paginate - 1) * limit) + 1,
            grow: 1,
            sortable: true,
            compact: true
        },
        {
            name: 'Email',
            selector: (row) => row.email || "N/A",
            sortable: true,
            compact: true,
            wrap: true
        },
        {
            name: "Registration date",
            selector: (row) => dayjs.tz(row.createdat, "Australia/Sydney").format('DD MMMM YYYY, hh:mm A'),
            format: (row) => dayjs.tz(row.createdat, "Australia/Sydney").format('DD MMMM YYYY, hh:mm A') || "N/A",
            sortable: true,
            sortFunction: customSortFunctionCreated,
            wrap: true
        },
        {
            name: 'Phone',
            selector: (row) => row.phonenumber || "N/A",
            sortable: true,
            wrap: true
        },
        {
            name: 'Gender',
            selector: (row) => row.gender || "Prefer not to say",
            sortable: true,
            wrap: true
        },
        {
            name: 'Age',
            selector: (row) => row.agerange || "N/A",
            sortable: true,
            wrap: true
        },
        {
            name: 'Medical',
            selector: (row) => row.medicalcentrename || "N/A",
            sortable: true,
            wrap: true
        },
        {
            name: 'Group',
            selector: (row) => row.groupname || "N/A",
            sortable: true,
            wrap: true
        },
        {
            name: 'Status',
            selector: (row) => row.status || "N/A",
            format: (row) => row.status || "N.A",
            sortable: true,
            wrap: true
        },
        {
            name: 'Response date',
            selector: (row) => dayjs.tz(row.updatedat, "Australia/Sydney").format('DD MMMM YYYY, hh:mm A'),
            format: (row) => dayjs.tz(row.updatedat, "Australia/Sydney").format('DD MMMM YYYY, hh:mm A') || "N/A",
            sortable: true,
            sortFunction: customSortFunction,
            wrap: true,
            compact: true
        },
        {
            name: 'Action',
            selector: (row) => row.participantid && row.questionerid,
            format: row => {
                return <div className='row' >
                    <div className='col-12' >
                        <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => onClickRow(row.participantid, row.questionerid)}
                        >
                            Detail
                        </button>
                    </div>
                </div>
            }
        },
    ];
    const handlePageChange = (page: any) => {
        fetchParticipantDetail(page);
    };

    console.log(hideID)

    return (
        <div className="container-fluid">
            <div className="row p-5">
                <div className="col pr-5 pt-2 bg-white">
                    <div className="row px-3 pb-2 d-flex" style={{ justifyContent: 'space-between' }}>
                        <div className="mt-3">
                            <span className="material-icons-outlined mr-2">question_answer</span>
                            <span className="page-title">Patient's Response</span>
                        </div>

                        {renderSearch()}

                    </div>
                    <div className="row pb-2 d-flex">
                        <div className="col">
                            <DataTable
                                columns={columns}
                                progressPending={loading}
                                data={data}
                                customStyles={customStyles}
                                noHeader
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                highlightOnHover={true}
                                pointerOnHover={true}
                                responsive={true}
                                striped
                            />

                        </div>
                    </div>

                    <div className="row px-3 pb-2 d-flex">
                        {/* <div className="col-md-10 pt-3 border-top d-flex" style={{ justifyContent: 'end' }}>
                            <div className="d-flex align-items-center">Hide patient details</div>
                            <div><Switch checked={hideID} onChange={() => setHideID(!hideID)} /></div>

                        </div> */}
                        <div className="col pt-3 border-top d-flex" style={{ justifyContent: 'end' }}>
                            <div className="d-flex align-items-center">Hide patient details when download</div>
                            <div><Switch checked={hideID} onChange={() => setHideID(!hideID)} /></div>
                            <button className="btn btn-outline-primary" type="button" onClick={() => setDownload(true)}>
                                <MdDownload /> Download
                            </button>
                        </div>


                    </div>
                    <div className="row pb-2 d-flex">
                        <div className="col">
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default ParticipantList;
