import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    headerTableLeft: {
        width: "20%",
    },
    scheduleTable: {
        width: "100%",
        "& thead": {
            borderTop: "1px solid #000000",
            borderBottom: "1px solid #000000",
            "& tr": {
                "& th": {
                    padding: "8px 0px 8px 0px",
                },
            },
        },
        "& tbody": {
            "& tr": {
                borderBottom: "1px solid #E5E5E5",
                "& td": {
                    padding: "24px 0px 24px 0px",
                },
                "&:last-child": {
                    borderBottom: "none",
                },
            },
        },
    },
    scheduleCount: {
        padding: "10px 14px",
        background: "#ffffff",
        borderRadius: "10px",
        display: "inline-block",
    },
    selectCount: {
        marginLeft: "14px",
        display: "inline-block",
        "& select": {
            padding: "10px 14px",
            background: "#ffffff",
            borderRadius: "10px",
            border: "none",
            height: "auto",
        },
    },


    toggle: {
        "--width": "44px",
        "--height": "calc(var(--width) / 2)",
        "--border-radius": "calc(var(--height) / 2)",
        display: "inline-block",
        cursor: "pointer",
    },
    toggleLabel: {
        fontSize: "20px",
        fontWeight: "500",
        display: "inline-block",
        marginRight: "15px",
    },
    toggleInput: {
        display: "none",
        "&:checked ~ div" : {
           background: "#29166F",
        },
        "&:checked ~ div:after" : {
            transform: "translateX(var(--height))",
        }
    },
    toggleFill: {
        display: "inline-block",
        position: "relative",
        width: "var(--width)",
        height: "var(--height)",
        borderRadius: "var(--border-radius)",
        background: "#dee2e6",
        transition: "background 0.4s",
        "&:after": {
            content: '""',
            position: "absolute",
            top: "2px",
            left: "2px",
            height: "18px",
            width: "18px",
            background: "#ffffff",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
            borderRadius: "var(--border-radius)",
            transition: "transform 0.4s",
        },
    },
});

const QuestionnaireSchedule = () => {
    const classes = useStyles();

    return (
        <div className="container">
            <h5>Questionnaire Schedule</h5>
            <div className="row">
                <div className="col-lg-12">
                    <table className="w-100 my-4">
                        <tr>
                            <th className={classes.headerTableLeft}>
                                Questionnaire Group
                            </th>
                            <td>:</td>
                            <td>Control Group</td>
                        </tr>
                        <tr>
                            <th>Created By</th>
                            <td>:</td>
                            <td>Matthew W. 2021-12-30 09:05</td>
                        </tr>
                    </table>
                </div>
                <div className="col-lg-12 text-right">
                    <label className={classes.toggle} htmlFor="myToggle">
                    <span className={classes.toggleLabel}>Active</span>
                        <input
                            className={classes.toggleInput}
                            type="checkbox"
                            id="myToggle"
                        />
                        <div className={classes.toggleFill}></div>
                    </label>
                </div>
                <div className="col-lg-12">
                    <table className={`mt-4 ${classes.scheduleTable}`}>
                        <thead>
                            <tr>
                                <th scope="col">QUESTIONNAIRE NAME</th>
                                <th scope="col">QUESTIONNAIRE DESCRIPTION</th>
                                <th scope="col">NEXT SCHEDULE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>face-trauma-control-1</td>
                                <td>
                                    Questionnaire for control & healing of face
                                    trauma caused by alcohol 1
                                </td>
                                <td>
                                    <span className={classes.scheduleCount}>
                                        12
                                    </span>
                                    <div className={classes.selectCount}>
                                        <select className="form-control">
                                            <option>Weeks</option>
                                            <option>Months</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default QuestionnaireSchedule;
