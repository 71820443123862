import { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getAdminMedicalAction } from "../../../redux/actions/medicalCenter.action"


const MedicalCenter = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector((state: RootStateOrAny) => state.auth)
    const medical = useSelector((state: RootStateOrAny) => state.medical)
    const myMedicalList = medical.medical || ""
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                await dispatch(getAdminMedicalAction(auth.token))
                setLoading(false)
            } catch (err: any) {
                setLoading(false)
                // alert(err.message)
            }
        }

        init()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="container-fluid">

            <div className='row p-5'  >
                <div className="col pt-2 bg-white">
                    <div className='row px-3 pb-2'  >
                        <span className="material-icons-outlined mr-2">medical_services</span>
                        <span className="page-title ">Medical Center list</span>
                    </div>
                    <table className="table table-bordered text-left">
                        <thead style={{ backgroundColor: "rgba(215, 215, 250, 1)" }}>
                            <tr style={{ textTransform: 'uppercase' }}>
                                <th scope="col">No</th>
                                <th scope="col">Medical Center Name</th>
                                <th scope="col">Short Name</th>
                                <th scope="col">action</th>

                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
                            {myMedicalList.map((mc: any, index: any) => {
                                return <tr>
                                    <th key={index}>{isLoading ? "Loading..." : index + 1}</th>
                                    <td>{isLoading ? "Loading... " : mc.name || "N/A"}</td>
                                    <td>{isLoading ? "Loading... " : mc.shortName || "N/A"}</td>
                                    <td>
                                        <button type="button" className="btn btn-warning text-white" onClick={() => history.push('/admin/setup/Medical-center/' + mc.id)}>Edit</button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    <div className="d-flex flex-row-reverse bd-highlight">
                        <div className="pb-5 bd-highlight">
                            <button type="button" className="btn btn-success" onClick={() => history.push('/admin/setup/Medical-center')}>Add new</button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
export default MedicalCenter