import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAdminQuestionnaireListAction } from "../../../redux/actions/questionnaire.action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../../redux/actions/auth.action";

const Questionnairelist = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [questionnaireList, setQuestionnaireList] = useState([]);

    const auth = useSelector((state: RootStateOrAny) => state.auth);

    useEffect(() => {
        !auth.token && history.push("/");
        auth.token && !auth.user && dispatch(getUserAction(auth.token));

        if (auth.successLoginParticipant) {
            history.push("/register");
        }
    }, [history, auth.token, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getQuestionnaireList() {
            const res: any = await dispatch(
                getAdminQuestionnaireListAction(auth.token)
            );
            setQuestionnaireList(res.data.items);
        }
        getQuestionnaireList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="container-fluid">
            <div className="row p-5">
                <div className="col pr-5 pt-2 bg-white">
                    <div className="row px-3 pb-2">
                        <span className="material-icons-outlined mr-2">quiz</span>
                        <span className="page-title">Questionnaire list</span>
                    </div>
                    <table className="table table-bordered">
                        <thead
                            style={{ backgroundColor: "rgba(215, 215, 250, 1)" }}
                        >
                            <tr style={{ textTransform:'uppercase' }}>
                                <th scope="col">No</th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Active</th>
                                <th scope="col" className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody
                            style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
                        >
                            {questionnaireList.map(
                                (questionnaire: any, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{questionnaire.name}</td>
                                            <td>{questionnaire.description}</td>
                                            <td>
                                                {questionnaire.isActive
                                                    ? "Active"
                                                    : "Inactive"}
                                            </td>
                                            <td className="text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-info"
                                                    onClick={() =>
                                                        history.push(
                                                            "/admin/questionnaire/" +
                                                            questionnaire.id
                                                        )
                                                    }
                                                >
                                                    Detail
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                    <div className="d-flex flex-row-reverse">
                        <div className="p-2">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() =>
                                    history.push("/admin/questionnaire/create")
                                }
                            >
                                Add New
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Questionnairelist;
