import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    scoreInput: {
        margin: "auto 10px",
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            "-moz-appearance": "none",
            margin: 0,
        },
    },
    scoreControl: {
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
        padding: "6px 6px 6px 6px",
        margin: "auto",
        "& span": {
            fontSize: "16px",
            margin: "0px",
        },
    },
});

const AnswerScore = (props: {
    score: number;
    onChangeScoreAnswer: any;
    increaseScoreAnswer: any;
    decreaseScoreAnswer: any;
}) => {
    const classes = useStyles();

    return (
        <>
            <button
                className={`btn btn-success ${classes.scoreControl}`}
                onClick={props.increaseScoreAnswer}
                title="Increase"
            >
                <span className="material-icons-outlined">add</span>
            </button>
            <input
                type="number"
                className={`form-control ${classes.scoreInput}`}
                value={props.score}
                onChange={props.onChangeScoreAnswer}
                min="0"
            />
            <button
                className={`btn btn-danger ${classes.scoreControl}`}
                onClick={props.decreaseScoreAnswer}
                title="Decrease"
            >
                <span className="material-icons-outlined">remove</span>
            </button>
        </>
    );
};

export default AnswerScore;
