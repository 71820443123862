import IResponse from '../../interfaces/IResponse'
import { postAnswerAPI } from '../api/api.answer'
import IAnswer from "../../interfaces/IAnswer"

export const postAnswerAction = (token: string, data: IAnswer) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await postAnswerAPI(token, data)
           return response
        }catch(error :any) {
            console.error('[questionnaire]: ', error)
            throw error
        }
    }
}