import IResponse from '../../interfaces/IResponse'
import {getReportDemographyAPI, getRiskDemographyAPI, getRiskDemographyDetailAPI} from '../api/api.report'

export const getReportDemographyAction = (token: string,groupname:string) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await getReportDemographyAPI(token,groupname)
           return response
        }catch(error :any) {
            console.error('[questionnaire]: ', error)
            throw error
        }
    }
}

export const getRiskDemographyAction = (token: string,demographic:string) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await getRiskDemographyAPI(token,demographic)
           return response
        }catch(error :any) {
            console.error('[questionnaire]: ', error)
            throw error
        }
    }
}

export const getRiskDemographyDetailAction = (token: string,demographic:string | null,demographicValue:string | null) : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await getRiskDemographyDetailAPI(token,demographic,demographicValue)
           return response
        }catch(error :any) {
            console.error('[questionnaire]: ', error)
            throw error
        }
    }
}