import {  useState,useEffect } from "react";
import { createUseStyles } from "react-jss";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUserAction } from "../../redux/actions/auth.action";
import { getConsentAction, postConsentAction } from "../../redux/actions/consent.action";

const useStyles = createUseStyles({
    container: {
        height: "100vh",
        background: "rgba(0, 0, 0, 0.56)",
    },
    consentText: {
        maxHeight: "55vh",
        overflow: "auto",
        width: "100%",
    },
    consentText1: {
        border: "1px solid #dee2e6",
    },
    consentTable: {
        width: "100%",
        "&, td": {
            border: "1px solid #dee2e6",
            padding: "8px",
        },
    },
});

const Agreement = () => {
    const classes = useStyles();
    const [isSign, setSign] = useState(false)
    const history = useHistory()
    const dispatch=useDispatch()
    const [isLoading,setLoading]=useState(false)
    const auth= useSelector((state:RootStateOrAny)=> state.auth)

    useEffect(() => {
        async function getParticipantDetail() 
            {
            setLoading(true)
            const res : any = await dispatch(getConsentAction(auth.token))
            const data =  res.data
            setLoading(false)
            if(data){
                history.push("/questionnaire")
               
            }
            else{
                history.push("/agreement")
            
            }
        }
        getParticipantDetail()
        
    },[]) // eslint-disable-line  react-hooks/exhaustive-deps

    useEffect(() => {
        !auth.token && history.push('/')
        auth.token && !auth.user && dispatch(getUserAction(auth.token))

        if(auth.successLoginParticipant){
            history.push('/register')
        }
       
    }, [history,auth.token,dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    const onClick=async ()=>{
        try {
            setLoading(true);
            await dispatch(postConsentAction(auth.token));
            setLoading(false);
            history.push("/slideshow");
        } catch (err: any) {
            console.log("[registerUserAction]", err);            
            alert(err?.response?.data?.message);
            setLoading(false);
        }
    }

    return (
        <div className={`container-fluid ${classes.container} `}>
            <div className="row h-100 align-items-center justify-content-center ">
                <div className="col-lg-4 col-sm-6">
                    <div className="card">
                        <div className="card-body pt-5">
                            <h5 className="card-title text-center">
                                Informed Consent Form
                            </h5>
                            <div className="border rounded">
                                <div
                                    className={`card-text p-2 ${classes.consentText} `}
                                >
                                    <h4 className="text-center">
                                        Participant Consent Form
                                    </h4>
                                    <div
                                        className={`p-1 ${classes.consentText1} `}
                                    >
                                        <b className="font-weight-bold">
                                            Interventional Study
                                        </b>{" "}
                                        –{" "}
                                        <i className="font-italic">
                                            Novel Screening and Brief
                                            Intervention for Patients with
                                            Alcohol Related Facial Trauma
                                        </i>
                                    </div>
                                    <br />
                                    <p>
                                        <b className="font-weight-bold">
                                            Principal Investigaor:
                                        </b>{" "}
                                        Kai Hsiang Lee
                                    </p>
                                    <p>
                                        <b className="font-weight-bold">
                                            Associate Investigator:
                                        </b>{" "}
                                        Matthew Woliansky
                                    </p>
                                    <br />
                                    <p className="font-weight-bold">
                                        Locations: Royal Perth and Canberra
                                        Hospitals
                                    </p>
                                    <br />
                                    <h4>What participation involves:</h4>
                                    <p>
                                        Participants invited to take part in
                                        this research project have sustained
                                        alcohol related facial injury. The aim
                                        of this project is to screen for patient
                                        relationship with alcohol at the time of
                                        injury and 3 months following after the
                                        use of a brief mobile phone app-based
                                        intervention.
                                    </p>
                                    <p>
                                        This Consent Form explains about the
                                        research project. It explains the tests
                                        and treatments involved. This is
                                        important in making your decision
                                        whether you wish to participate in the
                                        study. Please read this information and
                                        seek further clarity if required from
                                        any of the practitioners involved or the
                                        research team.
                                    </p>
                                    <p>
                                        Participation in this research is
                                        voluntary. If you don’t wish to take
                                        part, you are not required to. You will
                                        receive the best possible care whether
                                        you take part.
                                    </p>
                                    <p>
                                        If you agree to take part in the
                                        research project, you will be asked to
                                        tick “agree” at the bottom of this
                                        document. By ticking you are telling us
                                        that you:
                                    </p>
                                    <ul>
                                        <li>Understand what you have read</li>
                                        <li>
                                            Consent to take part in the research
                                            project
                                        </li>
                                        <li>
                                            Consent to the anonymous use of your
                                            personal and health information as
                                            described.
                                        </li>
                                    </ul>
                                    <p>
                                        You will be emailed a copy of your
                                        Consent Form.
                                    </p>
                                    <br />
                                    <h5>
                                        2. What is the purpose of this research?
                                    </h5>
                                    <p>
                                        This project aims to investigate brief
                                        intervention and screening in changing
                                        the habits of patients who have
                                        sustained alcohol related facial trauma.
                                        Consenting patients who have sustained
                                        alcohol related facial trauma will
                                        complete a screening questionnaire
                                        exploring demographics and screen for
                                        participants’ alcohol use via use an app
                                        based screening tool. This is based on a
                                        screening questionnaire developed by the
                                        World Health Organisation. Some
                                        participants will then be given
                                        computerized and written advice on risky
                                        alcohol use. All participants will be
                                        given the same questionnaire 3 months
                                        following the initial questionnaire.
                                    </p>
                                    <p>
                                        Previous research has shown that
                                        provision of brief advice to
                                        participants who suffer alcohol related
                                        facial injuries can lead to a change in
                                        drinking pattern but this practice is
                                        not routinely implemented by doctors in
                                        Australia. The study hopes to find a
                                        novel app based approach to providing
                                        this service to patients who have
                                        sustained an alcohol related facial
                                        injury.
                                    </p>
                                    <p>
                                        This research has been initiated by the
                                        study doctor, Associate Professor Kai
                                        Lee (Consultant Oral & Maxillofacial
                                        Surgeon) and Dr Matthew Woliansky
                                        (Dental Surgeon).
                                    </p>
                                    <h5>
                                        3. What does participation in this
                                        research involve?
                                    </h5>
                                    <p>
                                        Patients who consent to be involved have
                                        sustained facial injuries with alcohol
                                        involvement and require treatment from
                                        the Oral and Maxillofacial Surgery.
                                        Participants will complete a screening
                                        questionnaire exploring participants’
                                        background and screen for participants’
                                        alcohol. Some participants will then be
                                        given advice on risky alcohol use while
                                        the non experimental group will only
                                        receive information on their facial
                                        trauma. All participants will be given
                                        the same questionnaire 3 months later.
                                    </p>
                                    <p>
                                        You will be participating in a blind
                                        study. In a blind study you do not know
                                        which of the treatments you are
                                        receiving. Your study doctor will also
                                        not know which treatment you are
                                        receiving.
                                    </p>
                                    <p>
                                        Participation in this project will
                                        involve:
                                    </p>
                                    <ol>
                                        <li>
                                            Completing a mobile app
                                            questionnaire which asks demographic
                                            background including employment and
                                            also includes a screening
                                            questionnaire. This will take
                                            approximately 10 minutes
                                        </li>
                                        <li>
                                            Randomly selected participants will
                                            also app based advice
                                        </li>
                                        <li>
                                            All participants will be asked to
                                            complete same initial and final set
                                            of questionnaire at 3 months.
                                        </li>
                                    </ol>
                                    <h5>
                                        4. Do I have to take part in this
                                        research project?
                                    </h5>
                                    <p>
                                        Participation in any research project is
                                        voluntary. If you do not wish to take
                                        part, you do not have to. If you decide
                                        to take part and later change your mind,
                                        you may withdraw from the project at any
                                        stage.
                                    </p>
                                    <h5>
                                        5. What are the potential benefits of
                                        taking part?
                                    </h5>
                                    <p>
                                        We cannot guarantee that you will
                                        receive any benefits from this research;
                                        however, possible benefits may include
                                        reduction in risk drinking habits which
                                        can reduce risk of traumatic injuries.
                                    </p>
                                    <h5>
                                        6. What are the potential risks of
                                        taking part?
                                    </h5>
                                    <p>
                                        This study does not involve use of
                                        medical treatment and no side effect is
                                        expected from participation in this
                                        study.
                                    </p>
                                    <h5>
                                        7. Can I have take treatment during this
                                        research project?
                                    </h5>
                                    <p>
                                        Participating does not affect your
                                        ability to take any of your required
                                        medications or treatments. It is
                                        important to discuss any concerns
                                        regarding other treatment with the
                                        research team, including
                                        over-the-counter medications, vitamins
                                        or herbal remedies, acupuncture, or
                                        other alternative treatments.
                                    </p>
                                    <h5>
                                        8. What if I withdraw from this research
                                        project?
                                    </h5>
                                    <p>
                                        If you decide to withdraw from the
                                        project, please notify any of the
                                        contacts provided below. This will allow
                                        the research team to discuss any or
                                        special requirements related to
                                        withdrawing.
                                    </p>
                                    <p>
                                        If you do withdraw your consent during
                                        the research project, no further
                                        personal information will be collected
                                        from you, although personal information
                                        already collected will be retained to
                                        ensure that the results of the research
                                        project can be measured properly as
                                        legally required. You should be aware
                                        that data collected by the researcher up
                                        to the time you withdraw will form part
                                        of the research project results. If you
                                        do not want them to do this, you must
                                        tell them before you join the research
                                        project.
                                    </p>
                                    <h5>
                                        9. What happens at the end of the
                                        project?
                                    </h5>
                                    <p>
                                        Results of this study will be submitted
                                        for consideration to be published in a
                                        peer reviewed journal which will be
                                        available to participants. Your
                                        individual participant result will not
                                        be identified.
                                    </p>
                                    <br />
                                    <h4>
                                        Part 2 How is the project being run?
                                    </h4>
                                    <h5>
                                        10. What will happen to my information?
                                    </h5>
                                    <p>
                                        You will complete 2 questionnaires
                                        through the app. The first at the time
                                        of the incident and the second at 3
                                        months. At the 3 month mark individuals
                                        need to be re-identifiable for
                                        contacting but each individual will not
                                        be identifiable (information will be
                                        coded).
                                    </p>
                                    <p>
                                        Information will be stored on a secure
                                        server and will be encrypted and
                                        password protected. Access to this
                                        information will be by the principal
                                        researcher. Our app is used to collect
                                        study data and the information collected
                                        will not include any identifiers.{" "}
                                    </p>
                                    <p>
                                        The information generated by this
                                        project will be deleted at least 12
                                        months from completion of project.
                                        Documentation will be kept for a minimum
                                        of 12 months from completion of the
                                        project. However, it is intended results
                                        from this project will be written up and
                                        submitted for publication in a peer
                                        reviewed journal so that documentation
                                        will then be kept for 5 years post
                                        publication or 5 years from the decision
                                        not to publish.
                                    </p>
                                    <p>
                                        By consenting at the end of this form
                                        are agreeing to the research team
                                        collecting and using personal
                                        information about you for the research
                                        project. All information obtained in
                                        connection with this research project
                                        that can identify you will remain
                                        totally confidential. Information
                                        related to individual will be coded and
                                        will not be individually identifiable.
                                        Your information will only be used for
                                        the purpose of this research project,
                                        and it will only be disclosed with your
                                        permission, except as required by law.
                                    </p>
                                    <p>
                                        Information about you may be obtained
                                        from your health records held at this
                                        health service for the purpose of this
                                        research. By consenting you agree to the
                                        research team accessing health records
                                        if they are relevant to your
                                        participation in this research project.
                                        By agreeing in the consent form, you
                                        authorise release of, or access to, this
                                        confidential information to the relevant
                                        study personnel and regulatory
                                        authorities.{" "}
                                    </p>
                                    <p>
                                        It is anticipated that the results of
                                        this research project will be published
                                        and/or presented. In either modality,
                                        your personal information will not be
                                        identifiable.{" "}
                                    </p>
                                    <h5>11. Complaints and recompense</h5>
                                    <p>
                                        If any injuries or complications occur
                                        as result of the research project you
                                        must contac the research team by the
                                        information provided below to ensure
                                        adequate and appropriate medial
                                        treatment. If eligible for Medicare, you
                                        can receive any medical treatment
                                        required to treat the injury or
                                        complication, free of charge, as a
                                        public patient in any Australian public
                                        hospital.
                                    </p>
                                    <h5>
                                        12. Who is organising and funding the
                                        research?
                                    </h5>
                                    <p>
                                        This research project is being conducted
                                        by Dr Kai Lee and Dr Matthew Woliansky.
                                        Funding is primarily provided by The
                                        Australian and New Zealand Association
                                        of Oral and Maxillofacial Surgeons
                                        (ANZAOMS).
                                    </p>
                                    <h5>
                                        13. Who has reviewed the research
                                        project
                                    </h5>
                                    <p>
                                        The Human Research Ethics Committee
                                        (HREC) have reviewed and provided
                                        approval for this research. The ethical
                                        aspects of this research project have
                                        been approved by the HREC.
                                    </p>
                                    <p>
                                        The ACT Health Human Research Ethics
                                        Committee is constituted according to
                                        the National Statement on Ethical
                                        Conduct in Human Research 2007 and is
                                        certified for single review of multi-
                                        centre clinical trials. ACT Health HREC
                                        operates in compliance with applicable
                                        regulatory requirements and the
                                        principles of the International
                                        Conference on Harmonization Guidelines
                                        on Good Clinical Practice.{" "}
                                    </p>
                                    <h5>
                                        14. Further information and who to
                                        contact
                                    </h5>
                                    <div className="p-2 border mb-4">
                                        <p>
                                            The person you may need to contact
                                            will depend on the nature of your
                                            query.
                                        </p>
                                        <p className="mb-0">
                                            If you want any further information
                                            concerning this project or if you
                                            have any medical problems which may
                                            be related to your involvement in
                                            the project (for example, any side
                                            effects), you can contact the
                                            principal study doctor Dr Kai Lee on
                                            045818881 or any of the following
                                            people:
                                        </p>
                                    </div>
                                    <p>
                                        For matters relating to research at the
                                        site at which you are participating, the
                                        details of the local site complaints
                                        person are:
                                    </p>
                                    <div className="pt-1 pr-4 pl-4 pb-4">
                                        <p>
                                            <b>Complaints contact person</b>
                                        </p>
                                        <table className={classes.consentTable}>
                                            <tr>
                                                <td>Name</td>
                                                <td>
                                                    Associate Professor Kai Lee
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Position</td>
                                                <td>
                                                    Fellow, Oral & Maxillofacial
                                                    Surgeon
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Telephone</td>
                                                <td>0401337540</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>
                                                    Matthew.woliansky@gmail.com
                                                </td>
                                            </tr>
                                        </table>
                                        <br />
                                        <table className={classes.consentTable}>
                                            <tr>
                                                <td>Name</td>
                                                <td>Dr Matthew Woliansky</td>
                                            </tr>
                                            <tr>
                                                <td>Position</td>
                                                <td>Dental Surgeon</td>
                                            </tr>
                                            <tr>
                                                <td>Telephone</td>
                                                <td>0418516150</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>
                                                    Matthew.woliansky@gmail.com
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <label className="checkbox-inline">
                                    <input
                                        type="checkbox"
                                        className="mr-2"
                                        style={{ fontSize: "14px" }}
                                        onChange={(e) => setSign(e.target.checked) }
                                    />
                                    I have signed the informed consent form
                                </label>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-block"
                                style={{
                                    backgroundColor: "#29166F",
                                    color: "#ffffff",
                                }}
                                disabled={!isSign}
                                onClick={onClick}
                            >
                               {isLoading?"Loading ....":"Proceed"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Agreement;