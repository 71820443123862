import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getUserAction } from "../../redux/actions/auth.action";
import { useDispatch } from "react-redux";

const ProcessLogin = () => {
    const query = new URLSearchParams(useLocation().search)
    const token = query.get('token')
    const dispatch = useDispatch();
    const history = useHistory()

    useEffect(()=>{
        const init = async () => {
            try{
                if(token){                
                    await dispatch(getUserAction(token))
                }
                history.push("/agreement");
            }catch(err :any) {
                const message = err?.response?.data?.message
                alert(message)
                history.push('/')
            }
        }
        init()         
        
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div>Loading ...</div>)
}
export default ProcessLogin