import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import Slider from "react-slick";
import { getUserAction } from "../../redux/actions/auth.action";

const useStyles = createUseStyles({
    header: {
        background: "#29166F",
        height: "103px",
        lineHeight: "103px",
        fontSize: "18px",
        textAlign: "center",
        color: "#FCFAFF",
        fontWeight: "600",
    },
    brand: {
        lineHeight: "1.5",
        display: "inline-block",
        verticalAlign: "middle",
    },
    button: {
        background: "#29166F",
        padding: "19px",
        color: "#FFFFFF",
        fontSize: "20px",
        fontWeight: '600',
        borderRadius: "6px",
        bottom: 0

    },
    content: {
        height: '100vh'
    }
});

const contentUrl = [
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/control-group/1.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/control-group/2.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/control-group/3.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/control-group/4.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/control-group/5.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/control-group/7.jpg"
]

const experimental = [
    // "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/experimental-group/1.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/experimental-group/2.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/experimental-group/3.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/experimental-group/4.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/experimental-group/5.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/experimental-group/6.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/experimental-group/7.jpg",
    "https://prod-wesgate-api-assets.s3.ap-southeast-2.amazonaws.com/experimental-group/8.jpg"
]

const SlideshowUser = () => {
    const classes = useStyles();
    const history = useHistory()
    const [isSign, setSign] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [urlGroup, setUrlGroup] = useState<string[]>([])
    const dispatch = useDispatch()
    const auth = useSelector((state: RootStateOrAny) => state.auth)
    const user = auth.user || {}
    const group = user.group || {}

    useEffect(() => {
        !auth.token && history.push('/')
        auth.token && !auth.user && dispatch(getUserAction(auth.token))

        if (auth.successLoginParticipant) {
            history.push('/register')
        }
    }, [history, auth.token, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(getUserAction(auth.token))
    }, []) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLoading(true)
        if (group.name === "Control") {
            let urlContent = contentUrl.map((content) => { return (content) })
            setUrlGroup(urlContent)
        }
        if (group.name === "Experimental") {
            let urlContent = experimental.map((content) => { return (content) })
            setUrlGroup(urlContent)
        }
        setLoading(false)
    }, [group.name]) //eslint-disable-line react-hooks/exhaustive-deps


    const settingsSlider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    };

    return (
        <div className='container-fluid p-0 bg-light'>
            <div className='row m-0'>
                <div className={`col-md-8 mx-auto p-0 bg-white ${classes.content}`}>
                    <nav className={`${classes.header}`}>
                        <p className={`${classes.brand}`}>Slideshow</p>
                    </nav>
                    {isLoading ? "Loading" : <Slider {...settingsSlider}
                    >
                        {urlGroup.map((content, index) => {
                            return (
                                <div key={content} className="text-center">
                                    <img src={content} alt="url" style={{ height: "auto", width: "100%" }} />
                                    <h5>Image {index + 1} of {urlGroup.length}</h5>
                                </div>
                            )
                        })}
                    </Slider>}

                    <div className="d-block d-md-none text-center">
                        <h6>Please swipe left or right to navigate</h6>
                    </div>
                    <div className="mt-5">
                        <label className="checkbox-inline">
                            <input
                                type="checkbox"
                                className="mr-2"
                                style={{ fontSize: "14px" }}
                                onChange={(e) => setSign(e.target.checked)}
                            />
                            I have finished read the slideshow
                        </label>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-block"
                        style={{
                            backgroundColor: "#29166F",
                            color: "#ffffff",
                        }}
                        disabled={!isSign}
                        onClick={() => history.push('/questionnaire')}
                    >
                        Open Questionnaire
                    </button>
                </div>
            </div>
        </div>
    )
}
export default SlideshowUser
