import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../../redux/actions/auth.action";
import {
    getAdminQuestionnaireListDetailAction,
    deleteAdminDeleteQuestionnaireAction,
    deleteAdminDeleteQuestionAction,
    deleteAdminDeleteQuestionClusterAction,
} from "../../../redux/actions/questionnaire.action";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface IParams {
    id: string;
}

interface IData {
    createdAt: string;
    description: string;
    id: string;
    isActive: boolean;
    name: string;
    questionCluster: any;
    schedule: any;
    updatedAt: string;
}

const QuestionnaireListDetail = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    let { id } = useParams<IParams>();
    const [questionnaire, setQuestionnaire] = useState<Partial<IData>>({});
    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const auth = useSelector((state: RootStateOrAny) => state.auth);

    useEffect(() => {
        !auth.token && history.push("/");
        auth.token && !auth.user && dispatch(getUserAction(auth.token));

        if (auth.successLoginParticipant) {
            history.push("/register");
        }
    }, [history, auth.token, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getQuestionnaireListDetail() {
            const res: any = await dispatch(
                getAdminQuestionnaireListDetailAction(auth.token, id)
            );
            setQuestionnaire(res.data);
            setLoading(false);
        }
        getQuestionnaireListDetail();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onDelete = (id: string) => {
        async function deleteQuestionnaire() {
            const res: any = await dispatch(
                deleteAdminDeleteQuestionnaireAction(auth.token, id)
            );
            if (res.code === 200) {
                history.push("/admin/questionnaire");
            } else {
                console.error(res.code);
            }
        }
        deleteQuestionnaire();
    };

    const deleteQuestion = (uid:string, clusterIndex:number,questionIndex:number) => {
        async function deleteQuestion() {
            const res: any = await dispatch(
                deleteAdminDeleteQuestionAction(auth.token, uid)
            );
            if (res.code === 200) {
                const values:any = {...questionnaire};
                values.questionCluster[clusterIndex].questions.splice(questionIndex, 1);
                setQuestionnaire(values);
            } else {
                console.error(res.code);
            }
        }
        deleteQuestion();
    }

    const deleteCluster = (uid:string, clusterIndex:number) => {
        async function deleteCluster() {
            const res: any = await dispatch(
                deleteAdminDeleteQuestionClusterAction(auth.token, uid)
            );

            if (res.code === 200) {
                const values:any = {...questionnaire};
                values.questionCluster.splice(clusterIndex, 1);
                setQuestionnaire(values);
            } else {
                console.error(res.code);
            }
        }
        deleteCluster();
    }

    if (loading) {
        return (
            <div className="container mt-3">
                <p>Loading ...</p>
            </div>
        );
    }
    return (
        <div className="container my-3">
            <h5>{questionnaire.name}</h5>
            <div className="card">
                <h5 className="card-header d-flex">
                    <div className="mr-auto">Cluster</div>
                    <button
                        className="btn btn-success btn-sm mr-3"
                        onClick={() =>
                            history.push(
                                "/admin/question-cluster/create/" + questionnaire.id
                            )
                        }
                    >
                        Add New Cluster
                    </button>
                    <button
                        className="btn btn-success btn-sm"
                        onClick={() =>
                            history.push(
                                "/admin/question/create/" + questionnaire.id
                            )
                        }
                    >
                        Add New Question
                    </button>
                </h5>
                <div className="card-body">
                    {questionnaire.questionCluster.map(
                        (cluster: any, clusterIndex: number) => {
                            return (
                                <Accordion key={clusterIndex}>
                                    <AccordionSummary
                                        expandIcon={
                                            <span className="material-icons-outlined">
                                                expand_more
                                            </span>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <h6>{cluster.name}</h6>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            <b>Description : </b>
                                            {cluster.description}
                                        </p>
                                        <table className="table table-bordered">
                                            <thead
                                                style={{
                                                    backgroundColor:
                                                        "rgba(215, 215, 250, 1)",
                                                }}
                                            >
                                                <tr>
                                                    <th scope="col">No</th>
                                                    <th scope="col">Question</th>
                                                    <th scope="col">Choice</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                style={{
                                                    backgroundColor:
                                                        "rgba(255, 255, 255, 1)",
                                                }}
                                            >
                                                {cluster.questions.map(
                                                    (
                                                        question: any,
                                                        questionIndex: number
                                                    ) => {
                                                        return (
                                                            <tr key={questionIndex}>
                                                                <td>
                                                                    {question.sort}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        question.title
                                                                    }
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding: 0,
                                                                        border: "none",
                                                                    }}
                                                                >
                                                                    <table
                                                                        style={{
                                                                            width: "100%",
                                                                            border: "none",
                                                                        }}
                                                                    >
                                                                        <thead className="thead-light">
                                                                            <tr>
                                                                                <th>
                                                                                    Sort
                                                                                </th>
                                                                                <th>
                                                                                    Name
                                                                                </th>
                                                                                <th>
                                                                                    Code
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {question.questionChoice.map(
                                                                                (
                                                                                    choice: any,
                                                                                    choiceIndex: number
                                                                                ) => {
                                                                                    return (
                                                                                        <tr
                                                                                            key={
                                                                                                choiceIndex
                                                                                            }
                                                                                        >
                                                                                            <td
                                                                                                style={{
                                                                                                    width: "20%",
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    choice.sort
                                                                                                }
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    width: "60%",
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    choice.name
                                                                                                }
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    width: "20%",
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    choice.code
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td>
                                                                    <div style={{display:"flex",justifyContent:"center"}}>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger btn-sm d-flex"
                                                                            title="Delete Question"
                                                                            onClick={()=> deleteQuestion(question.id,clusterIndex,questionIndex)}
                                                                            style={{padding:"5px",alignItems:"center"}}
                                                                        >
                                                                            <span className="material-icons-outlined">delete</span>
                                                                        </button>
                                                                    </div>
                                                                    <div style={{display:"flex",justifyContent:"center",marginTop: "0.75rem"}}>

        
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success btn-sm d-flex"
                                                                        title="Edit Question"
                                                                        onClick={ ()=> history.push("/admin/question/edit/" + id + "/" + question.id)}
                                                                        style={{padding:"5px",alignItems:"center"}}
                                                                        >
                                                                        <span className="material-icons-outlined">edit</span>
                                                                    </button>
                                                                    </div>
                                                                
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                        <div className="text-right">
                                            <button
                                                className="btn btn-warning text-white btn-sm mr-3"
                                                onClick={() =>
                                                    history.push(
                                                        "/admin/question-cluster/edit/" + id + "/" + cluster.id
                                                    )
                                                }
                                            >
                                                Edit Cluster
                                            </button>
                                            <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() => deleteCluster(cluster.id, clusterIndex)}
                                            >
                                                Delete Cluster
                                            </button>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        }
                    )}
                </div>
            </div>
            <div className="card mt-3">
                <h5 className="card-header">Schedule</h5>
                <div className="card-body">
                    <table className="w-100 table table-bordered">
                        <thead
                            style={{
                                backgroundColor: "rgba(215, 215, 250, 1)",
                            }}
                        >
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">Type</th>
                                <th scope="col">Schedule Time</th>
                            </tr>
                        </thead>
                        <tbody
                            style={{
                                backgroundColor: "rgba(255, 255, 255, 1)",
                            }}
                        >
                            {questionnaire.schedule.map(
                                (schedule: any, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{schedule.type}</td>
                                            <td>{schedule.scheduleTime}</td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card mt-3">
                <h5 className="card-header">Setting</h5>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex flex-row-reverse">
                        <button
                            className="btn btn-warning text-white"
                            onClick={() =>
                                history.push(
                                    "/admin/questionnaire/edit/" +
                                        questionnaire.id
                                )
                            }
                        >
                            Edit this questionnaire
                        </button>
                        <div className="w-100">
                            <strong>Edit this questionnaire</strong>
                            <p className="mb-0">
                                Change name or description of this questionnaire
                            </p>
                        </div>
                    </li>
                    <li className="list-group-item d-flex flex-row-reverse">
                        <button
                            className="btn btn-danger"
                            onClick={() => setOpen(true)}
                        >
                            Delete this questionnaire
                        </button>
                        <div className="w-100">
                            <strong>Delete this questionnaire</strong>
                            <p className="mb-0">
                                Once you delete a questionnaire, there is no
                                going back. Please be certain.
                            </p>
                        </div>
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>
                                {"Are you absolutely sure?"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    This action cannot be undone. This will
                                    permanently delete the questionnaire,
                                    cluster, question and remove all
                                    collaborator associations.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button
                                    className="btn btn-success text-white m-3"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-danger m-3"
                                    onClick={() => onDelete(id)}
                                >
                                    Yes
                                </button>
                            </DialogActions>
                        </Dialog>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default QuestionnaireListDetail;
