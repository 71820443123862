import IResponse from '../../interfaces/IResponse'
import { getDetailAdminParticipanAnswerAPI, getParticipantCountAPI, getParticipantListAPI, getReportParticipantAPI, getRiskLevelByDemographyAPI, sendNotificationParticipantAPI } from '../api/api.participant'
import * as types from '../types/participantReport'

export const getParticipanAction = (token: string, status: string, page: number, perPage: number): Promise<IResponse> | any => {
    return async (dispatch: any) => {
        try {
            // debugger
            const response: Promise<IResponse> = await getParticipantListAPI(token, status, page, perPage)
            return response
        } catch (error: any) {
            console.error('[participant list]: ', error)
            throw error
        }
    }
}

export const getParticipanCountAction = (token: string): Promise<IResponse> | any => {
    return async (dispatch: any) => {
        try {
            // debugger
            const response: Promise<IResponse> = await getParticipantCountAPI(token)
            return response
        } catch (error: any) {
            console.error('[participant list]: ', error)
            throw error
        }
    }
}

export const getDetailParticipanAnswerAction = (token: string, participantId: string, questionnaireId: string): Promise<IResponse> | any => {
    return async (dispatch: any) => {
        try {
            // debugger
            const response: Promise<IResponse> = await getDetailAdminParticipanAnswerAPI(token, participantId, questionnaireId)
            return response
        } catch (error: any) {
            console.error('[participant detail list]: ', error)
            throw error
        }
    }
}

export const getParticipanReportAction = (token: string) => {
    return async (dispatch: any) => {
        try {
            // debugger
            dispatch({ type: types.REPORT_LOADING })
            const response = await getReportParticipantAPI(token)
            dispatch({
                type: types.GET_REPORT, payload: {
                    report: response.data.items
                }
            })
            return response
        } catch (error: any) {
            console.error('[participant report list]: ', error)
            throw error
        }
    }
}


export const getRiskLevelByDemographyAction = (token: string, demographic: string, part: string) => {
    return async (dispatch: any) => {
        try {
            // debugger
            dispatch({ type: types.REPORT_LOADING })
            const response = await getRiskLevelByDemographyAPI(token, demographic, part)
            dispatch({
                type: types.GET_RISKLEVEL, payload: {
                    riskLevel: response.data
                }
            })
            return response
        } catch (error: any) {
            console.error('[participant report list]: ', error)
            throw error
        }
    }
}

export const sendNotificationParticipantAction = (token: string, participantId: string, questionnaireId: string, data: object): Promise<IResponse> | any => {
    return async () => {
        try {
            const response: Promise<IResponse> = await sendNotificationParticipantAPI(token, participantId, questionnaireId, data)
            return response
        } catch (error: any) {
            console.error('[questionnaireList]: ', error)
            throw error
        }
    }
}
