import { traceDeprecation } from "process";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDetailParticipanAnswerAction } from "../../../redux/actions/partisipan.action";
import getOrdinal from "../../../util/getOrdinal/getOrdinal";
import { constants } from "buffer";

export type detailParams = {
    participantId: string,
    questionnaireId: string
};


const ListAnswer = () => {
    const auth = useSelector((state: RootStateOrAny) => state.auth)
    const dispatch = useDispatch()
    const { participantId, questionnaireId } = useParams<detailParams>()
    const [myParticipantList, setMyParticipantList] = useState<any>([])
    const [myAnswerList, setMyAnswerList] = useState<any>([])
    const myQuestionCluster = myAnswerList?.questionCluster || []
    const participantSchedule = myParticipantList.participantSchedule || []


    async function getParticipantDetail() {
        const res: any = await dispatch(getDetailParticipanAnswerAction(auth.token, participantId, questionnaireId))
        const data = res.data
        setMyAnswerList(data?.items)
        setMyParticipantList(data.participant)
    }

    useEffect(() => {
        getParticipantDetail()
    }, [])

    return (
        <>
            {
                myParticipantList ?
                    <div>
                        {myQuestionCluster.map((cluster: any, indexCluster: any) => (
                            <table className="table table-bordered">
                                <thead style={{ backgroundColor: "rgba(215, 215, 250, 1)" }}>
                                    <tr>
                                        <th scope="col">No</th>
                                        <th scope="col">{cluster.name.toUpperCase()}</th>                                        
                                        <th scope="col">
                                            1st Response
                                        </th>
                                        <th scope="col">Value</th>
                                        <th scope="col">
                                            2nd Response
                                        </th>
                                        <th scope="col">Value</th>
                                    </tr>

                                </thead>
                                <tbody style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
                                    {cluster.questions.map((quest: any, index: number) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{quest.title}</td>
                                            {
                                                (() => {
                                                    const answerLabel: any = []
                                                    let filtered = quest.answer.filter((t: any) => t.revisitionNumber == 1);

                                                    (filtered.length > 0) ? filtered.map((t1: any) => {
                                                        answerLabel.push(
                                                            (() => {
                                                                let answerTemp = quest.questionChoice.filter((choice: any) => choice.code == t1.answer)
                                                                return (
                                                                    <>
                                                                        <td>{answerTemp[0].name}</td>
                                                                        <td>{answerTemp[0].code}</td>
                                                                    </>
                                                                )
                                                            })()
                                                        )
                                                    }) : answerLabel.push(
                                                        <>
                                                            <td></td>
                                                            <td></td>
                                                        </>
                                                    )

                                                    filtered = quest.answer.filter((t: any) => t.revisitionNumber == 2);
                                                    (filtered.length > 0) ? filtered.map((t1: any) => {
                                                        answerLabel.push(
                                                            (() => {
                                                                let answerTemp = quest.questionChoice.filter((choice: any) => choice.code == t1.answer)
                                                                return (
                                                                    <>
                                                                        <td>{answerTemp[0].name}</td>
                                                                        <td>{answerTemp[0].code}</td>
                                                                    </>
                                                                )
                                                            })()
                                                        )
                                                    }) : answerLabel.push(
                                                        <>
                                                            <td></td>
                                                            <td></td>
                                                        </>
                                                    )

                                                    return answerLabel
                                                })()
                                            }

                                        </tr>
                                    ))}

                                    <tr style={{ fontWeight: 'bold' }}>
                                        <td></td>
                                        <td></td>
                                        <td>Score</td>
                                        <td>{cluster.totalScore.q1}</td>
                                        <td></td>
                                        <td>{cluster.totalScore.q2}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ))}
                    </div>
                    :
                    <div style={{ color: "red" }}>Participant not responding</div>
            }
        </>
    );
};

export default ListAnswer;

