import { useState, useEffect } from "react";
import Question from "./Question";
import { useParams, useHistory } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../../../redux/actions/auth.action";
import { getAdminQuestionnaireListDetailAction, getAdminQuestionDetailAction, updateAdminEditQuestionAction } from "../../../../redux/actions/questionnaire.action";

interface IParams {
    idQuestionnaire: string;
    idQuestion: string;
}

interface IQuestion {
    title: string;
    type: string;
    sort: number;
    questionClusterId:string;
    questionChoice: {
        sort: number;
        name: string;
        code: number;
    }[];
}

interface ICluster {
    createdAt: string;
    description: string;
    id: string;
    name: string;
    questionerId: string;
    questions: [];
    sort: number;
    updatedAt: string;
}

const UpdateQuestion = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    let { idQuestionnaire, idQuestion } = useParams<IParams>();
    const [clusters, setClusters] = useState<ICluster[]>([]);
    const [cluster, setCluster] = useState("");
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState<IQuestion[]>([]);

    const auth = useSelector((state: RootStateOrAny) => state.auth);

    useEffect(() => {
        !auth.token && history.push("/");
        auth.token && !auth.user && dispatch(getUserAction(auth.token));

        if (auth.successLoginParticipant) {
            history.push("/register");
        }
    }, [history, auth.token, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getQuestionnaireListDetail() {
            const res: any = await dispatch(
                getAdminQuestionnaireListDetailAction(auth.token, idQuestionnaire)
            );
            setClusters(res.data.questionCluster);
            setLoading(false);
        }
        async function getQuestionDetail() {
            const res: any = await dispatch(
                getAdminQuestionDetailAction(auth.token, idQuestion)
            );
            delete res.data.createdAt
            delete res.data.updatedAt
            delete res.data.id
            const values = [...questions,res.data];
            setQuestions(values);
            setCluster(res.data.questionClusterId)
            setLoading(false);
        }
        getQuestionnaireListDetail();
        getQuestionDetail()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const changeNoQuestion = (index: number, event: any) => {
        console.log("changeNoQuestion")
        const values = [...questions];
        values[index].sort = Number(event.target.value);
        setQuestions(values);
    };

    const changeQuestion = (index: number, event: any) => {
        console.log("changeQuestion")
        const values = [...questions];
        values[index].title = event.target.value;
        setQuestions(values);
    };

    const changeTypeQuestion = (index: number, event: any) => {
        console.log("changeTypeQuestion")
        const values = [...questions];
        values[index].type = event.target.value;
        setQuestions(values);
    };

    const removeQuestion = (index: number) => {
        // No need action here
    };

    const addAnswer = (indexQuestion: number) => {
        console.log("addAnswer")
        const values: any[] = [...questions];
        values[indexQuestion].questionChoice.push({
            sort: 0,
            name: "",
            code: 0,
        });
        setQuestions(values);
    };

    const removeAnswer = (indexQuestion: number, indexAnswer: number) => {
        console.log("removeAnswer")

        const values = [...questions];
        values[indexQuestion].questionChoice.splice(indexAnswer, 1);
        setQuestions(values);
    };

    const changeNoAnswer = (
        indexQuestion: number,
        indexAnswer: number,
        event: any
    ) => {
        console.log("changeNoAnswer")

        const values = [...questions];
        values[indexQuestion].questionChoice[indexAnswer].sort = Number(
            event.target.value
        );
        setQuestions(values);
    };

    const changeAnswer = (
        indexQuestion: number,
        indexAnswer: number,
        event: any
    ) => {
        console.log("changeAnswer")
        const values = [...questions];
        values[indexQuestion].questionChoice[indexAnswer].name =
            event.target.value;
        setQuestions(values);
    };

    const changeScoreAnswer = (
        indexQuestion: number,
        indexAnswer: number,
        event: any
    ) => {
        console.log("changeScoreAnswer")
        const values = [...questions];
        values[indexQuestion].questionChoice[indexAnswer].code =
            event.target.value;
        setQuestions(values);
    };

    const increaseScoreAnswer = (
        indexQuestion: number,
        indexAnswer: number,
        event: any
    ) => {
        console.log("increaseScoreAnswer")
        const values = [...questions];
        values[indexQuestion].questionChoice[indexAnswer].code =
            Number(values[indexQuestion].questionChoice[indexAnswer].code) + 1;
        setQuestions(values);
    };

    const decreaseScoreAnswer = (
        indexQuestion: number,
        indexAnswer: number,
        event: any
    ) => {
        console.log("decreaseScoreAnswer")

        const values = [...questions];
        if (values[indexQuestion].questionChoice[indexAnswer].code - 1 >= 0) {
            values[indexQuestion].questionChoice[indexAnswer].code =
                Number(values[indexQuestion].questionChoice[indexAnswer].code) - 1;
        } else {
            values[indexQuestion].questionChoice[indexAnswer].code = 0;
        }
        setQuestions(values);
    };

    const submitHandler = () => {
        questions.forEach((question: IQuestion) => {
            question.questionClusterId = cluster;
            async function getQuestionnaireListDetail() {
                await dispatch(
                    updateAdminEditQuestionAction(auth.token, idQuestion, question)
                );
            }
            getQuestionnaireListDetail();

        })
        
        history.push("/admin/questionnaire/" + idQuestionnaire)
    };

    if (loading) {
        return (
            <div className="container mt-3">
                <p>Loading ...</p>
            </div>
        );
    }
    return (
        <div className="container my-3">
            <h4>Edit Question</h4>
            <div className="card">
                <h6 className="card-header">Select Cluster</h6>
                <div className="card-body">
                    <div className="form-group">
                        <select
                            className="form-control"
                            value={cluster}
                            onChange={(event: any) => setCluster(event.target.value)}
                        >
                            {clusters.map((cluster: any, index: number) => (
                                <option key={cluster.id} value={cluster.id}>
                                    {cluster.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <hr style={{ borderColor: "#E9E9E9" }} />

            <div style={{ display: "flex" }}>
                <h4 style={{ marginRight: "auto" }}>Questions</h4>
            </div>
            <div>
                {questions.map((question, index) => (
                    <Question
                        key={index}
                        index={index}
                        question={question}
                        onChangeNoQuestion={(event: any) =>
                            changeNoQuestion(index, event)
                        }
                        onChangeQuestion={(event: any) =>
                            changeQuestion(index, event)
                        }
                        onChangeTypeQuestion={(event: any) =>
                            changeTypeQuestion(index, event)
                        }
                        showRemoveQuestion={false}
                        removeQuestion={() => removeQuestion(index)}
                        addAnswer={() => addAnswer(index)}
                        onChangeNoAnswer={changeNoAnswer}
                        onChangeAnswer={changeAnswer}
                        onChangeScoreAnswer={changeScoreAnswer}
                        increaseScoreAnswer={increaseScoreAnswer}
                        decreaseScoreAnswer={decreaseScoreAnswer}
                        removeAnswer={removeAnswer}
                    />
                ))}
            </div>
            <div className="text-right">
                <button
                    type="button"
                    onClick={submitHandler}
                    className="btn btn-success"
                    disabled={questions.length > 0 ? false : true}
                >
                    Save Question
                </button>
            </div>
        </div>
    );
};

export default UpdateQuestion;
