import { API } from "./api.middleware"

export const getMedicalAPI = async()=>{
    return await API({
        url:'/medical-centre',
         method:'GET'
    }).then(res=>res.data)
}

export const detailMedicalAPI = async(id:string)=>{
    return await API({
        url:'/medical-centre/'+id,
        method:'GET'
    }).then(res=>res.data)
}

export const getMedicalAdminAPI = async(token:string)=>{
    return await API({
        url:'/admin/medical-centre',
         method:'GET',
         headers: { Authorization: "Bearer " + token },
    }).then(res=>res.data)
}

export const detailMedicalAdminAPI = async( token:string,id:string)=>{
    return await API({
        url:'/admin/medical-centre/'+id,
        method:'GET',
        headers: { Authorization: "Bearer " + token },
    }).then(res=>res.data)
}

export const postMedicalAdminAPI=async(token:string, data: object | FormData)=>{
    return await API({
        url:'/admin/medical-centre',
        method:'POST',
        data,
        headers:{Authorization: "Bearer " + token}
    })
}

export const updateMedicalAdminAPI = async(id:string, token:string, data: object | FormData)=>{
    return await API({
        url:'/admin/medical-centre/'+id,
        method:'PUT',
        data,
        headers: { Authorization: "Bearer " + token },
    }).then(res=>res.data)
}

export const deleteMedicalAdminAPI = async(id:string, token:string)=>{
    return await API({
        url:'/admin/medical-centre/'+id,
        method:'DELETE',
        headers: { Authorization: "Bearer " + token },
    }).then(res=>res.data)
}