import { API } from "./api.middleware"
import IAnswer from "../../interfaces/IAnswer"

export const postAnswerAPI = async (token: string, data:IAnswer) => {
    return await API({
        url : '/user/answer' ,
        method : 'POST',
        headers: { Authorization: "Bearer " + token },
        data
    }).then( res => res.data )
}
