import React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../../redux/actions/auth.action";
import { getReportDemographyAction } from "../../../redux/actions/report.action";
import { MdDownload } from "react-icons/md";
import axios from "axios";

const ReportDemography = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [download, setDownload] = useState(false)
    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const [form, setForm] = useState({
        demographic: "control"
    });


    async function getReportDemography(e:any) {
        const res: any = await dispatch(
            getReportDemographyAction(auth.token,e)
        );
        setData(res.data);
    }

    async function downloadApi() {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/report-demography?groupname=${form.demographic}&download=true`,
                {
                    responseType: "blob",
                    headers: { Authorization: "Bearer " + auth.token },
                }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "report-demography.csv");
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0);
            setDownload(false);
        } catch (e) {
            alert("Download failed")
        }
    }

    useEffect(() => {
        !auth.token && history.push("/");
        auth.token && !auth.user && dispatch(getUserAction(auth.token));

        if (auth.successLoginParticipant) {
            history.push("/register");
        }
        getReportDemography(form.demographic);

        if (download) {
            downloadApi();
        }
    }, [history, auth.token, dispatch, download]); // eslint-disable-line react-hooks/exhaustive-deps

    // Variable for conditional render
    let temporaryName: string = "";

    const onChangeForm = (
        e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        //e.preventDefault()
        const { name, value } = e.currentTarget;
        setForm({
            ...form,
            [name]: value,
        });        
        getReportDemography(value)
    };

    console.log(form)


    const renderSearch = () => {
        return (
            <form className="input-group col-md-4 col-sm-6 mt-3"
            >
                <span className="mt-1 mr-3" style={{ fontSize: '1rem' }}>Filter</span>
                <select
                    name="demographic"
                    onChange={onChangeForm}
                    className="form-control"
                    id="status"           
                >                    
                    <option value="control">Control</option>
                    <option value="experimental">Experimental</option>
                </select>
            </form>
        )
    }

    return (
        <div className="container-fluid">
            <div className='row p-5'  >
                <div className="col pr-5 pt-2 bg-white">
                    <div className="row px-3 pb-2 d-flex" style={{ justifyContent: 'space-between' }}>
                        <div className="mt-3">
                            <span className="material-icons-outlined mr-2">analytics</span>
                            <span className="page-title ">Participant's Attitude Report</span>
                        </div>
                        {renderSearch()}

                    </div>
                    <table className="table table-bordered">
                        <thead style={{ backgroundColor: "rgba(215, 215, 250, 1)" }}>
                            <tr style={{ textTransform: 'uppercase' }}>
                                <th scope="col"></th>
                                <th scope="col" colSpan={2}>Initial</th>
                                <th scope="col" colSpan={2}>Follow Up</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
                            {data.map((data: any, index: number) => {
                                if (data.name !== temporaryName) {
                                    temporaryName = data.name;
                                    return (
                                        <React.Fragment key={index}>
                                            <tr
                                                style={{
                                                    background: "#e9ecef",
                                                    fontWeight: "700",
                                                    color: "#495057",
                                                }}
                                            >
                                                <td style={{ textTransform: 'uppercase' }} colSpan={5}>{data.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="pl-4">{data.title}</td>
                                                <td>
                                                    {data.initial.yes}/{data.initial.count}
                                                </td>
                                                <td>
                                                    {data.initial.percent_yes.toFixed(2)}%
                                                </td>
                                                <td>
                                                    {data.followup.yes}/{data.followup.count}
                                                </td>
                                                <td>
                                                    {data.followup.percent_yes.toFixed(2)}%
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                } else {
                                    return (
                                        <tr key={index}>
                                            <td className="pl-4">{data.title}</td>
                                            <td>
                                                {data.initial.yes}/{data.initial.count}
                                            </td>
                                            <td>
                                                {data.initial.percent_yes.toFixed(2)}%
                                            </td>
                                            <td>
                                                {data.followup.yes}/{data.followup.count}
                                            </td>
                                            <td>
                                                {data.followup.percent_yes.toFixed(2)}%
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </table>
                    <div className="row px-3 pb-5 d-flex">
                        {/* <SwitchButton hideEmail={hideEmail} onSwitch={()=>setHideEmail(!hideEmail)} wordingSwitch="hide risk demographic detail" /> */}
                        <div className="col pt-3 d-flex" style={{ justifyContent: 'end' }}>
                            <button className="btn btn-outline-primary" type="button" onClick={() => setDownload(true)}>
                                <MdDownload /> Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className='row p-5'  >
            </div>
        </div>

    );
};

export default ReportDemography;
