import IResponse from '../../interfaces/IResponse'
import { regInfoAPI } from '../api/api.regInfo'

export const getRegInfoAction = () : Promise<IResponse> | any =>{
    return async () => { 
        try {
           const response : Promise<IResponse> = await regInfoAPI()
           return response
        }catch(err :any) {
            console.error('[list occupation]: ', err)
            throw err
        }
        
    }
}