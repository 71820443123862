import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDetailMedicalAction, clearMedicalDetail } from "../../../redux/actions/medicalCenter.action";
import { nanoid } from "nanoid";
import { uploadS3 } from "../../../redux/api/api.upload";
import {updateMedicalAdminAPI, postMedicalAdminAPI, deleteMedicalAdminAPI} from '../../../redux/api/api.medical';

type medical = {
    name: string,
    shortname: string,
    logo: any
}

type params = {
    id: string
}

const SetupMedicalCenter=()=>{
    const {id} = useParams<params>();
    const history=useHistory();
    const dispatch = useDispatch();
    const {auth} = useSelector((state: RootStateOrAny) => state);
    const {detailMedical} = useSelector((state:RootStateOrAny)=> state.medical);
    const [medicalForm, setMedicalForm] = useState<medical>({
        name: "",
        shortname: "",
        logo: ""
    });
    const [status, setStatus] = useState({
        loading: false,
        editable: false
    })
    const [image, setImage] = useState<string>("");
    const [medicalErr, setMedicalErr] = useState({
        name: "",
        shortname: "",
        logo: ""
    });
    

    useEffect(() => {
        if(!id) {
            setStatus(prev => ({...prev, editable: true}))
        }

        const init = async () => {
            try{
                setStatus(prev => ({...prev, loading: true}))
                await dispatch(getDetailMedicalAction(id));
                setStatus(prev => ({...prev, loading: false}))
            }catch(err: any){
                // alert(err.message)
            }
        }

        if(id) {
            init()
        }

        return () => {
            dispatch(clearMedicalDetail());
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        setMedicalForm({
            name: detailMedical?.name,
            shortname: detailMedical?.shortName,
            logo: ""
        })
        setImage(detailMedical?.logo)
    }, [detailMedical]);

    const onPickImage = (e: any) => {
        const file = e.target.files[0];
        setImage(URL.createObjectURL(file));
        inputChange('logo', file);
    }

    const inputChange = (key: "name" | "shortname" | "logo", value: string) => {
        setMedicalErr(prev => ({...prev, [key]: ""}));
        setMedicalForm(prev => ({...prev, [key]: value}));
    }

    const onSubmit = async () => {
        setStatus(prev => ({...prev, loading: true}))
        let err = false;
        let setErr = {
            name: "",
            shortname: "",
            logo: ""
        };
        if(medicalForm.name === "") {
            err = true;
            setErr.name = "Medical Center Name can't be empty"
        }
        if(medicalForm.shortname === "") {
            err = true;
            setErr.shortname = "Shortname Medical can't be empty"
        }
        if(medicalForm.logo === "" && image === "") {
            err = true;
            setErr.logo = "Logo must be uploaded"
        }
        setMedicalErr(setErr)
        if(err) return;
        
        const init = async (logo? : string) => {
            let params:any = {
                name: medicalForm.name,
                shortName: medicalForm.shortname
            }
            let resId = null;
            if(logo) params['logo'] = logo;
            try {
                if(id) {
                    await updateMedicalAdminAPI(id, auth.token, params);
                } else {
                    const res = await postMedicalAdminAPI(auth.token, params);
                    resId = res.data.data.id
                }

                setStatus(prev => ({...prev, loading: false}))
                alert('Success saving data');
                if(resId) {
                    history.push('/admin/setup/Medical-center/' + resId);
                } else {
                    history.go(0);

                }
            } catch (error) {
                alert("There is something wrong when inserting data")
            }
            
        }
        const {logo} = medicalForm;
        if(logo !== "") {
            try {
                const ext = logo.name.split('.').pop();
                const namefile = `${nanoid()}.${ext}`;

                await uploadS3(logo, namefile, auth.token).then((url: any) => {
                    init(url);
                })
            } catch (error) {
                alert("There is something wrong when upload the logo");
            }
        } else {
            init()
        }
    }

    const onDelete = () => {
        if(window.confirm('Are you sure want to delete this Data?')) {
            deleteMedicalAdminAPI(id, auth.token).then(() => {
                alert('Success deleting Data');
                history.push('/admin/MedicalCenter');
                
            })
        }
    }
    
    
    return(
        <>
        <div className="container-fluid">
            <form className="p-4 p-lg-5 ">
                <h4>Setup medical center Name</h4>
                <div className="form-group mt-3">
                    <label htmlFor="medicalCenter">Medical Center Name</label>
                    <input type="text" className="form-control" id="medicalCenter" placeholder={status.loading ? "Loading..." : `Medical Center`} value={medicalForm.name} onChange={({currentTarget}) => inputChange('name', currentTarget.value)} disabled={!status.editable || status.loading}/>
                    {medicalErr.name !== "" && (
                        <span style={{color: "red"}}>{medicalErr.name}</span>
                    )}
                </div>
                <div className="form-group mt-3">
                    <label htmlFor="shortname">Shortname Medical Center</label>
                    <input type="text" className="form-control" id="shortname" placeholder={status.loading ? "Loading..." : `Shortname Medical Center`} value={medicalForm.shortname} onChange={({currentTarget}) => inputChange('shortname', currentTarget.value)} disabled={!status.editable || status.loading} />
                    {medicalErr.shortname !== "" && (
                        <span style={{color: "red"}}>{medicalErr.shortname}</span>
                    )}
                </div>
                <img src={image} alt="Logo" />
                <div className="form-group">
                    <label htmlFor="slideshow">Upload Logo</label>
                    <div className="input-group">
                        <div className="custom-file">
                            <label className="custom-file-label" htmlFor="UploadLogo">
                                <input type="file" className="custom-file-input bg-light" id="UploadLogo" aria-describedby="UploadLogo" onChange={onPickImage} disabled={!status.editable || status.loading}/>
                                {medicalErr.logo !== "" && (
                                    <span style={{color: "red"}}>{medicalErr.logo}</span>
                                )}
                            </label>
                        </div>
                    </div>
                </div>
                {status.editable ? (
                    <div className='row d-flex align-items-center justify-content-center flex-column my-6'  >
                        <button type="button" className='btn btn-primary' style={{backgroundColor: 'rgba(41, 22, 111, 1)'
        ,color:"white"}} onClick={onSubmit} disabled={status.loading}>
                            {status.loading ? "Loading..." : "Save"} 
                        </button>
                    </div>
                ) : (
                    <div className='row d-flex align-items-center justify-content-end flex-row my-6'  >
                            <button type="button" className='btn btn-warning mr-3' onClick={() => setStatus(prev => ({...prev, editable: true}))}>
                                Edit 
                            </button>
                            <button type="button" className='btn btn-danger' onClick={onDelete}>
                                Delete 
                            </button>
                    </div>
                )}
            </form>
        </div>
        
        </>
    )
}

export default SetupMedicalCenter
