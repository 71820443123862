import IAction from '../../interfaces/IAction'
import * as types from '../types/participantReport'

const initialState = {
    report: {},
    riskLevel: {},
    isLoading: false,
    errMessage: null
}

const reducer = (state = initialState, action: IAction = { type: '', payload: {} }) => {
    switch (action.type) {
        case types.GET_REPORT: return {
            ...state,
            report: action.payload.report,
            isLoading: false,
            errMessage: null
        }
        case types.GET_RISKLEVEL: return {
            ...state,
            riskLevel: action.payload.riskLevel,
            isLoading: false,
            errMessage: null
        }
        case types.REPORT_LOADING: return {
            ...state,
            isLoading: true,
            errMessage: null
        }
        case types.REPORT_FAILED: return {
            ...state,
            isLoading: false,
            errMessage: action.payload.errMessage
        }
        default: return state
    }
}

export default reducer