import { API } from "./api.middleware"

export const getReportDemographyAPI = async (token: string,groupname:string) => {
    return await API({
        url : `/admin/report-demography?groupname=${groupname}` ,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const getRiskDemographyAPI = async (token: string,demographic:string) => {
    return await API({
        url : `/admin/risk-demographic?demographic=${demographic}` ,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const getRiskDemographyDetailAPI = async (token: string,demographic:string | null,demographicValue:string | null) => {
    return await API({
        url : `/admin/risk-demographic?demographic=${demographic}&value=${demographicValue}` ,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}