import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import LoginPage from './component/Login/LoginPage';
import RegisterPage from "./component/RegisterUser/RegisterPage";
import Agreement from "./component/RegisterUser/Agreement";
import Homepage from "./component/homepage/Homepage";
import SlideshowUser from "./component/SlideShow/SlideshowUser";
import HomePageAdmin from "./component/admin/HomepageAdmin";
import Questionnaire from "./component/Questionnaire/Questionnaire";
import QuestionnaireResult from "./component/Questionnaire/QuestionnaireResult";
import GroupPage from "./component/admin/Setup/Group/GroupPage";
import LoginUser from "./component/homepage/LoginUser";
import ProcessLogin from "./component/Login/ProcessLogin";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/register" component={RegisterPage}/>
        <Route path="/slideshow" component={SlideshowUser} />
        <Route path="/questionnaire" component={Questionnaire}/>
        <Route path="/questionnaire-result" component={QuestionnaireResult}/>
        <Route path="/agreement" component={Agreement} />
        <Route path="/admin" component={HomePageAdmin} />
        <Route path='/setup-group' component={GroupPage}/>
        <Route path='/login' component={ProcessLogin}/>
        <Route path="/:id/login" component={LoginPage} />
        <Route exact path="/:id" component={LoginUser}/>
      </Switch>
    </Router>
  );
}

export default App;
