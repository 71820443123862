import ILogin from '../../interfaces/ILogin'
import IUser, { ILoginUser } from '../../interfaces/IUser'
import { GetUserAPI, loginAPI, loginUserAPI, RefreshTokenAPI, registerAPI } from '../api/api.auth'
import * as types from '../types/auth.type'

export const registerUserAction = (payload: IUser) => {
    return async (dispatch: any) => {
        try {
            const response = await registerAPI(payload)
            dispatch({ type: types.LOGIN_SUCCESS, payload: { token: response?.data?.access_token } })
            dispatch({
                type: types.SET_USER,
                payload: {
                    user: response?.data?.participant
                }
            })
        } catch (err) {
            console.error('[register]', err)
            throw err
        }
    }
}

export const loginAction = (payload: ILogin) => {
    return async (dispatch: any) => {
        try {
            const response = await loginAPI(payload)
            dispatch({
                type: types.LOGIN_SUCCESS,
                payload: {
                    token: response.data && response.data.access_token,
                    refreshToken:response.data && response.data.refresh_token
                }
            })
        } catch (err) {
            console.error('[loginAction]', err)
            throw err
        }
    }
}

export const logoutAction = () => (dispatch: any) => {
    return dispatch({ type: types.LOGOUT })
}

export const loginUserAction = (payload: ILoginUser) => {
    return async (dispatch: any) => {
        try {
            dispatch({ type: types.ON_LOADING })
            const response = await loginUserAPI(payload)
            dispatch({
                type: types.LOGIN_SUCCESS,
                payload: {
                    token: response.data && response.data.access_token,
                    user: response.data && response.data.user
                }
            })
            
        } catch (err: any) {
            console.error('[login user]', err)
            throw err
        }
    }
}

export const getUserAction = (token: string) => {
    return async (dispatch: any) => {
        try {
            const response = await GetUserAPI(token)
            dispatch({ type: types.LOGIN_SUCCESS, payload: { token } })
            dispatch({
                type: types.SET_USER,
                payload: {
                    user: response.data
                }
            })
            return response
        } catch (err) {
            throw err
        }
    }
}

export const refreshTokenAction = (token:string) => {
    return async (dispatch: any) => {
        try {
            const response = await RefreshTokenAPI(token)
            dispatch({
                type: types.REFRESH_TOKEN,
                payload: {
                    token: response.data && response.data.access_token
                }
            })
        } catch (err) {
            console.error('[loginAction]', err)
            throw err
        }
    }
}