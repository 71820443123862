import axios from "axios"
import { useEffect, useState } from "react"
import { MdDownload } from "react-icons/md"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { getParticipanCountAction } from "../../../redux/actions/partisipan.action"
import { LevelPresentationAetiologyBar, LevelPresentationAgeBar, LevelPresentationFractureSiteBar, LevelPresentationGenderBar, LevelPresentationOccupationBar, LevelPresentationPresentationBar } from "./BarChart"
import { PieChartAetiology, PieChartAge, PieChartFracture, PieChartGender, PieChartNumberPresentation, PieChartOccupation } from "./PieChart"
import { AetiologyTable, AgeTable, FractureTable, GenderTable, NumberPresentationTable, OccupationTable } from "./TableList"

const WelcomeScreen = () => {
    const [download, setDownload] = useState(false);
    const [isHomepageContent, setHomepageContent] = useState('age');
    const [isLevelContent, setLevelContent] = useState('ageRange');
    const [count, setCount] = useState<any>([])
    const auth = useSelector((state: RootStateOrAny) => state.auth)
    const dispatch = useDispatch()
    const [selectedValue, setSelectedValue] = useState('q1'); // Initialize with the default value

    const handleRadioChange = (event: any) => {
        setSelectedValue(event.target.value);
    };


    const token = auth.token || ""

    const handleChange = (e: any) => { setHomepageContent(e.target.value) }
    const handleChangeLevel = (e: any) => {
        const newLevelContent = e.target.value;
        setLevelContent(newLevelContent);

        if (newLevelContent === "occupation") {
            // Modify the DOM element
            const myElement = document.getElementById("levelPresentation");
            if (myElement) {
                myElement.removeAttribute("id"); // Remove the "id" attribute
                myElement.setAttribute("id", "occupationLevel"); // Set the "id" attribute to "occupationLevel"
            }
        } else {
            // Revert the changes
            const myElement = document.getElementById("occupationLevel");
            if (myElement) {
                myElement.removeAttribute("id"); // Remove the "id" attribute
                myElement.setAttribute("id", "levelPresentation"); // Set the "id" attribute back to "levelPresentation"
            }
        }
    }


    const homepageContent = [
        { id: 0, value: 'age', text: 'Ages', table: <AgeTable />, chart: <PieChartAge /> },
        { id: 1, value: 'gender', text: 'Gender', table: <GenderTable />, chart: <PieChartGender /> },
        { id: 2, value: 'occupation', text: 'Occupation', table: <OccupationTable />, chart: <PieChartOccupation /> },
        { id: 0, value: 'Aetiology', text: 'Aetiology', table: <AetiologyTable />, chart: <PieChartAetiology /> },
        { id: 1, value: 'Fracture', text: 'Fracture', table: <FractureTable />, chart: <PieChartFracture /> },
        { id: 2, value: 'NumberPresentation', text: 'Number Presentation', table: <NumberPresentationTable />, chart: <PieChartNumberPresentation /> },
    ];

    const levelPresentationContent = [
        { id: 0, value: 'ageRange', text: 'Ages', table: <AgeTable />, chart: <LevelPresentationAgeBar demographic={isLevelContent} questionaireCount={selectedValue} /> },
        { id: 1, value: 'gender', text: 'Gender', table: <GenderTable />, chart: <LevelPresentationGenderBar demographic={isLevelContent} questionaireCount={selectedValue} /> },
        { id: 2, value: 'occupation', text: 'Occupation', table: <OccupationTable />, chart: <LevelPresentationOccupationBar demographic={isLevelContent} questionaireCount={selectedValue} /> },
        { id: 3, value: 'aetiology', text: 'Aetiology', table: <AetiologyTable />, chart: <LevelPresentationAetiologyBar demographic={isLevelContent} questionaireCount={selectedValue} /> },
        { id: 4, value: 'fractureSite', text: 'Fracture', table: <FractureTable />, chart: <LevelPresentationFractureSiteBar questionaireCount={selectedValue} demographic={isLevelContent} />, },
        { id: 5, value: 'presentationEd', text: 'Number Presentation', table: <NumberPresentationTable />, chart: <LevelPresentationPresentationBar questionaireCount={selectedValue} demographic={isLevelContent} /> },
    ];

    const fetchParticipantCount = async () => {
        const res: any = await dispatch(getParticipanCountAction(auth.token))
        const data = res
        setCount(data)
    };

    async function downloadApi() {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/participant-report?download=true`,
                {
                    responseType: "blob",
                    headers: { Authorization: "Bearer " + token },
                }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "participant-demografi.csv");
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0);
            setDownload(false);
        } catch (e) {
            alert("Download failed")
        }
    }

    useEffect(() => {
        fetchParticipantCount();
        if (download) {
            downloadApi();
        }

    }, [download]); // eslint-disable-line  react-hooks/exhaustive-deps
    return (
        <>
            <div className="container-fluid">
                <div className="row p-5 d-flex" style={{ justifyContent: "space-between" }}>
                    <div className="col pr-5">
                        <div className="row date">
                            <div className="col-md-6">
                                <div className="containerTitleHomepage">

                                    <img src="/assets/img/patient.png" alt="" height={"105px"} />
                                </div>
                                <p className="boxTextHomepage">Total Registered Patient</p>
                            </div>
                            <div className="col-md-4 ml-4 containerNumber">
                                <p>{count.totalPatient}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col pr-5">
                        <div className="row date">
                            <div className="col-md-6">
                                <div className="containerTitleHomepage">
                                    Q1
                                </div>
                                <p className="boxTextHomepage">Completed Questioner 1</p>
                            </div>
                            <div className="col-md-4 ml-4 containerNumber">
                                <p>{count.q1Complete}</p>
                            </div>

                        </div>
                    </div>
                    <div className="col" >
                        <div className="row date">
                            <div className="col-md-6">
                                <div className="containerTitleHomepage">
                                    Q2
                                </div>
                                <p className="boxTextHomepage">Completed Questioner 2</p>
                            </div>
                            <div className="col-md-4 ml-4 containerNumber">
                                <p>{count.q2Complete}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* chart */}
                <div className="row px-5">
                    <div className="col shadow">
                        <div className="border-bottom" >
                            <select className="selectedButton mb-4" id="levelPresentation" value={isLevelContent} onChange={handleChangeLevel}>
                                {levelPresentationContent.map(item => {
                                    return (<><option key={item.value} value={item.value}>{item.text}</option></>);
                                })}
                            </select>
                            <div className="custom-control custom-radio custom-control-inline mr-3 ml-3">
                                <input
                                    type="radio"
                                    id="partQ1"
                                    name="RadioPart"
                                    className="custom-control-input"
                                    value="q1"
                                    checked={selectedValue === 'q1'} // Check if the value matches the selectedValue
                                    onChange={handleRadioChange} // Call the onChange handler
                                />
                                <label className="custom-control-label" htmlFor="partQ1">
                                    Q1
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    id="partQ2"
                                    name="RadioPart"
                                    className="custom-control-input"
                                    value="q2"
                                    checked={selectedValue === 'q2'} // Check if the value matches the selectedValue
                                    onChange={handleRadioChange} // Call the onChange handler
                                />
                                <label className="custom-control-label" htmlFor="partQ2">
                                    Q2
                                </label>
                            </div>
                        </div>

                        {levelPresentationContent.filter(content => content.value === isLevelContent).map(filteredContent => (
                            <div className="row px-5 text-center pb-4">
                                {filteredContent.chart}
                            </div>
                        ))}


                    </div>
                </div>
                <div className="row px-5 pt-5" >
                    <div className="col shadow">
                        <div className="row">
                            <div className="col bg-white pt-3 pb-3">
                                <div className="border-bottom">
                                    <select className="selectedButton mb-4" value={isHomepageContent} onChange={handleChange}>
                                        {homepageContent.map(item => {
                                            return (<>
                                                <option key={item.value} value={item.value}>{item.text}</option>

                                            </>);
                                        })}
                                    </select>
                                </div>

                                <div>
                                    {homepageContent.filter(content => content.value === isHomepageContent).map(filteredContent => (
                                        <div className="row">
                                            <div className="col">
                                                <div className="table-responsive">
                                                    {filteredContent.table}
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                {filteredContent.chart}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row px-5 pt-5 pb-5" >
                    <div className="col p-0">
                        <div className="d-flex" style={{ justifyContent: 'end' }}>
                            <button className="btn btn-outline-primary" type="button" onClick={() => setDownload(true)}>
                                <MdDownload /> Download Demografi Report
                            </button>
                        </div>

                    </div>

                </div>
                <div className="row px-5 pt-5 pb-5" >
                    <div className="col p-0">
                        {/* dummy section for keeping bottom visible */}
                    </div>
                </div>
            </div>

        </>
    )
}
export default WelcomeScreen