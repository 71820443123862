import { Switch } from "@mui/material";
import { useState } from "react";
import { MdDownload } from "react-icons/md"

interface buttonProps {
   onClickButton:any;
   wordingButton:string;
  }

interface switchProps{
  hideEmail:boolean;
  onSwitch:any;
  wordingSwitch:string
}

const ButtonDownload=(props:buttonProps)=>{
    return(
      <div className="col pt-3 border-top d-flex" style={{ justifyContent: 'end' }}>
                <button className="btn btn-outline-primary" type="button" onClick={props.onClickButton}>
                    <MdDownload /> {props.wordingButton}
                </button>
          </div>
     
    )
}

export const SwitchButton=(props:switchProps)=>{
 return(
  <div className="col-md-10 pt-3 border-top d-flex" style={{ justifyContent: 'end' }}>
  <div className="d-flex align-items-center">{props.wordingSwitch}</div>
  <div><Switch checked={props.hideEmail} onChange={props.onSwitch} /></div>
  
</div>
 )
}

export const ButtonDetail=(props:buttonProps)=>{
  return <div className='row' >
  <div className='col-12' >
      <button
          type="button"
          className="btn btn-info"
          onClick={props.onClickButton}
      >
          {props.wordingButton}
      </button>
  </div>
</div>
}

export default ButtonDownload