import { API } from "./api.middleware"

export const postConsentAPI = async (token: string) => {
    return await API({
        url : '/user/consent' ,
        method : 'POST',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}

export const getConsentAPI = async (token: string) => {
    return await API({
        url : '/user/consent' ,
        method : 'GET',
        headers: { Authorization: "Bearer " + token },
    }).then( res => res.data )
}