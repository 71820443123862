import { createUseStyles } from 'react-jss'
import React, { PropsWithChildren, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { logoutAction } from '../../../redux/actions/auth.action'

const useStyles = createUseStyles({
    menu: {
        fontSize: '16px'
    },
    bgBrown: {
        background: '#efefef !important'
    },
    flex1: {
        flex: 1
    },
    nav: {
        height: 'calc(100vh - 56px)',
        maxWidth: '250px',
        // Width: '250px',
        // position: 'fixed'
    }
})
interface MenuNavProps {
    pathCurrent: any
}

const MenuNav: React.FC<PropsWithChildren<MenuNavProps>> = () => {
    const { location: { pathname } } = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector((state: RootStateOrAny) => state.auth)
    const renderActiveMenu = (pathCurrent: string) => pathCurrent === pathname ? `active cursor ${classes.bgBrown} ` : ''

    useEffect(() => {
        !auth.token && history.push('/')
        // auth.token && !auth.user && dispatch(getUserAction(auth.token))

        if (auth.successLoginParticipant) {
            history.push('/register')
        }
    }, [history, auth.token, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    const logout = () => {
        dispatch(logoutAction())
        history.push('/')
    }
    return (
        <>
            <nav className={`nav flex-column  p-3 bg-white ${classes.nav}`}>
                <Link className={`nav-link d-flex ${classes.menu} ${renderActiveMenu('/admin')}`} to='/admin' style={{ backgroundColor: "white", color: "#444" }}>
                    <span className="material-icons-outlined mr-2">dashboard</span> Dashboard
                </Link>
                <Link className={`nav-link d-flex ${classes.menu} ${renderActiveMenu('/admin/MedicalCenter')}`} to='/admin/MedicalCenter' style={{ backgroundColor: "white", color: "black" }}>
                    <span className="material-icons-outlined mr-2">medical_services</span>Medical Center
                </Link>
                <Link className={`nav-link d-flex ${classes.menu} ${renderActiveMenu('/admin/questionnaire')} text-dark `} to='/admin/questionnaire' style={{ backgroundColor: "white", color: "black" }}>
                    <span className="material-icons-outlined mr-2">quiz</span> Questionnaire
                </Link>
                <Link className={`nav-link d-flex ${classes.menu} ${renderActiveMenu('/admin/participant')} text-dark `} to='/admin/participant' style={{ backgroundColor: "white", color: "black" }}>
                    <span className="material-icons-outlined mr-2">question_answer</span>Response
                </Link>
                <Link className={`nav-link d-flex ${classes.menu} ${renderActiveMenu('/admin/risk-demography')} text-dark `} to='/admin/risk-demography' style={{ backgroundColor: "white", color: "black" }}>
                    <img src='/assets/img/population.png' className="mr-2"/> Risk by Demography
                </Link>
                <Link className={`nav-link d-flex ${classes.menu} ${renderActiveMenu('/admin/report')} text-dark `} to='/admin/report' style={{ backgroundColor: "white", color: "black" }}>
                    <span className="material-icons-outlined mr-2">analytics</span> Attitude Report
                </Link>
                <Link className={`nav-link d-flex ${classes.menu} ${renderActiveMenu('/admin/Logout')} text-dark `} to='/admin/Logout' style={{ backgroundColor: "white", color: "black" }} onClick={logout}>
                    <span className="material-icons-outlined mr-2">logout</span>Logout
                </Link>
                <div className={`flex-grow ${classes.flex1}`}></div>
                <div className={`text-center`}>
                    <small>&copy; Copyright 2022, Westgate Surgical.</small>
                </div>
            </nav>
        </>
    )

}
export default MenuNav