import { Suspense, useEffect, useState } from 'react'
import { Switch, Route, useHistory } from "react-router-dom";
import HeaderNav from './Navbar/HeaderNav';
import MenuNav from './Navbar/MenuNav';
import Main from './Main';
import Questionnairelist from './questionnaire/questionnaireList';
import QuestionnaireListDetail from './questionnaire/QuestionnaireListDetail'
import CreateQuestionnaire from './questionnaire/CreateQuestionnaire';
import UpdateQuestionnaire from './questionnaire/UpdateQuestionnaire';
import CreateQuestionCluster from './questionnaire/CreateQuestionCluster';
import UpdateQuestionCluster from './questionnaire/UpdateQuestionCluster';
import CreateQuestion from './questionnaire/Question/CreateQuestion';
import UpdateQuestion from './questionnaire/Question/UpdateQuestion';
import WelcomeScreen from './WelcomeScreenAdmin/Welcome';
import QuestionnaireSchedule from './questionnaire/QuestionnaireSchedule';
import ParticipantList from './Participant/ParticipantList';
import { createUseStyles } from 'react-jss';
import MedicalCenter from './MedicalCenter/MedicalCenterList';
import SetupMedicalCenter from './MedicalCenter/SetupMedicalCenter';
import SlideshowUploadPage from './slideshowUpload/SlideShowUploadPage';
import ParticipanDetail from './ParticipantDetail/ParticipantPage';
import ReportDemography from './Report/ReportDemography';
import RiskDemographyList from './RiskDemography/RiskDemography';
import RiskDemographyDetail from './RiskDemography/RiskDemographyDetail';

const useStyles = createUseStyles({
  navbarFull: {
    display: 'none !important',
  },
  navbarStandard: {
    position: 'fixed',
    zIndex: '99',
    margin: 0,
    padding: 0,
    height: '100vh',
    width: '250px'
  },
  footer: {
    marginTop: "20px",
    marginBottom: "30px",
    padding: "10px",
    backgroundColor: '#F5F5F5'
  },
  homePage: {
    overflow: 'auto',
    right: '0px',
    position: 'fixed',
    width: 'calc(100vh - left)',
    background: '#F5F5F5',
    height: '100vh'
    //height: 'calc(100vh - 100px)',
    // alignContent:'end',
    // alignItems: 'end'
  },
  homePageNoSideNav: {
    overflow: 'auto',
    height: 'calc(100vh)',
    paddingLeft: 0
  }
})

const HomePageAdmin = () => {
  const classes = useStyles();
  const history = useHistory()
  const [isShowMenu, toggleMenu] = useState(false)
  
  useEffect(() => {
    toggleMenu(false)
  }, [history.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='container-fluid bg-light p-0' style={{ overflowX: 'hidden' }} >
      <HeaderNav toggleMenu={() => toggleMenu(!isShowMenu)} isTogle={isShowMenu} />
      {isShowMenu ?


        <div className="row m-0">
          <div className={`col ${isShowMenu ? classes.navbarFull : ''} `}>
            <div className="nav flex-column nav-pills h-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <MenuNav pathCurrent='' />
            </div>
          </div>
          <div className={`col ${classes.homePageNoSideNav} `}>
            <Main>
              <Suspense fallback={<div className='d-flex justify-content-center w-100 mt-5' >Loading ...</div>}>
                <Switch>
                  <Route exact path="/admin" component={WelcomeScreen} />
                  <Route path="/admin/MedicalCenter" component={MedicalCenter} />
                  <Route path="/admin/setup/Medical-center/:id" component={SetupMedicalCenter} />
                  <Route path="/admin/setup/Medical-center" component={SetupMedicalCenter} />
                  <Route path="/admin/slideshow-upload" component={SlideshowUploadPage} />
                  <Route path='/admin/questionnaire' component={Questionnairelist} exact />
                  <Route path='/admin/questionnaire/create' component={CreateQuestionnaire} />
                  <Route path='/admin/questionnaire/edit/:id' component={UpdateQuestionnaire} />
                  <Route path='/admin/questionnaire/:id' component={QuestionnaireListDetail} />
                  <Route path='/admin/question-cluster/create/:id' component={CreateQuestionCluster} />
                  <Route path='/admin/question-cluster/edit/:idQuestionnaire/:idCluster' component={UpdateQuestionCluster} />
                  <Route path='/admin/question/create/:id' component={CreateQuestion} />
                  <Route path='/admin/question/edit/:idQuestionnaire/:idQuestion' component={UpdateQuestion} />
                  <Route path='/admin/participant' component={ParticipantList} />
                  <Route path='/admin/participant-detail/:participantId/:questionnaireId' component={ParticipanDetail} />
                  <Route path='/admin/scheduler' component={QuestionnaireSchedule} />
                  <Route path='/admin/report' component={ReportDemography} />
                  <Route path='/admin/risk-demography' component={RiskDemographyList} />
                    <Route path='/admin/risk-demography-detail/' component={RiskDemographyDetail} />
                </Switch>
              </Suspense>
            </Main>

          </div>
        </div>


        :


        // show side nav
        <>

          <div className="row m-0">
            <div className={`col ${classes.navbarStandard} `}>
              <div className="nav flex-column nav-pills h-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <MenuNav pathCurrent='' />
              </div>
            </div>
            <div className={`col-md-10 ${classes.homePage} `}>
              <Main>
                <Suspense fallback={<div className='d-flex justify-content-center mt-5' >Loading ...</div>}>
                  <Switch>
                    <Route exact path="/admin" component={WelcomeScreen} />
                    <Route path="/admin/MedicalCenter" component={MedicalCenter} />
                    <Route path="/admin/setup/Medical-center/:id" component={SetupMedicalCenter} />
                    <Route path="/admin/setup/Medical-center" component={SetupMedicalCenter} />
                    <Route path="/admin/slideshow-upload" component={SlideshowUploadPage} />
                    <Route path='/admin/questionnaire' component={Questionnairelist} exact />
                    <Route path='/admin/questionnaire/create' component={CreateQuestionnaire} />
                    <Route path='/admin/questionnaire/edit/:id' component={UpdateQuestionnaire} />
                    <Route path='/admin/questionnaire/:id' component={QuestionnaireListDetail} />
                    <Route path='/admin/question-cluster/create/:id' component={CreateQuestionCluster} />
                    <Route path='/admin/question-cluster/edit/:idQuestionnaire/:idCluster' component={UpdateQuestionCluster} />
                    <Route path='/admin/question/create/:id' component={CreateQuestion} />
                    <Route path='/admin/question/edit/:idQuestionnaire/:idQuestion' component={UpdateQuestion} />
                    <Route path='/admin/participant' component={ParticipantList} />
                    <Route path='/admin/participant-detail/:participantId/:questionnaireId' component={ParticipanDetail} />
                    <Route path='/admin/scheduler' component={QuestionnaireSchedule} />
                    <Route path='/admin/report' component={ReportDemography} />
                    <Route path='/admin/risk-demography' component={RiskDemographyList} />
                    <Route path='/admin/risk-demography-detail/' component={RiskDemographyDetail} />
                  </Switch>
                </Suspense>
              </Main>
              
            </div>
          </div>
        </>


      }
    </div>
  )
}

export default HomePageAdmin