// // useAdminToken.js
import { useState, useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { refreshTokenAction } from '../../redux/actions/auth.action';

export const RefreshToken = () => {
  const [token, setToken] = useState("");
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const history = useHistory()

  const fetchToken = async () => {
    const req = await fetch(process.env.REACT_APP_API_URL+'/auth/admin/refresh', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${auth.refreshToken}`,
              'Content-Type': 'application/json'
            },
    });
    if (!req.ok) {
      history.push("/");
      console.error("Failed to Fetch access token");
      return;
    }

    const data = await req.json();
    console.log(data.data.access_token);
    setToken(data.data.access_token);
  };


  useEffect(() => {
    fetchToken();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return { token};
};


// useGetRefreshToken.tsx
export const useGetRefreshToken = (auth: any, setLoading: any) => {
  const dispatch = useDispatch();
  const history=useHistory()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(refreshTokenAction(auth.refreshToken));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        history.push("/");
      }
    };

    fetchData();
  }, [auth, setLoading, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useGetRefreshToken;
