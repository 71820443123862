import IResponse from '../../interfaces/IResponse'
import { getConsentAPI, postConsentAPI } from '../api/api.consent'
import * as types from '../types/auth.type'

export const postConsentAction = (token: string) : Promise<IResponse> | any =>{
    return async (dispatch:any) => { 
        try {
            debugger
           const response : Promise<IResponse> = await postConsentAPI(token)
           dispatch({ type: types.LOGIN_SUCCESS, payload: {token}})
           return response
        }catch(error :any) {
            console.error('[consent]: ', error)
            throw error
        }
    }
}

export const getConsentAction = (token: string) : Promise<IResponse> | any =>{
    return async (dispatch:any) => { 
        try {
            debugger
           const response : Promise<IResponse> = await getConsentAPI(token)
           return response
        }catch(error :any) {
            console.error('[consent]: ', error)
            throw error
        }
    }
}