import AnswerScore from "./AnswerScore";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    scoreContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    delete: {
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
        padding: "8px 8px 8px 8px",
        margin: "auto auto auto 1rem",
        "& span": {
            fontSize: "16px",
            margin: "0px",
        },
    },
});

const Answer = (props: {
    answer: any;
    onChangeNoAnswer: any;
    onChangeAnswer: any;
    onChangeScoreAnswer: any;
    increaseScoreAnswer: any;
    decreaseScoreAnswer: any;
    removeAnswer: any;
}) => {
    const classes = useStyles();

    return (
        <>
            <hr className="d-lg-none d-xl-none" style={{ borderColor: "rgba(0, 0, 0, 0.125)",width: "100%", margin: "0px 15px 1rem"}} />
            <div className="col-lg-1 form-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="No."
                    onChange={props.onChangeNoAnswer}
                    value={props.answer.sort}
                />
            </div>
            <div className="col-lg-8 form-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Type answer..."
                    onChange={props.onChangeAnswer}
                    value={props.answer.name || ""}
                />
            </div>
            <div className={`col-lg-3 form-group ${classes.scoreContainer}`}>
                <AnswerScore
                    score={props.answer.code}
                    onChangeScoreAnswer={props.onChangeScoreAnswer}
                    increaseScoreAnswer={props.increaseScoreAnswer}
                    decreaseScoreAnswer={props.decreaseScoreAnswer}
                />
                <button
                    type="button"
                    className={`btn btn-danger ${classes.delete}`}
                    onClick={props.removeAnswer}
                    title="Delete Answer"
                >
                    <span className="material-icons-outlined">delete</span>
                </button>
            </div>
        </>
    );
};

export default Answer;
