import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import {
    getQuestionnaireAction,
    postUserStatusQuestionnaireAction,
} from "../../redux/actions/questionnaire.action";
import { postAnswerAction } from "../../redux/actions/answer.action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../redux/actions/auth.action";

const useStyles = createUseStyles({
    header: {
        background: "#29166F",
        height: "103px",
        lineHeight: "103px",
        fontSize: "18px",
        textAlign: "center",
        color: "#FCFAFF",
        fontWeight: "600",
    },
    brand: {
        lineHeight: "1.5",
        display: "inline-block",
        verticalAlign: "middle",
    },
    question: {
        fontSize: "22px",
        fontWeight: "500",
        color: "#181717",
        padding: "25px 5px",
    },
    button: {
        background: "#29166F",
        padding: "19px",
        color: "#FFFFFF",
        fontSize: "20px",
        fontWeight: "600",
        borderRadius: "6px",
    },
    buttonBox: {},
    answer: {
        minHeight: "66px",
        borderRadius: "6px",
        marginBottom: "30px",
        fontSize: "16px",
        color: "#828282",
        display: "block",
        position: "relative",
        "& > label": {
            marginBottom: "0px",
        },
    },
    answerLabel: {
        background: "#F2F2F2",
        minHeight: "66px",
        padding: "18px 64px 18px 24px",
        borderRadius: "6px",
        cursor: "pointer",
        width: "100%",
        fontWeight: "400",
    },
    answerInput: {
        position: "absolute",
        top: "calc(50% - 15px)",
        right: "39px",
        "-webkit-appearance": "none",
        appearance: "none",
        background: "transparent",
        width: "30px",
        height: "30px",
        border: "2px solid #828282",
        borderRadius: "50%",
        cursor: "pointer",
        "&:checked": {
            border: "2px solid #FFFFFF",
            boxShadow: "0px 0px 0px 4px inset #29166F",
            background: "white",
        },
        "&:checked + label": {
            background: "#29166F",
            color: "#FFFFFF",
            fontWeight: "500",
        },
    },
    "@media (max-width: 991px)": {
        answerInput: {
            top: "calc(50% - 10px)",
            height: "20px",
            width: "20px",
        },
        buttonBox: {
            display: "none",
        },
    },
});

const Questionnaire = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [questionData,setQuestionData]=useState([]);
    const [data, setData] = useState([]);
    const [answer, setAnswer] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const auth = useSelector((state: RootStateOrAny) => state.auth);

    useEffect(() => {
        async function getQuestionnaire() {
            try {
                const res: any = await dispatch(
                    getQuestionnaireAction(auth.token)
                );
                const listed = makeListQuestion(res.data);
                const listedOptional=makeFilterOptionsQuestionnaire(res.data);
                setData(listedOptional)
                setQuestionData(listed);
            } catch (err: any) {
                history.push("/questionnaire-result");
            }
        }
        getQuestionnaire();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        !auth.token && history.push("/");
        auth.token && !auth.user && dispatch(getUserAction(auth.token));

        if (auth.successLoginParticipant) {
            history.push("/register");
        }
    }, [history, auth.token, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const postAnswer = async (question: string, answerCode: string) => {
        return await dispatch(
            postAnswerAction(auth.token, {
                participantQuestionId: question,
                answer: answerCode,
            })
        );
    };

    const postStatusQuestionnaire = async () => {
        return await dispatch(postUserStatusQuestionnaireAction(auth.token));
    };

    const onClickNext = (question: string, answerCode: string) => {
        if (answerCode !== "") {
            const response = postAnswer(question, answerCode);
            if (page === questionData.length) {
                postStatusQuestionnaire();
                history.push("/questionnaire-result");
            }
            else if(page === (questionData.length - data.length)){
                postStatusQuestionnaire();
            }
            setPage(page + 1);
            setAnswer("");
            setIsDisabled(true);
            console.log(response);
        } else if (answerCode === "") {
            console.log("Please choose your answer");
        }
    };

    const onClickAnswer = (questionId: string, answerCode: string) => {
        if (window.innerWidth > 991) {
            // desktop
            setAnswer(answerCode);
            setIsDisabled(false);
        } else {
            // mobile
            setTimeout(() => {
                onClickNext(questionId, answerCode);
            }, 1000);
        }
    };

    const makeListQuestion = (data: any) => {
        if (!data) return null;
        const questions = data.items.map((data: any) => {
            const cluster = data.questionCluster.map((cluster: any) => {
                return cluster.questions;
            });
            let lists = cluster.reduce(
                (list: any, item: any) => [...list, ...item],
                []
            );
            return lists;
        });
        return questions[0];
    };

    const makeFilterOptionsQuestionnaire=(data:any)=>{
        debugger
        if (!data) return null;
        const questions=data.items.map((data:any)=>{
            const cluster = data.questionCluster.filter((filterName:any)=>filterName.name === "OPTIONAL").map((cluster:any)=>{
                return cluster.questions;
            })
            let lists = cluster.reduce(
                (list: any, item: any) => [...list, ...item],
                []
            );
            return lists;
        });
        // const questions = data.items.map((data: any) => {
        //     const cluster = data.questionCluster.map((cluster: any) => {
        //         return cluster.questions;
        //     });
            // let lists = cluster.reduce(
            //     (list: any, item: any) => [...list, ...item],
            //     []
            // );
            // return lists;
        // });
        return questions[0];
    }

    return (
        <div className="container-fluid bg-light">
            <div className="row justify-content-center">
                <div className="col-md-8 p-0">
                    <nav className={classes.header}>
                        <p className={classes.brand}>
                            Question {page}/{questionData.length}
                        </p>
                    </nav>
                    <div className="px-4 bg-white pb-4">
                        {questionData
                            .filter(
                                (q: any, noPage: any) => noPage + 1 === page
                            )
                            .map((question: any, key: any) => (
                                <React.Fragment key={question.id}>
                                    <div className={classes.question}>
                                        {page}. {question.title}
                                    </div>
                                    <div className="row">
                                        {question.questionChoice.map(
                                            (option: any, keyOpt: any) => (
                                                <div
                                                    key={keyOpt}
                                                    className={`col-lg-6 ${classes.answer}`}
                                                >
                                                    <input
                                                        className={
                                                            classes.answerInput
                                                        }
                                                        type="radio"
                                                        name={question.id}
                                                        id={`radio${keyOpt}`}
                                                        value={option.code}
                                                        onClick={() => {
                                                            onClickAnswer(
                                                                question.id,
                                                                option.code
                                                            );
                                                        }}                                    
                                                    />
                                                    <label
                                                        htmlFor={`radio${keyOpt}`}
                                                        className={
                                                            classes.answerLabel
                                                        }
                                                    >
                                                        {option.name}
                                                    </label>
                                                </div>
                                            )
                                        )}
                                        <div
                                            className={`col-12 ${classes.buttonBox}`}
                                        >
                                            <button
                                                type="submit"
                                                className={`btn btn-block ${classes.button}`}
                                                onClick={() => {
                                                    onClickNext(
                                                        question.id,
                                                        answer
                                                    );
                                                }}
                                                disabled={isDisabled}
                                            >
                                                {page === questionData.length
                                                    ? "Finish"
                                                    : "Next"}
                                            </button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Questionnaire;
