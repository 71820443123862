import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getRiskLevelByDemographyAction } from '../../redux/actions/partisipan.action';

export const useRiskLevelInitialization = (auth:{token:string}, demographic:any, questionaireCount:string, setLoading:any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        await dispatch(getRiskLevelByDemographyAction(auth.token, demographic, questionaireCount));
        setLoading(false);
      } catch (err:any) {
        setLoading(false);
        // alert(err.message)
      }
    };

    init();
  }, [auth, demographic, questionaireCount, setLoading, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useRiskLevelInitialization;
