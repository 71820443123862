import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { refreshTokenAction } from "../../redux/actions/auth.action";
import { getParticipanReportAction } from "../../redux/actions/partisipan.action";
import { RefreshToken } from "./RefreshTokenHooks";

const useGetParticipantReport = (auth:any, setLoading:any) => {
    const dispatch = useDispatch();
    const { token } = RefreshToken();
    const history=useHistory()
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          await dispatch(getParticipanReportAction(auth.token));
          setLoading(false);
        } catch (err:any) {
          setLoading(false);
          if (err.response?.data?.message === "jwt expired") {
            await dispatch(refreshTokenAction(auth.refreshToken))
            await dispatch(getParticipanReportAction(token));
            console.log(err);
          }
          else
          history.push("/");
          // alert(err.message)
        }
      };
  
      fetchData();
  
    }, [token]); // eslint-disable-line  react-hooks/exhaustive-deps
};

export default useGetParticipantReport