import ILogin from '../../interfaces/ILogin'
import IUser, { ILoginUser } from '../../interfaces/IUser'
import { API } from './api.middleware'

export const registerAPI = async (data: IUser) => {
    return await API({
        url : '/auth/user/register' ,
        method : 'POST',
        data
    }).then( res => res.data )
}

export const loginAPI = async (data:ILogin) => {
    return await API({
        url : '/auth/admin/login' ,
        method : 'POST',
        data
    }).then( res => res.data )
}

export const loginUserAPI=async(data:ILoginUser)=>{
    return await API({
        url:'/auth/user/login',
        method:'POST',
        data
    }).then(res=>res.data)
}

export const GetUserAPI=async(token:string)=>{
    return await API({
        url:'/auth/user/me',        
        headers:{
            Authorization:'Bearer ' + token
        }
    }).then(res=>res.data)
}

export const RefreshTokenAPI=async(token:string)=>{
    return await API({
        url:'/auth/admin/refresh',  
        method:'POST',      
        headers:{
            Authorization:'Bearer ' + token
        }
    }).then(res=>res.data)
}