import { useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { loginAction } from "../../redux/actions/auth.action"

const LoginForm = () => {
    const history = useHistory()
    const [form, setForm] = useState({ email: '', password: '' })
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false)

    const onChangeForm = (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.currentTarget
        setForm({
            ...form,
            [name]: value,
        })
    }

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            await dispatch(loginAction(form))
            setLoading(false)
            history.push('/admin')
        } catch (err: any) {
            const message = err?.response?.data?.message
            alert(message)
            setLoading(false)
        }
    }

    return (
        <form className="p-4 p-lg-5 border rounded" style={{ background: '#ffffff9e' }} onSubmit={onSubmit} >
            <h4 className="text-center mb-4">Admin login</h4>
            <div style={{ borderTop: '1px solid rgba(0, 0, 0, 0.24)' }}></div>
            <div className="form-group mt-4">
                <label htmlFor="Username">Username</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text bg-white" id="basic-addon1">
                            <span className="material-icons-outlined">account_circle</span>
                        </span>
                    </div>
                    <input type="text" className="form-control" value={form.email} name="email" onChange={onChangeForm} id="username" aria-describedby="username" placeholder="Username" />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text bg-white" id="basic-addon1">
                            <span className="material-icons-outlined">lock</span>
                        </span>
                    </div>
                    <input type="password" className="form-control" value={form.password} name="password" onChange={onChangeForm} id="password" placeholder="Password" />
                </div>
            </div>
            <div className="form-group mt-5">
                <button type="submit" className="btn btn-primary btn-block" style={{
                    backgroundColor: ' rgba(41, 22, 111, 1)'
                    , color: "white"
                }} disabled={isLoading} >
                    {isLoading ? 'Login ..' : 'Login'}
                </button>
            </div>
        </form>
    )
}

export default LoginForm