import { useState } from "react"
import { RootStateOrAny, useSelector } from "react-redux"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ILevelPresentation from "../../../interfaces/ILevelPresentation";
import useGetParticipantReport from "../../../util/CustomHooks/UseGetParticipantReport"
import useRiskLevelInitialization from "../../../util/CustomHooks/useGetRiskLevel";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const LevelPresentationGenderBar = (props: ILevelPresentation) => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.riskLevel || {}
  const low = myReport.low || "N/A"
  const moderate = myReport.medium || "N/A"
  const high = myReport.high || ""
  const [isLoading, setLoading] = useState(false)
  const level_val = [low.female, moderate.female, high.female]
  const level_percentage = [low.male, moderate.male, high.male]
  const level_nottosay = [low.nottosay, moderate.nottosay, high.nottosay]

  // Use the custom hook
  useRiskLevelInitialization(auth, props.demographic, props.questionaireCount, setLoading);
  
  const data = {
    labels: Object.keys(myReport),
    datasets: [
      {
        label: "female",
        data: level_val.map(lp => lp),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: "male",
        data: level_percentage.map(lp => Math.floor(lp)),
        borderColor: 'rgb(54,162,235)',
        backgroundColor: 'rgb(54, 162, 235, 0.5)',
      },
      {
        label: "not to say",
        data: level_nottosay.map(lp => Math.floor(lp)),
        borderColor: 'rgb(54,162,235)',
        backgroundColor: 'rgb(23, 40, 235, 0.5)',
      },
    ]
  };

  const optionsLevel = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          font: {
            size: 16
          }
        }
      },
      title: {
        display: true,
        text: 'Risk Level Presentation',
        font: {
          size: 16,
        },
      },
      tooltip: {
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 16,
        },
      },
    },
  };


  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <Bar options={optionsLevel} data={data} />

      }

    </>
  )
}

export const LevelPresentationOccupationBar = (props: ILevelPresentation) => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.riskLevel || {}
  const low = myReport.low || "N/A"
  const moderate = myReport.medium || "N/A"
  const high = myReport.high || ""
  const [isLoading, setLoading] = useState(false)

  // Use the custom hook
  useRiskLevelInitialization(auth, props.demographic, props.questionaireCount, setLoading);

  const level_agriculture_and_farming = [low.agriculture_and_farming, moderate.agriculture_and_farming, high.agriculture_and_farming]
  const level_Building = [low.building, moderate.building, high.building]
  const level_bussiness = [low.business, moderate.business, high.business]
  const level_education = [low.education, moderate.education, high.education]
  const level_factory_workers = [low.factory_workers, moderate.factory_workers, high.factory_workers]
  const level_health = [low.health, moderate.health, high.health]
  const level_hospitality = [low.hospitality, moderate.hospitality, high.hospitality]
  const level_other_professional = [low.other_professional, moderate.other_professional, high.other_professional]
  const level_student = [low.student, moderate.student, high.student]
  const level_retire = [low.retire, moderate.retire, high.retire]

  const data = {
    labels: Object.keys(myReport),
    datasets: [
      {
        label: "Agriculture And Farming",
        data: level_agriculture_and_farming.map(lp => lp),
        borderColor: 'rgba(255, 92, 92, 1)',
        backgroundColor: 'rgba(255, 92, 92, 1)',
      },
      {
        label: "Building",
        data: level_Building.map(lp => lp),
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgb(54, 162, 235)',
      },
      {
        label: "Bussiness",
        data: level_bussiness.map(lp => lp),
        borderColor: 'rgba(253, 172, 66, 1)',
        backgroundColor: 'rgba(253, 172, 66, 1)',
      },
      {
        label: "Education",
        data: level_education.map(lp => lp),
        borderColor: 'rgba(57, 217, 138, 1)',
        backgroundColor: 'rgba(57, 217, 138, 1)',
      },
      {
        label: "Factory Workers",
        data: level_factory_workers.map(lp => lp),
        borderColor: 'rgba(230, 229, 230, 1)',
        backgroundColor: 'rgba(230, 229, 230, 1)',
      },
      {
        label: "Health",
        data: level_health.map(lp => lp),
        borderColor: 'rgb(	252	199	7)',
        backgroundColor: 'rgb(	252	199	7)',
      },
      {
        label: "Hospitality",
        data: level_hospitality.map(lp => lp),
        borderColor: 'rgb(54,162,235)',
        backgroundColor: 'rgb(54, 162, 235, 0.5)',
      },
      {
        label: "Other Professional",
        data: level_other_professional.map(lp => lp),
        borderColor: 'rgb(202,139,60)',
        backgroundColor: 'rgb(202,139,60)',
      },
      {
        label: "Retire",
        data: level_retire.map(lp => lp),
        borderColor: 'rgb(234, 110,20)',
        backgroundColor: 'rgb(234, 110,20)',
      },
      {
        label: "Student",
        data: level_student.map(lp => lp),
        borderColor: 'rgb(144,111,8)',
        backgroundColor: 'rgb(144,111,8)',
      },
    ]
  };

  const optionsLevel = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {

          font: {
            size: 16,
          },

        }
      },
      title: {
        display: true,
        text: 'Risk Level Presentation',
        font: {
          size: 16,
        },
      },
      tooltip: {
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 16,
        },
      },
    },
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <div id="risk-level-bar">
            <Bar options={optionsLevel} data={data} />
          </div>
      }

    </>
  )
}

export const LevelPresentationAetiologyBar = (props: ILevelPresentation) => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.riskLevel || {}
  const low = myReport.low || "N/A"
  const moderate = myReport.medium || "N/A"
  const high = myReport.high || ""
  const [isLoading, setLoading] = useState(false)
  const level_interpersonal_violence = [low.interpersonal_violence, moderate.interpersonal_violence, high.interpersonal_violence]
  const level_accident = [low.accident, moderate.accident, high.accident]
  const level_falls = [low.falls, moderate.falls, high.falls]

  // Use the custom hook
  useRiskLevelInitialization(auth, props.demographic, props.questionaireCount, setLoading);

  const data = {
    labels: Object.keys(myReport),
    datasets: [
      {
        label: "Interpersonal Violence",
        data: level_interpersonal_violence.map(lp => lp),
        borderColor: 'rgba(255, 92, 92, 1)',
        backgroundColor: 'rgba(255, 92, 92, 1)',
      },
      {
        label: "Accident",
        data: level_accident.map(lp => lp),
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgb(54, 162, 235)',
      },
      {
        label: "Falls",
        data: level_falls.map(lp => lp),
        borderColor: 'rgba(253, 172, 66, 1)',
        backgroundColor: 'rgba(253, 172, 66, 1)',
      },
    ]
  };

  const optionsLevel = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {

          font: {
            size: 16
          }
        }
      },
      title: {
        display: true,
        text: 'Risk Level Presentation',
        font: {
          size: 16,
        },
      },
      tooltip: {
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 16,
        },
      },
    },
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <div id="risk-level-bar">
            <Bar options={optionsLevel} data={data} />
          </div>
      }

    </>
  )
}

export const LevelPresentationFractureSiteBar = (props: ILevelPresentation) => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.riskLevel || {}
  const low = myReport.low || "N/A"
  const moderate = myReport.medium || "N/A"
  const high = myReport.high || ""
  const [isLoading, setLoading] = useState(false)
  const level_mandible = [low.mandible, moderate.mandible, high.mandible]
  const level_maxilla = [low.maxilla, moderate.maxilla, high.maxilla]
  const level_multiple_sites = [low.multiple_sites, moderate.multiple_sites, high.multiple_sites]
  const level_nasal = [low.nasal, moderate.nasal, high.nasal]
  const level_orbit = [low.orbit, moderate.orbit, high.orbit]
  const level_zygomatic = [low.zygomatic, moderate.zygomatic, high.zygomatic]

  // Use the custom hook
  useRiskLevelInitialization(auth, props.demographic, props.questionaireCount, setLoading);

  const data = {
    labels: Object.keys(myReport),
    datasets: [
      {
        label: "Mandible",
        data: level_mandible.map(lp => lp),
        borderColor: 'rgba(255, 92, 92, 1)',
        backgroundColor: 'rgba(255, 92, 92, 1)',
      },
      {
        label: "Maxilla",
        data: level_maxilla.map(lp => lp),
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgb(54, 162, 235)',
      },
      {
        label: "Multiple Sites",
        data: level_multiple_sites.map(lp => lp),
        borderColor: 'rgba(253, 172, 66, 1)',
        backgroundColor: 'rgba(253, 172, 66, 1)',
      },
      {
        label: "Nasal",
        data: level_nasal.map(lp => lp),
        borderColor: 'rgb(54,162,235)',
        backgroundColor: 'rgb(54, 162, 235, 0.5)',
      },
      {
        label: "Orbit",
        data: level_orbit.map(lp => lp),
        borderColor: 'rgb(202,139,60)',
        backgroundColor: 'rgb(202,139,60)',
      },
      {
        label: "Zygomatic",
        data: level_zygomatic.map(lp => lp),
        borderColor: 'rgb(234, 110,20)',
        backgroundColor: 'rgb(234, 110,20)',
      }
    ]
  };

  const optionsLevel = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {

          font: {
            size: 16
          }
        }
      },
      title: {
        display: true,
        text: 'Risk Level Presentation',
        font: {
          size: 16,
        },
      },
      tooltip: {
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 16,
        },
      },
    },
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <div id="risk-level-bar">
            <Bar options={optionsLevel} data={data} />
          </div>
      }

    </>
  )
}

export const LevelPresentationPresentationBar = (props: ILevelPresentation) => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.riskLevel || {}
  const low = myReport.low || "N/A"
  const moderate = myReport.medium || "N/A"
  const high = myReport.high || ""
  const [isLoading, setLoading] = useState(false)
  const level_first = [low.first, moderate.first, high.first]
  const level_second = [low.second, moderate.second, high.second]
  const level_over_3 = [low.over_3, moderate.over_3, high.over_3]

   // Use the custom hook
   useRiskLevelInitialization(auth, props.demographic, props.questionaireCount, setLoading);

  const data = {
    labels: Object.keys(myReport),
    datasets: [
      {
        label: "First",
        data: level_first.map(lp => lp),
        borderColor: 'rgba(255, 92, 92, 1)',
        backgroundColor: 'rgba(255, 92, 92, 1)',
      },
      {
        label: "Second",
        data: level_second.map(lp => lp),
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgb(54, 162, 235)',
      },
      {
        label: "Over 3",
        data: level_over_3.map(lp => lp),
        borderColor: 'rgba(253, 172, 66, 1)',
        backgroundColor: 'rgba(253, 172, 66, 1)',
      }
    ]
  };

  const optionsLevel = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {

          font: {
            size: 16
          }
        }
      },
      title: {
        display: true,
        text: 'Risk Level Presentation',
        font: {
          size: 16,
        },
      },
      tooltip: {
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 16,
        },
      },
    },
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <div id="risk-level-bar">
            <Bar options={optionsLevel} data={data} />
          </div>
      }

    </>
  )
}

export const LevelPresentationAgeBar = (props: ILevelPresentation) => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.riskLevel || {}
  const low = myReport.low || "N/A"
  const moderate = myReport.medium || "N/A"
  const high = myReport.high || ""

  const [isLoading, setLoading] = useState(false)

  // Use the custom hook
  useRiskLevelInitialization(auth, props.demographic, props.questionaireCount, setLoading);

  const level_maxAge = [low.over_60, moderate.over_60, high.over_60]
  const level_age_18_29
    = [low.age_18_29
      , moderate.age_18_29
      , high.age_18_29
    ]
  const level_age_30_46 = [low.age_30_46, moderate.age_30_46, high.age_30_46]
  const level_age_47_60 = [low.age_47_60, moderate.age_47_60, high.age_47_60]

  const data = {
    labels: Object.keys(myReport),
    datasets: [
      {
        label: "Age Over 60",
        data: level_maxAge.map(lp => lp),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: "Age 18 - 29 ",
        data: level_age_18_29.map(lp => Math.floor(lp)),
        borderColor: 'rgb(54,162,235)',
        backgroundColor: 'rgb(54, 162, 235, 0.5)',
      },
      {
        label: "Age 30 - 46",
        data: level_age_30_46.map(lp => Math.floor(lp)),
        borderColor: 'rgb(202,139,60)',
        backgroundColor: 'rgb(202,139,60)',
      },
      {
        label: "Age 47-60",
        data: level_age_47_60.map(lp => Math.floor(lp)),
        borderColor: 'rgb(234, 110,20)',
        backgroundColor: 'rgb(234, 110,20)',
      },
    ]
  };

  const optionsLevel = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {

          font: {
            size: 16
          }
        }
      },
      title: {
        display: true,
        text: 'Risk Level Presentation',
        font: {
          size: 16,
        },
      },
      tooltip: {
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 16,
        },
      },
    },
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <div id="risk-level-bar">
            <Bar options={optionsLevel} data={data} />
          </div>
      }

    </>
  )
}

export const NumberPresentationBar = () => {
  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const report = useSelector((state: RootStateOrAny) => state.report)
  const myReport = report.report || "N/A"
  const myPresentation = myReport.number_presentation || "N/A"
  const first = myPresentation.first || "N/A"
  const second = myPresentation.second || "N/A"
  const over_3 = myPresentation.over_3 || "N/A"
  const [isLoading, setLoading] = useState(false)
  const presentation_val = [first.val, second.val, over_3.val]
  const presentation_percentage = [first.percent, second.percent, over_3.percent]
  const presentation_value = ["first", "second", "over 3"]

  useGetParticipantReport(auth, setLoading);

  const data = {
    labels: presentation_value.map(pv => pv),
    datasets: [
      {
        label: "participant",
        data: presentation_val.map(presentation => presentation),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: "Percentage (%)",
        data: presentation_percentage.map(presentation => Math.floor(presentation)),
        borderColor: 'rgb(54,162,235)',
        backgroundColor: 'rgb(54, 162, 235, 0.5)',
      },
    ]
  };

  const optionsPresentation = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Number Presentation ED',
      },
    },
  };

  return (
    <>
      {
        isLoading ? "Loading ... "
          :
          <>
            <Bar options={optionsPresentation} data={data} />;
          </>
      }

    </>
  )
}