import Answer from "./Answer";

const questionTypes: { name: string; code: string }[] = [
    { name: "Multiple Choice", code: "multiple-choice" },
];

const Question = (props: {
    index: number;
    question: any;
    onChangeNoQuestion: any;
    onChangeQuestion: any;
    onChangeTypeQuestion: any;
    showRemoveQuestion: boolean;
    removeQuestion: any;
    addAnswer: any;
    onChangeNoAnswer: any;
    onChangeAnswer: any;
    onChangeScoreAnswer: any;
    increaseScoreAnswer: any;
    decreaseScoreAnswer: any;
    removeAnswer: any;
}) => {
    return (
        <div className="card my-3">
            <div className="card-header bg-white d-flex">
                <h5 className="mb-0 mr-auto">Question {props.index + 1}</h5>
                {props.showRemoveQuestion ? (<button
                    className="btn btn-danger btn-sm"
                    onClick={props.removeQuestion}
                >
                    Delete Question
                </button>) : ""}
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-1 form-group">
                        <label htmlFor={`answerType${props.index}`}>No.</label>
                        <input
                            className="form-control"
                            value={props.question.sort}
                            placeholder="No."
                            onChange={props.onChangeNoQuestion}
                        />
                    </div>
                    <div className="col-lg-8 form-group">
                        <label htmlFor={`question${props.index}`}>
                            Question
                        </label>
                        <textarea
                            id={`question${props.index}`}
                            rows={1}
                            className="form-control"
                            placeholder="Type your question here..."
                            value={props.question.title || ""}
                            onChange={props.onChangeQuestion}
                        ></textarea>
                    </div>
                    <div className="col-lg-3 form-group">
                        <label htmlFor={`answerType${props.index}`}>Type</label>
                        <select
                            className="form-control"
                            onChange={props.onChangeTypeQuestion}
                        >
                            {questionTypes.map((type: any, index: number) => (
                                <option key={index} value={type.code}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <hr style={{ borderColor: "rgba(0, 0, 0, 0.125)",width: "100%", margin: "0px 15px 1rem"}} />

                    <div className="col-lg-12  d-flex">
                        <b className="mr-auto">Answers</b>
                        <button
                            className="btn btn-success btn-sm"
                            onClick={props.addAnswer}
                        >
                            Add Answer
                        </button>
                    </div>
                    {props.question.questionChoice.length > 0 ? (
                        <>
                            <div className="col-lg-1">
                                <label>No.</label>
                            </div>
                            <div className="col-lg-8">
                                <label>Answer</label>
                            </div>
                            <div className="col-lg-3">
                                <label>Score</label>
                            </div>
                        </>
                    ) : (
                        ""
                    )}

                    {props.question.questionChoice.map(
                        (answer: any, index: number) => (
                            <Answer
                                key={index}
                                answer={answer}
                                onChangeNoAnswer={(event: any) =>
                                    props.onChangeNoAnswer(
                                        props.index,
                                        index,
                                        event
                                    )
                                }
                                onChangeAnswer={(event: any) =>
                                    props.onChangeAnswer(
                                        props.index,
                                        index,
                                        event
                                    )
                                }
                                onChangeScoreAnswer={(event: any) =>
                                    props.onChangeScoreAnswer(
                                        props.index,
                                        index,
                                        event
                                    )
                                }
                                increaseScoreAnswer={(event: any) =>
                                    props.increaseScoreAnswer(
                                        props.index,
                                        index,
                                        event
                                    )
                                }
                                decreaseScoreAnswer={(event: any) =>
                                    props.decreaseScoreAnswer(
                                        props.index,
                                        index,
                                        event
                                    )
                                }
                                removeAnswer={() =>
                                    props.removeAnswer(props.index, index)
                                }
                            />
                        )
                    )}
                    <div className="col-lg-12 text-right">
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Question;
