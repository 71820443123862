// import { IAdminMedical } from '../../interfaces/IMedical'
import { detailMedicalAdminAPI, detailMedicalAPI, getMedicalAdminAPI, getMedicalAPI } from '../api/api.medical'
import * as types from '../types/medical.type'

export const getMedicalAction = () => {
    return async (dispatch:any)=>{
        try {
           
            dispatch({type:types.MEDICAL_LOADING})
            const response = await getMedicalAPI()
            dispatch({
                type:types.GET_MEDICAL,
                    payload:{
                    medical:response.data.items
                }
            })
            return response
        } catch (err:any) {
            throw err
        }
    }
}
export const getDetailMedicalAction=(id:string)=>{
    return async (dispatch:any)=>{
        try {
            
            dispatch({type:types.MEDICAL_LOADING})
            const response = await detailMedicalAPI(id)
            dispatch({
                type:types.GET_DETAIL_MEDICAL,
                payload:{
                    detailMedical:response.data
                    // medical:response.data.items
                }
            })
        } catch (err:any) {
            dispatch({
                type:types.MEDICAL_FAILED,
                payload:{
                    errMessage:err
                }
            })
            console.error("[list medical]",err)
            throw err
        }
    }
}

export const getAdminMedicalAction = (token:string) => {
    return async (dispatch:any)=>{
        try {
           
            dispatch({type:types.MEDICAL_LOADING})
            const response = await getMedicalAdminAPI(token)
            dispatch({
                type:types.GET_MEDICAL,
                    payload:{
                    medical:response.data.items
                }
            })
            return response
        } catch (err:any) {
            throw err
        }
    }
}
export const getAdminDetailMedicalAction = (token:string,id:string) => {
    return async (dispatch:any)=>{
        try {
           
            dispatch({type:types.MEDICAL_LOADING})
            const response = await detailMedicalAdminAPI(token,id)
            dispatch({
                type:types.GET_MEDICAL,
                    payload:{
                        detailMedical:response.data
                    // medical:response.data.items
                }
            })
            return response
        } catch (err:any) {
            throw err
        }
    }
}

export const clearMedicalDetail = () => {
    return (dispatch: any) => dispatch({
        type: types.CLEAR_DETAIL_MEDICAL
    })
}

// export const postAdminMedicalAction = (token:string, payload:IAdminMedical) => {
//     return async (dispatch:any)=>{
//         try {
           
//             dispatch({type:types.MEDICAL_LOADING})
//             const response = await postMedicalAdminAPI(token)
//             dispatch({
//                 type:types.GET_MEDICAL,
//                     payload:{
//                     medical:response.data.items
//                 }
//             })
//             return response
//         } catch (err:any) {
//             throw err
//         }
//     }
// }
